//styling
import '../App.css';
import "../css/admin_portal.css";
import "../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";


//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';



//customcomponents

//libraries
import { Button } from '@mui/material';

export default function PromptUser(props){
    
    const [showDialogue,setShowDialogue]=useState(false);
    const [initial,setInitial]=useState();
    const [warnConfirm,setWarnConfirm]=useState();
    

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        
        
        if(!initial){
            setInitial(true);
        }

      }, [props.showDialogue]);
    
    const closeDialogue=(e)=>{

        let params;
        if(props.Params){
            params = {};
            params.response = e;
            params.params = props.Params;
        }

        if(props.onCallback){
            props.onCallback(params||e);
        }else{
            setShowDialogue(false)
            //console.log('props.onCallback not set');
        }
    }
    

    return(
       
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
        >
            <DialogTitle>
                {props.Title || 'props.Title not set'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue('No')}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
               
               <Stack spacing={2}>
                <div>{props.Prompt || 'props.Prompt not set'}</div>
                {
                (props.warnLimit && props.Params && props.Params.length > props.warnLimit)?
                    <>
                        <div>{props.warnMessage.replace("{0}",props.Params.length).replace("{1}",props.warnConfirmation)}</div>
                        
                            <TextField id="location-name" 
                                        variant="outlined" 
                                        required
                                        placeholder={props.warnConfirmation}
                                        value={warnConfirm}
                                        onChange={(e)=>setWarnConfirm(e.target.value)}
                                        />
                        
                    </>
                    :<></>
                }
               </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>closeDialogue('Yes')} disabled={((props.warnLimit && props.Params.length > props.warnLimit && props.warnConfirmation!=warnConfirm))}>Yes</Button>
                <Button onClick={()=>closeDialogue('No')}>No</Button>                
            </DialogActions>
        </Dialog>

    )
}