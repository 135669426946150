//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";
import { useFormContext, FormProvider } from 'react-hook-form';



//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Alert, AlertTitle } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';



//customcomponents
import AssignOption  from './AssignOption';
import IconSelector from '../../IconSelector';

//libraries
import { GetAllCustomers,GetCustomerLocations, GetAllRoles} from '../../../libs/Customers';
import { Button, LinearProgress } from '@mui/material';
import { AddLink, UpdateLink } from '../../../libs/Links';
import { AddMessage, UpdateMessage } from '../../../libs/Messages';
import { AddReport, UpdateReport } from '../../../libs/Reports';
import { AddContent, UpdateContent } from '../../../libs/Content';
import {  AddPOC, UpdatePOC, GetAllPOC } from '../../../libs/POC';
import { UpdateCustomer, AddCustomer } from '../../../libs/Customers';

export default function AddUpdateCustomer(props){

    
    const [showDialogue,setShowDialogue]=useState(false);
    const [isLoading, setIsLoading]=useState(false);
    const [initial,setInitial]=useState();
    const [assignOptionDialogueVisible, setAssignOptionDialogeVisible]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [resetForm,setResetForm]=useState();
    const [editMode,setEditMode]=useState();
    const [selectedRecord,setSelectedRecord]=useState();
    
    

    const [customerNumber, setCustomerNumber]=useState();
    const [customerName, setCustomerName]=useState();
    const [customerPOC, setCustomerPOC]=useState();
    const [pocData,setPOCData]=useState();
    const [locations, setLocations]=useState();
    
    const [pocFullName, setPOCFullName]=useState();
    const [pocTitle, setPOCTitle]=useState();
    const [pocEmail, setPOCEmail]=useState();
    const [pocDeskPhone, setPOCDeskPhone]=useState();
    const [pocCellPhone, setPOCCellPhone]=useState();
    const [isDefaultPOC, setIsDefaultPOC]=useState(false);
    const [pocImageB64, setPOCImageB64]=useState();
    const [pocImage, setPOCImage]=useState();
    
    //const { getValues } = useFormContext();

    useEffect(()=>{
        resetFeedback();
    },[showDialogue])

    
    useEffect(()=>{
        
        if(selectedRecord){
            //console.log(selectedRecord);
            setCustomerNumber(selectedRecord.customer_number);
            setCustomerName(selectedRecord.customer_name)
            //console.log(`setting selected POC to ${selectedRecord.poc.poc_id}`);
            setCustomerPOC(selectedRecord.poc);
            setLocations(selectedRecord.locations);
        }
        
    },[selectedRecord])

   

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        resetFeedback();
        if(!initial){
            setInitial(true);
        }

        setEditMode(props.edit);
        if(props.edit && props.selectedRecord){
            setSelectedRecord(props.selectedRecord);
        }else{
            clearForm();
        }
        
        LoadPOC();

      }, [props.showDialogue]);


    const LoadPOC = async()=>{
        let poc = await GetAllPOC();
        //console.log('poc data',poc);
        setPOCData(poc);
    }
    
    const UpdateCustomerObj=async(e)=>{
        let retObj = selectedRecord;
        //console.log('preupdate',retObj);
        let success = false;
        setIsLoading(true);
        let customerObj;
        try{
            //console.log(`update customer with ${customerNumber} ${customerName} ${customerPOC.poc_id}`);
            //await UpdateReport(selectedRecord.id, reportTitle, reportDescription, reportFile);
            /*await UpdateContent({contentType: contentType
                                , id: selectedRecord.id
                                , reportTitle: reportTitle
                                , reportDescription : reportDescription
                                , reportFile: reportFile});*/
            customerObj = await UpdateCustomer(selectedRecord.customer_id, customerNumber,customerName,customerPOC);
            retObj.customer_number = customerNumber;
            retObj.customer_name = customerName;
            retObj.poc = customerPOC;//pocData.rows.filter((p)=>p.poc_id==customerPOC.poc_id);
            //console.log('postupdate',retObj);
            
            //console.log('updated POC object');
            if(props.onCustomerUpdated){
                props.onCustomerUpdated(retObj);
            }
            
            success=true;
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('Customer Updated Successfully');
            
        }catch(err){
            setFeedbackTitle('Error Updating Customer');
            setSeverity('error');
            setIcon('error');
            console.error(err);
            setFeedbackMessage(JSON.stringify(err.response.data.error));
        }
                    

           
        setIsLoading(false);
        
        
    }
    const AddCustomerObj=async(e)=>{
        
        
        let pocObj = {};
        let success = false;
        setIsLoading(true);
        try{
            //console.log(`add customer ${customerName}`)
            /*//AddPOC(fullname,cell,email,imageb64,phone,title, isDefault)
            pocObj = await AddPOC(pocFullName,pocCellPhone,pocEmail,pocImageB64,pocDeskPhone,pocTitle,isDefaultPOC);
            
            console.log('added poc');
            console.log(pocObj);*/
            pocObj = await AddCustomer(customerName, customerNumber, customerPOC);
            
            success=true;
        }catch(err){
            setFeedbackTitle('Error Adding Customer');
            setSeverity('error');
            setIcon('error');
            setFeedbackMessage(JSON.stringify(err.response.data.error));
        }
            
        setIsLoading(false);


        if(success){
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('Customer Added Successfully');
            
            //setTimeout(()=>resetFeedback(),3000);
            if(props.onCustomerAdded){
                props.onCustomerAdded(pocObj)
            }

            clearForm();
            //setAssignOptionDialogeVisible(true);
            //setResetForm(Math.random());
        }else{
            //console.log('failure occurred');
        }
        

    }

    
    const clearForm=()=>{
        setCustomerNumber();
        setCustomerName()
        setCustomerPOC();
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackTitle();
        setFeedbackMessage();
    }
  

    const closeDialogue=(e)=>{

        clearForm();
        resetFeedback();
        setSelectedRecord();
        setShowDialogue(false)
        if(props.onCloseCallback){
            props.onCloseCallback(e);
        }
    }
    
    const removeLocation = (locationId)=>{
        let crrlocationss = locations;
        setLocations(locations.filter((a)=>a.location_id!=locationId));
    }

    return(
       <>
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '600px',
                maxHeight: '90%',
                minWidth: '800px',
                maxWidth: '90%'
              }}}
        >
            <DialogTitle>
                {(editMode)?'Edit':'Add'} Customer
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {(isLoading)?<LinearProgress />:<></>}
                <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                    <AlertTitle>{feedbackTitle}</AlertTitle>
                    {feedbackMessage}
                </Alert>
                <Button style={{visibility: ((severity=='success'&&props.onMakeAssignments)) ? 'visible' : 'hidden' }} onClick={(e)=>props.onMakeAssignments()}>Manage assignments</Button>
                <div style={{padding:'10px'}}>
                
                </div>
                <div style={{padding:'10px'}}>
                <Stack spacing={5}>
                    <Stack spacing={2} direction="row">
                    <FormControl fullWidth>
                        <FormLabel id="customer-number-label" >Customer Number</FormLabel>
                        <TextField id="customer-number" 
                                    aria-labelledby="customer-number-label" 
                                    variant="outlined" 
                                    required
                                    value={customerNumber}
                                    onChange={(e)=>setCustomerNumber(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-name-label" >Customer Name</FormLabel>
                        <TextField id="customer-name" 
                                    aria-labelledby="customer-name-label" 
                                    variant="outlined" 
                                    required
                                    value={customerName}
                                    onChange={(e)=>setCustomerName(e.target.value)}
                                    />
                    </FormControl>
                    </Stack>
                    <FormControl fullWidth>
                        {(pocData)?
                            <Autocomplete
                                disablePortal
                                id="ddlPOC"
                                options={pocData.rows}
                                getOptionLabel={(option) => option.poc_fullname}
                                renderInput={(params) => <TextField {...params} label="POC" />}
                                onChange={(e,v)=>{setCustomerPOC(v)}}
                                key={customerPOC}
                                value={customerPOC}
                            />
                        :<></>
                        }
                    </FormControl>
                </Stack>
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={()=>(editMode)?UpdateCustomerObj():AddCustomerObj()} loading={isLoading}>{(editMode)?'Update':'Add'}</LoadingButton>
                <Button onClick={()=>closeDialogue()}>Cancel</Button>                
            </DialogActions>
        </Dialog>
        
        </>
        

    )
}