
import { Auth,API } from "aws-amplify";
import { insert,remove } from "./ArrayHelper";

const APINAME='reports'
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetReportById = async(reportid)=>{
    let report;
    try{
        let cog_user = (await Auth.currentAuthenticatedUser())
        let sub = cog_user.attributes.sub;
        let groups = cog_user.signInUserSession.accessToken.payload['cognito:groups'];
        if(groups && groups.indexOf('SysAdmin')>=0){
            const qParams = new URLSearchParams();
            qParams.append("reportId", reportid);
            report = await API.get(APINAME,`/report/${reportid}`);
            
        }else{
            const qParams = new URLSearchParams();
                qParams.append("reportId", reportid);
            report = await API.get(APINAME,'/report/user/'+sub+'?'+qParams.toString());
            report = report.filter(f=>f.report_id=reportid)[0];
        }
        return report

    }catch(err){
        console.error('error retrieving report', err);
    }

                
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetMyReports= async(sub, rows, pageSize=5, currentPage=0, search, sortBy) =>{
    
    return new Promise(async (resolve,reject)=>{
        try{
            sub = sub || (await Auth.currentAuthenticatedUser()).attributes.sub;

            const qParams = new URLSearchParams();
            qParams.append("pagesize", pageSize);
            qParams.append("currentpage", currentPage);
            if(search){
                qParams.append("search",search);
            }
            if(sortBy){
                qParams.append("sort",sortBy);
            }
            

            if(sub){

                
                let linkinfo = await API.get(APINAME,'/report/user/'+sub+'?'+qParams.toString());
                
                if(search){
                    //console.log('filter for search terms');
                    linkinfo = linkinfo.filter((l)=> (l.report_description.toLowerCase().indexOf(search.toLowerCase())>=0))
                }

                let totalitems = linkinfo.length;
                let startIndex = currentPage*pageSize;
                let length = (startIndex+pageSize>linkinfo.length)?linkinfo.length-startIndex:pageSize;
                let dummyitems=0;
                if(totalitems!=(rows.length-rows.filter(f=>f.report_id.indexOf('dummy')==0).length)){
                    if(linkinfo.length>pageSize){
                        linkinfo = linkinfo.splice(startIndex,length);
                    }
                    if(rows.length!=totalitems){
                        for(let i=startIndex+pageSize;i<totalitems;i++){
                            linkinfo.push({report_id:`dummy${i}`});
                        }
                        rows=linkinfo;
                    }else{
                        let count=0
                        //remove dummy rows
                        rows = remove(rows, startIndex,length);
                        //insert new rows
                        for(let l of linkinfo){
                            rows = insert(rows,startIndex+count, l)
                            count++;
                        } 
                        
                    }
                    
                    dummyitems =  rows.filter(f=>f.report_id.indexOf('dummy')==0).length;
                }

                rows = [...rows];
                
                if(!sortBy || sortBy == 'date_created'){
                    //default sort
                    //console.log('default sort');
                    //console.log(rows);
                    rows = rows.sort((a, b) => new Date(b.date_created) - new Date(a.date_created) );
                }else{
                    //field sort
                    rows = rows.sort((a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1);
                }

                //console.log('sending resolve to callback');
                /*console.log({
                    rows : rows
                  , totalItems : totalitems
                  , resolvedItems : totalitems-dummyitems
              })*/
                resolve({
                      rows : rows
                    , totalItems : totalitems
                    , resolvedItems : totalitems-dummyitems
                });
            }else{
                resolve({
                    rows : []
                  , totalItems : 0
                  , resolvedItems : 0
              });
            }
            
        }catch(err){
            reject(err);
        }
        
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetAllReports=async()=>{
    
    return new Promise(async (resolve,reject)=>{
        try{
                let messages = await API.get(APINAME,'/report/?'+ Date.now());
                resolve(messages);
        }catch(err){
            console.error('Error getting messages.')
            reject(err);
        }
        
    })

}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetUploadUrl=async(ext)=>{
    return new Promise(async (resolve,reject)=>{
        try{
                let report = await API.get(APINAME,'/report/presignedurl?ext='+ext);
                resolve(report);
        }catch(err){
            console.error('Error getting presigned url.')
            reject(err);
        }
        
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const readArrayBuffer=(file)=>{
    return new Promise((resolve,reject)=>{
        var reader = new FileReader();
        reader.onabort=()=>{
            //console.log('reader aborted');
            reject('reader aborted');
        }
        reader.onerror=()=>{
            //console.log('failure reading file');
            reject('failure reading file');
        }
        reader.onload = () =>{
            const res = reader.result
            resolve(res);
        }
        reader.readAsArrayBuffer(file);
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddReport=async(title, description, uploadFile)=>{

    return new Promise(async(resolve,reject)=>{

        try{
            
             //get presigned URL where we will PUT the file
            let uploadDetail = await GetUploadUrl(uploadFile.name.match(/\.(.[^\.]+)$/)[1]);
            //{reportFileName:"",uploadURL:""}
            
            //upload the file to the presigned URL
            let fupresult = await fetch(uploadDetail.uploadURL, {
                method: 'PUT',
                headers: {'Content-Type':uploadFile.type},
                body: uploadFile
            })
            
            //after successful result, call API to create the database entry for upload complete operations
            let reportData = {
                report_title: title
              , report_description: description
              , report_file_name: uploadDetail.reportFileName
            }
            let req = {
                    headers: {'Content-Type':'application/json'}
                , body: reportData
            }
          
              let reportinfo = await API.post(APINAME,'/report/', req);
              resolve(reportinfo);
          
        }catch(err){
          
            console.error('Error uploading report.')
            reject(err);
        
        }

        
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UpdateReport=async(reportid,title, description, uploadFile)=>{

    return new Promise(async(resolve,reject)=>{

        try{
            let uploadDetail;
            if(uploadFile){
                //get presigned URL where we will PUT the file
                uploadDetail = await GetUploadUrl(uploadFile.name.match(/\.(.[^\.]+)$/)[1]);
                //{reportFileName:"",uploadURL:""}
                
                //upload the file to the presigned URL
                let fupresult = await fetch(uploadDetail.uploadURL, {
                    method: 'PUT',
                    headers: {'Content-Type':uploadFile.type},
                    body: uploadFile
                })
            }
            //after successful result, call API to create the database entry for upload complete operations
            let reportData = {
                report_title: title
              , report_description: description
              //, report_file_name: uploadDetail.reportFileName
            }
            if(uploadFile){
                reportData.report_file_name=uploadDetail.reportFileName
            }

            let req = {
                    headers: {'Content-Type':'application/json'}
                , body: reportData
            }
          
              let reportinfo = await API.put(APINAME,'/report/'+reportid, req);
              resolve(reportinfo);
          
        }catch(err){
          
            console.error('Error updating report.')
            reject(err);
        
        }

        
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableReports=async(ids)=>{
    let promises = []
    for(let id of ids){
        promises.push(DisableReport(id));
    }
    return await Promise.all(promises);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableReport=async(reportid)=>{

    return new Promise(async(resolve,reject)=>{

        try{
            let uploadDetail;
           
            let reportData = {
                is_active:'N'
            }

            let req = {
                    headers: {'Content-Type':'application/json'}
                , body: reportData
            }
          
              let reportinfo = await API.put(APINAME,'/report/'+reportid, req);
              resolve(reportinfo);
          
        }catch(err){
            console.error('Error disabling report.')
            reject(err);
        }
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableReport=async(reportid)=>{

    return new Promise(async(resolve,reject)=>{

        try{
            let uploadDetail;
           
            let reportData = {
                is_active:'Y'
            }

            let req = {
                    headers: {'Content-Type':'application/json'}
                , body: reportData
            }
          
              let reportinfo = await API.put(APINAME,'/report/'+reportid, req);
              resolve(reportinfo);
          
        }catch(err){
            console.error('Error enabling report.')
            reject(err);
        }
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableReports=async(ids)=>{
    let promises = []
    for(let id of ids){
        promises.push(EnableReport(id));
    }
    return await Promise.all(promises);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const UpdateReportAssignments=async(reportid,customers,replace)=>{

    return new Promise(async(resolve,reject)=>{

        try{
            //after successful result, call API to create the database entry for upload complete operations
            let reportData = {
                customers:customers
            }
            if(replace){
                reportData.action='REPLACE'
            }
            
            let req = {
                    headers: {'Content-Type':'application/json'}
                , body: reportData
            }
          
              let reportinfo = await API.put(APINAME,'/report/'+reportid, req);
              resolve(reportinfo);
          
        }catch(err){
          
            console.error('Error updating report.')
            reject(err);
        }
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DeleteReport = async(reportid)=>{
    
    return new Promise(async(resolve,reject)=>{
        try{
            let delreport = await API.del(APINAME,'/report/'+reportid);
            resolve(delreport);
        }catch(err){
            console.error('Error deleting report.')
            reject(err);
        }
    })
}//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { GetMyReports, GetReportById, GetAllReports, AddReport, DeleteReport, DisableReport,DisableReports,EnableReport,EnableReports, UpdateReport, UpdateReportAssignments }