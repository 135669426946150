//styling
import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";
import IconSearch from "../../images/icon_search.png";

//react components
import React, { useState, useEffect  } from "react";
import { useSearchParams } from "react-router-dom";


//third-party components
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DescriptionIcon from '@mui/icons-material/Description';
import SummarizeIcon from '@mui/icons-material/Summarize';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';


//customcomponents
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
//libraries
import { GetMyReports } from '../../libs/Reports';
import axios from 'axios';


export default function MyReports(props){

    const [isLoading, setIsLoading]=useState(true);
    const [isTableLoading, setIsTableLoading]=useState();
    const [columns, setColumns]=useState([]);
    const [reports,setReports]=useState(props.reportData || {rows:[],totalItems:0,resolvedItems:0, page:0});
    const [currentPage, setCurrentPage]=useState((props.reportData)?props.reportData.page || 0:0);
    const [pageSize, setPageSize]=useState((props.reportData)?props.reportData.pageSize || 100:100);
    const [sortBy, setSortBy]=useState();
    const [searchParams,setSearchParams ] = useSearchParams();
    const [selected, setSelected] = useState();
    const [searchTerm, setSearchTerm]=useState();

    useEffect(()=>{
        if(props.reportData){
            setReports(props.reportData);
            setCurrentPage(0);
            setPageSize(100);
        }
    },[props.reportData])

    useEffect(() => {
        setColumns([
            {
                field: "actions",
                headerName: "View",
                sortable: false,
                description: 'View Report',
                width: 80,
                disableClickEventBubbling: true,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        
                        (params.row.report_presigned_url && params.row.report_presigned_url.match(/^https:\/\/.+?\/.+?\.pdf\?.+$/))?
                        <Tooltip title="View Report">
                            <IconButton href={`/reports/viewer?reportid=${encodeURIComponent(params.row.report_id)}`} target="_blank"><DescriptionIcon /></IconButton>
                        </Tooltip>
                        :
                            <Tooltip title="Download Report">
                                <IconButton onClick={async (e)=>{ 
                                    //let selreport = await GetReportById(params.row.id);
                                    let rsp = await axios.get(params.row.report_presigned_url, {responseType: 'blob'});
                                    const targeturl = URL.createObjectURL(rsp.data);
                                    let ext = params.row.report_presigned_url.match(/^https:\/\/.+?\/.+?\.(docx|pdf|xlsx|pptx)\?.+$/i)
                                        //console.log(ext);
                                        //download file
                                        const a = document.createElement('a');
                                        //console.log('report_title',params.row.report_title);
                                        a.download = `${params.row.report_title||params.row.report_id}.${ext[1]}`;
                                        a.href = targeturl;
                                        a.addEventListener('click', (e) => {
                                            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
                                        });
                                        a.click();
                                        URL.revokeObjectURL(targeturl);
                                }}><SummarizeIcon /></IconButton>
                            </Tooltip>
                    );
                 }
            },
            {     field: 'date_created'
                , headerName: 'Date/Time'
                , width:200
                , valueGetter: (params) =>{
                    return (params.row.date_created)?`${ new Date(params.row.date_created).toLocaleDateString()} ${new Date(params.row.date_created).toLocaleTimeString()}`:'' ;
                }
            }
            ,{ 
                field: 'report_title'
              , headerName: 'Title'
              , width: 250 
            }
           ,{ 
                 field: 'report_description'
               , headerName: 'Description'
               , width: 350 
           }
         ]);
        
        if(props.selected){
            
            LoadData({pageSize:pageSize, page: currentPage},reports,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }
        setSelected(props.selected);
      }, [props.selected]);
    
      
    const CustomPaginator=()=>{
        return (
                (isTableLoading)?
                <></>
                :<GridPagination disabled={!isTableLoading}/>
        );
    }

    const handlePagination = (params, evt, details)=>{
                
            LoadData(params, reports,props.onData);

     }

     const LoadData = (params,origdata,callback)=>{

            if(!origdata){
                origdata = {
                    rows:[]
                    ,totalItems:0
                    ,resolvedItems:0
                    ,page:0
                }
            }
            setIsTableLoading(true);
            if(!origdata || origdata.totalItems==0 || (origdata.resolvedItems<(params.pageSize*(params.page+1)) && origdata.resolvedItems!=origdata.totalItems) || params.search){
                setPageSize(params.pageSize);
                
                GetMyReports(searchParams.get('userId')||props.userId, origdata.rows||[], params.pageSize, params.page, params.search, sortBy).then(async (reportdata)=>{
                    if(reportdata){
                        setReports(reportdata);
                        if(callback){
                            /*console.log(origdata);
                            if((origdata && origdata.resolvedItems!=reportdata.resolvedItems) || reportdata.totalItems==0){
                                reportdata.page=params.page
                                reportdata.pageSize=params.pageSize
                                console.log('processing as expected')
                              */  callback(reportdata).then(()=>{
                                    setPageSize(params.pageSize);
                                    setCurrentPage(params.page);
                                    setIsTableLoading(false);
                                });
                            //}
                            
                        }else{
                            setPageSize(params.pageSize);
                            setCurrentPage(params.page);
                            setIsTableLoading(false);
                        }
                    }
                
                }).catch((err)=>{
                    console.error(`error fetching reports for page ${params.page}`,err);
                    callback({rows:[],page:params.page,pageSize:params.pageSize}).then(()=>{
                        setPageSize(params.pageSize);
                        setCurrentPage(params.page);
                        setIsTableLoading(false);
                    });
                });
            }else{
                
                origdata.page=params.page;
                origdata.pageSize=params.pageSize;
                callback(origdata);
                setIsTableLoading(false);
                setPageSize(params.pageSize);
                setCurrentPage(params.page);
            }

            
            
     }

    const searchInput = (e)=>{
        
        if(e.keyCode==13){
            LoadData({pageSize:pageSize, page: 0, search:searchTerm}, undefined,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    resolve();
                })
            });
        }
    }

    return(
        (selected)?
        (isLoading)?
        <div id="my_reports" className="my_reports">
            <div className="row row_padding">
            <LinearProgress />
            </div>
        </div>
        :
        <div id="my_reports" className="my_reports">
            <div className="row row_padding">
            <p>Abbott provides Data Management reports for review and download. These reports include i-STAT test results and other data collected and synthesized from your connected instruments.          </p>
                <>
                <div className="container_search">
                    <OutlinedInput id="txtsearch" 
                        onKeyDown={searchInput} 
                        variant='outlined'
                        value={searchTerm}
                        placeholder='Search'
                        onChange={(e)=>setSearchTerm(e.target.value)}
                        startAdornment={<InputAdornment position="end"><IconButton aria-label="search" edge="start" onClick={(e)=>searchInput({keyCode:13})}><SearchIcon /></IconButton></InputAdornment>}
                        sx={{verticalAlign:'top'}}
                    />
                </div>
                <div id="report_table_container">
                    <div>
                    <table style={{width:'100%'}}></table>
                        <DataGrid
                            rows={reports?.rows||[]}
                            columns={columns}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                pageSize: pageSize,
                                page:currentPage
                                },
                            },
                            }}
                            pageSizeOptions={[10,25,50,100]}
                            //onRowSelectionModelChange={(val)=>alert(val)}
                            getRowId={(row)=>{return row.report_id}}
                            fullWidth
                            id="report_table" 
                            className="hover row-border" 
                            onPaginationModelChange={handlePagination}
                            slots={{ 
                                    pagination : CustomPaginator,
                                    noRowsOverlay: CustomNoRowsOverlay,
                                    noResultsOverlay: CustomNoRowsOverlay,
                            }}
                            loading={isTableLoading}
                            autoHeight={true}
                           
                            
                        >
                        </DataGrid>
                      
                    </div>
                </div>
                </>
            </div>
        </div>
        :<></>

    )
}