//styling
import '../../App.css';
import "../../css/admin_portal.css";
import "../../css/normalize.css";
import IconSearch from "../../images/icon_search.png";

//react components
import React, { useState, useEffect  } from "react";


//third-party components
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, AlertTitle } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddIcon from '@mui/icons-material/Add';




//customcomponents
import IconSelector from '../IconSelector';
import PromptUser from '../PromptUser';
import AddUpdatePOC from './Dialogues/AddUpdatePOC';


//libraries
import { GetAllPOC,DeletePOC } from '../../libs/POC';

export default function POCManager(props){

    const [isLoading, setIsLoading]=useState(true);
    const [isTableLoading, setIsTableLoading]=useState();
    const [columns, setColumns]=useState([]);
    const [pocdata,setPOCData]=useState(props.pocData || {rows:[],totalItems:0,resolvedItems:0});
    const [currentPage, setCurrentPage]=useState((props.pocData)?props.pocData.page || 0:0);
    const [pageSize, setPageSize]=useState((props.pocData)?props.pocData.pageSize || 100:100);
    const [sortBy, setSortBy]=useState();
    const [searchPhrase, setSearchPhrase]=useState();
    const [selectedrowids,setSelectedRowIds]=useState([]);
    const [selectedRows,setSelectedRows]=useState([]);
    const [isDeleting, setIsDeleting]=useState(false);
    const [deleteParams, setDeleteParams]=useState({});
    const [showDeletePrompt,setShowDeletePrompt]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [showAddPOCDialogue,setShowAddPOCDialogue] = useState(false);
    const [selectedRecord, setSelectedRecord]=useState();
    const [editContent, setEditContent]=useState(false);


    useEffect(()=>{
        if(feedbackMessage){
            //hide delete prompt after wait.
                let timeoutperiod = 3000;
                switch(severity){
                case 'error':
                    timeoutperiod = 6000;
                break;
                }
                setTimeout(()=>{//console.log('running timer');
                    resetFeedback();},3000);
        }

    },[feedbackMessage]);



    useEffect(() => {
        setColumns([
            {     field: 'poc_fullname'
                , headerName: 'Name'
                , width:150
            }
            ,{
                field: 'poc_title'
                , headerName:"Title"
                ,width:250
            }
            ,{
                  field: 'poc_email'
                , headerName:"Email"
                ,width:200
            }
            ,{
                field: 'poc_phone'
                , headerName:"Desk Phone"
                ,width:125
            }
            ,{
                field: 'poc_cell'
                , headerName:"Cell Phone"
                , width:125
            }
            ,{
                field:'poc_default'
                , headerName:"Default POC"
                ,width:125
            }
            ,{
                field: "action_edit",
                headerName: "",
                sortable: false,
                description: 'Edit',
                width: 50,
                disableClickEventBubbling: true,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <Tooltip title="Edit">
                            <IconButton onClick={(e)=>{
                                setEditContent(true);
                                setSelectedRecord(params.row);
                                setShowAddPOCDialogue(true);
                            }}><EditIcon /></IconButton>
                        </Tooltip>
                    );
                 }
            },{
                field: "action_delete",
                headerName: "",
                sortable: false,
                description: 'Delete',
                width: 50,
                disableClickEventBubbling: true,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <Tooltip title="Delete">
                            <IconButton onClick={(e)=>{ setDeleteParams([{poc_id:params.row.poc_id}]);setShowDeletePrompt(true);}}><DeleteIcon /></IconButton>
                        </Tooltip>
                    );
                 }
            }
         ]);
        
        if(props.isSelected){
         
            LoadData({pageSize:pageSize, page: currentPage},pocdata,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        //console.log('setting data in callback')
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }

      }, [props.isSelected,pocdata]);
    
    const resetData= async()=>{
        setIsLoading(true);
        setPOCData({rows:[],totalItems:0,resolvedItems:0});
    }

   

    const CustomPaginator=()=>{
        return (
                (isTableLoading)?
                <></>
                :<GridPagination disabled={!isTableLoading}/>
        );
    }

    const handlePagination = (params, evt, details)=>{
                
            LoadData(params, pocdata,props.onData);

     }

     const LoadData = (params,origdata,callback)=>{


            setIsTableLoading(true);
            if(!origdata || origdata.totalItems==0 || (origdata.resolvedItems<(params.pageSize*(params.page+1)) && origdata.resolvedItems!=origdata.totalItems) || params.search){
                setPageSize(params.pageSize);
                //console.log('calling getpoc with search params:', params.search)
                GetAllPOC(pocdata.rows, params.pageSize, params.page, params.search, sortBy).then(async (data)=>{
                    if(data){
                        setPOCData(data);
                        if(callback){
                            if(origdata && origdata.resolvedItems!=data.resolvedItems){
                                data.page=params.page
                                data.pageSize=params.pageSize
                                //console.log('sending report data to callback');

                                callback(data).then(()=>{
                                    //console.log('back from callback');
                                    setPageSize(params.pageSize);
                                    setCurrentPage(params.page);
                                    setIsTableLoading(false);
                                });
                            }
                            
                        }else{
                            //console.log('no callback configured')
                            setPageSize(params.pageSize);
                            setCurrentPage(params.page);
                            setIsTableLoading(false);
                        }
                    }
                
                }).catch((err)=>{
                    console.error(`error fetching poc for page ${params.page}`,err);
                    setIsTableLoading(false);
                });
            }else{
                //console.log('pulling data form cache');
                origdata.page=params.page;
                origdata.pageSize=params.pageSize;
                callback(origdata);
                setIsTableLoading(false);
                setPageSize(params.pageSize);
                setCurrentPage(params.page);
            }

            
            
     }

    const searchInput = (e)=>{
        
        if(e.keyCode==13){
            LoadData({pageSize:pageSize, page: 0, search:e.target.value},undefined,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }
    }

    const pocAdded = (pocObj)=>{
        setEditContent(false);
        //console.log('pocadded');
        //console.log(pocObj);

        let data= {...pocdata};
        if(pocObj.poc_default==true){
            data.rows.forEach(r=>r.poc_default=false);
        }

        
        data.rows = [pocObj].concat(data.rows);
        data.totalItems++;
        data.resolvedItems++;
        setSelectedRecord(pocObj);
        setPOCData(data);

    }

    const pocUpdated = (pocObj)=>{
        
        //console.log('poc updated');
        //console.log(pocObj);

        let data= {...pocdata};
        if(pocObj.poc_default=='true'){
            data.rows.forEach(r=>r.poc_default=false);
        }

        data.rows = data.rows.slice(0,data.rows.indexOf(selectedRecord)).concat([pocObj]).concat(data.rows.slice(data.rows.indexOf(selectedRecord)+1));
        //data.rows = [contentObj].concat(data.rows.filter((r)=>r.id!=contentObj.id));
        setPOCData(data);
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackMessage();
        setFeedbackTitle();
    }


    return(
        (isLoading)?
        <div id="pocmanager">
            <LinearProgress />
        </div>
        :
        <div id="pocmanager">
            
                <>
                        <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                            <AlertTitle>{feedbackTitle}</AlertTitle>
                            {feedbackMessage}
                        </Alert>
                        {(isDeleting)?<LinearProgress />:<></>}
                        
                        <PromptUser showDialogue={showDeletePrompt}
                            Title="Delete POC"
                            Prompt="Are you sure you would like to delete the selected POC?" 
                            Params={deleteParams}
                            onCallback={async (e)=>{
                                
                                setShowDeletePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        let delpromises=[];
                                        for(let delItem of e.params){
                                            delpromises.push(DeletePOC(delItem.poc_id));
                                        }
                                        let delres = await Promise.all(delpromises);
                                        
                                        let data = {...pocdata};
                                        data.rows = data.rows.filter((r)=>r.poc_id!=e.params.poc_id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setPOCData(data);
                                        setSeverity('success');
                                        setIcon('verified');
                                        setFeedbackTitle('POC Deleted');
                                        setFeedbackMessage('POC Deleted Successfully.')
                                        //console.log('content deleted');
                                    }catch(err){
                                        console.error(err.response.data.error);
                                        setSeverity('error');
                                        setIcon('error');
                                        setFeedbackTitle('Error Deleting Content');
                                        setFeedbackMessage(err.response.data.error)
                                        //console.log('error deleting content');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        <Stack direction="row" >
                            <OutlinedInput id="txtsearch" 
                                onKeyDown={searchInput} 
                                variant='outlined'
                                value={searchPhrase}
                                onChange={(e)=>setSearchPhrase(e.target.value)}
                                startAdornment={<InputAdornment position="end"><IconButton aria-label="search" edge="start" onClick={(e)=>searchInput({keyCode:13})}><SearchIcon /></IconButton></InputAdornment>}
                                sx={{verticalAlign:'top'}}
                            />
                            <Tooltip title="Delete Selected POCs">
                                <IconButton onClick={(e)=>{
                                    
                                    let rowdata = pocdata.rows.filter((d)=>selectedrowids.indexOf(d.poc_id)>=0);
                                    let delparams = [];
                                    rowdata.forEach((d)=>delparams.push({poc_id:d.poc_id}));
                                    setDeleteParams(delparams);
                                    setShowDeletePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><DeleteIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Add POC">
                                <IconButton onClick={(e)=>{setShowAddPOCDialogue(true)}} disabled={(selectedrowids.length>0)}><AddIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Refresh Data">
                                <IconButton onClick={(e)=>{resetData();}}><RefreshIcon /></IconButton>
                            </Tooltip>
                            
                        </Stack>
                        <DataGrid
                            rows={pocdata.rows}
                            columns={columns}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                pageSize: pageSize,
                                page:currentPage
                                },
                            },
                            }}
                            disableRowSelectionOnClick
                            pageSizeOptions={[5,10,25,50,100]}
                            //onRowSelectionModelChange={(val)=>alert(val)}
                            getRowId={(row)=>{return row.poc_id}}
                            fullWidth
                            id="report_table" 
                            className="hover row-border" 
                            onPaginationModelChange={handlePagination}
                            slots={{ 
                                    pagination : CustomPaginator,
                                    
                            }}
                            loading={isTableLoading}
                            checkboxSelection
                            onRowSelectionModelChange={(ids) => {
                                setSelectedRowIds(ids);
                                let rows = [];
                                ids.map((id)=>{ rows = rows.concat(pocdata.rows.filter((r)=>r.poc_id==id))});
                                setSelectedRows(rows);
                            }}
                            
                        >
                        </DataGrid>
                        <AddUpdatePOC showDialogue={(showAddPOCDialogue)} onCloseCallback={(e)=>{setShowAddPOCDialogue(false); setSelectedRecord();setTimeout(()=>setEditContent(false),250);}} onPOCAdded={pocAdded} onPOCUpdated={pocUpdated} edit={editContent} selectedRecord={selectedRecord} />
                    
                </>
            
        </div>

    )
}