import { Auth,API } from "aws-amplify";


import { GetAllLinks, DeleteLink, AddLink,DisableLink,DisableLinks,EnableLink,EnableLinks, UpdateLink , UpdateLinkAssignments} from "./Links";
import { GetAllReports, DeleteReport, AddReport, DisableReport,DisableReports,EnableReport,EnableReports, UpdateReport, UpdateReportAssignments } from "./Reports";
import { GetAllMessages, DeleteMessage, AddMessage,DisableMessage,DisableMessages,EnableMessage,EnableMessages, UpdateMessage, UpdateMessageAssignments } from "./Messages";
import { SendContentAssignmentNotification } from "./Customers";

const GetContent= async(rows, pageSize, page, search, sortBy) =>{
    
    return new Promise(async(resolve,reject)=>{

    if(search){
        search=search.toLowerCase();
    }
    let content=[];
    
    try{
    let promises = [];
    promises.push(GetAllLinks())
    promises.push(GetAllReports());
    promises.push(GetAllMessages());

    let retvals = await Promise.all(promises);

    //process links
    
    for(let link of retvals[0]){
        let item = link
        item.id =link.hosted_link_id;
        item.contentType= "link";
        delete item.hosted_link_id;
        if(search && (item.link.toLowerCase().indexOf(search)>=0
                      || item.title.toLowerCase().indexOf(search)>=0
                      || item.description.toLowerCase().indexOf(search)>=0 
                      )
                  || !search){

            content.push(item)
        }
    }

    //process reports
    for(let report of retvals[1]){
        let item = report;
        item.id = item.report_id;
        item.contentType="report"
        item.title=item.report_title;
        item.description = item.report_description;
        if(search && (item.title.toLowerCase().indexOf(search)>=0
                      || item.report_description.toLowerCase().indexOf(search)>=0
                      )
                  || !search){
            delete item.report_title;
            delete item.report_description;
            delete item.report_id;
            content.push(item)
        }
    }

    //process messages
    
    for(let message of retvals[2]){
        let item = message;
        item.id = item.message_id;
        item.contentType="message"
        item.title=item.message_title;
        item.description = item.subject_line;
        if(search && (item.message.toLowerCase().indexOf(search)>=0
                      || item.message_title.toLowerCase().indexOf(search)>=0
                      || item.subject_line.toLowerCase().indexOf(search)>=0 
                      )
                  || !search){
            delete item.message_title
            delete item.subject_line;
            delete item.message_id;
            content.push(item)
        }
    }

    if(!sortBy || sortBy == 'created_date'){
        //default sort
        content = content.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
    }else{
        //field sort
        content = content.sort((a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1);
    }
    
    }catch(err){
        console.error(err);
        reject(err);
    }

   
    resolve( {
          rows: content
        , totalItems: content.length
        , resolvedItems: content.length
    })
    
    });


}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DeleteContent = async(contentId,contentType)=>{
    let resp={};
    switch(contentType){
        case "link":
            resp = await DeleteLink(contentId);
        break;
        case "message":
            resp = await DeleteMessage(contentId);
        break;
        case "report":
            resp = await DeleteReport(contentId);
        break;

    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableContent = async(contentObjects)=>{
    let disableLinks = [];
    contentObjects.filter((c)=>c.contentType=='link').map((m)=>disableLinks.push(m.id));
    let disableMessages = [];
    contentObjects.filter((c)=>c.contentType=='message').map((m)=>disableMessages.push(m.id));
    let disableReports = [];
    contentObjects.filter((c)=>c.contentType=='report').map((m)=>disableReports.push(m.id));

    if(disableLinks.length>0){
        //console.log(`disabling ${disableLinks.length} Links`)
        await DisableLinks(disableLinks);
    }
    if(disableMessages.length>0){
        //console.log(`disabling ${disableMessages.length} Messages`)
        await DisableMessages(disableMessages);
    }
    if(disableReports.length>0){
        await DisableReports(disableReports);
        //console.log(`disabling ${disableReports.length} Reports`)
    }

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableContent = async(contentObjects)=>{
    let enableLinks = [];
    contentObjects.filter((c)=>c.contentType=='link').map((m)=>enableLinks.push(m.id));
    let enableMessages = [];
    contentObjects.filter((c)=>c.contentType=='message').map((m)=>enableMessages.push(m.id));
    let enableReports = [];
    contentObjects.filter((c)=>c.contentType=='report').map((m)=>enableReports.push(m.id));

    if(enableLinks.length>0){
        await EnableLinks(enableLinks);
    }
    if(enableMessages.length>0){
        await EnableMessages(enableMessages);
    }
    if(enableReports.length>0){
        await EnableReports(enableReports);
    }

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddContent=async(content)=>{
        
        
    let contentObj = {};
    let success = false;
    
    switch(content.contentType){
        case "link":
            try{
    
            contentObj = await AddLink(content.linkTitle, content.linkDescription, content.linkUrl);
            contentObj.title = content.linkTitle;
            contentObj.description = content.linkDescription;
            contentObj.contentType='link';
            contentObj.id=contentObj.hosted_link_id;
            success=true;
            }catch(err){
                console.error(err);
            }
            
        break;
        case "message":
            try{
                contentObj = await AddMessage(content.messageTitle, content.messageSubject, content.messageBody);
                contentObj.title = contentObj.message_title;
                contentObj.description = contentObj.subject_line;
                contentObj.contentType='message';
                contentObj.id=contentObj.message_id;
                success=true;
            }catch(err){
                console.error(err);
            }
        break;
        case "report":
            try{
                contentObj = await AddReport(content.reportTitle, content.reportDescription, content.reportFile);
                contentObj.title = contentObj.report_title;
                contentObj.description = contentObj.report_description;
                contentObj.contentType='report';
                contentObj.id=contentObj.report_id;
                
                success=true;
                
            }catch(err){
                console.error(err);
            }
                

        break;
    }
    
    return contentObj;

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UpdateContent=async(content)=>{
    
    let contentObj = {};
    let success = false;
    
    switch(content.contentType){
        case "link":
            try{
                await UpdateLink(content.id, content.linkTitle, content.linkDescription, content.linkUrl);
                contentObj.title = content.linkTitle;
                contentObj.description = content.linkDescription;
                contentObj.link = content.linkUrl;
                success=true;
            }catch(err){
                console.error(err);
            }
            
        break;
        case "message":
            try{
                
                await UpdateMessage(content.id,content.messageTitle, content.messageSubject, content.messageBody);
                contentObj.title = content.messageTitle;
                contentObj.description = content.messageSubject;
                success=true;
            }catch(err){
                console.error(err);
            }
        break;
        case "report":
            try{
                await UpdateReport(content.id, content.reportTitle, content.reportDescription, content.reportFile);
                contentObj.title = content.reportTitle
                contentObj.description = content.reportDescription
                success=true;
                
            }catch(err){
                console.error(err);
            }
        break;
    }
    
    
    return contentObj;
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddContentAssignments = async(assignmentList,replace=false)=>{
    const assignments = [...assignmentList];
    let links = assignments.filter((a)=>a.contentType=='link');
    let messages = assignments.filter((a)=>a.contentType=='message');
    let reports = assignments.filter((a)=>a.contentType=='report');

    let promises=[];
    for(let l of links){
        //console.log('update link',l);
        promises.push(UpdateLinkAssignments(l.id,l.customers, replace));
    }
    
    for(let m of messages){
        //console.log('update messages',m);
        promises.push(UpdateMessageAssignments(m.id,m.customers, replace));
    }
    
    for(let r of reports){
        //console.log('update reports',r);
        promises.push(UpdateReportAssignments(r.id,r.customers, replace));
    }

    let res = await Promise.all(promises);
    //console.log(res);

    let notifications = [];
    for(let assignment of assignments){
        delete assignment.id;
        delete assignment.contentType;
        notifications.push(SendContentAssignmentNotification(assignment));
    }
    let nres = await Promise.all(notifications);    

    return res;

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { GetContent, DeleteContent, AddContent, DisableContent, EnableContent, UpdateContent, AddContentAssignments};
