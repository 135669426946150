
//styling
import '../App.css';
import HeaderLogo from "../images/abbott-header-logo.png"
import HeaderImage from "../images/img_header.png";
import "../css/customer_portal.css";
import "../css/normalize.css";

//react components
import React, { Component, useState, useEffect  } from "react";
import { useNavigate } from 'react-router-dom';



export default function Redirect(props){
  
    const navigate = useNavigate();
    
    useEffect(()=>{
    
        if(props.to){
            navigate(props.to);
        }
        
    },[]);


    return(
        <></>
    )

}