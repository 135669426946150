//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";
import { useFormContext, FormProvider } from 'react-hook-form';



//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Alert, AlertTitle } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';



//customcomponents
import AssignOption  from './AssignOption';
import IconSelector from '../../IconSelector';

//libraries

import { Button, LinearProgress } from '@mui/material';
import { GetAllPOC } from '../../../libs/POC';
import { AddLocation, UpdateLocation } from '../../../libs/Locations';

export default function LocationForm(props){

    
    const [showDialogue,setShowDialogue]=useState(false);
    const [isLoading, setIsLoading]=useState(false);
    const [initial,setInitial]=useState();
    const [assignOptionDialogueVisible, setAssignOptionDialogeVisible]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [resetForm,setResetForm]=useState();
    const [editMode,setEditMode]=useState();
    const [selectedRecord,setSelectedRecord]=useState();
    
    
    
    const [pocData,setPOCData]=useState();
    const [locations, setLocations]=useState();
    const [locationName,setLocationName]=useState();
    const [locationAddress1,setLocationAddress1]=useState();
    const [locationAddress2,setLocationAddress2]=useState();
    const [locationCity,setLocationCity]=useState();
    const [locationState,setLocationState]=useState();
    const [locationZip,setLocationZip]=useState();
    const [locationCountry,setLocationCountry]=useState();
    const [locationPOC, setLocationPOC]=useState();
    const [customerId, setCustomerId]=useState();
    
   
    //const { getValues } = useFormContext();

    useEffect(()=>{
        resetFeedback();
    },[showDialogue])

    useEffect(()=>{
        if(props.customerId){
            setCustomerId(props.customerId);
        }
    }, [props.customerId])
    
    useEffect(()=>{
        
        if(selectedRecord){
            //console.log('selected record',selectedRecord);
            setLocationName(selectedRecord.location_name);
            setLocationAddress1(selectedRecord.address_line1);
            setLocationAddress2(selectedRecord.address_line2);
            setLocationCity(selectedRecord.city);
            setLocationState(selectedRecord.state);
            setLocationZip(selectedRecord.zip);
            setLocationCountry(selectedRecord.country);
            setLocationPOC(pocData.rows.filter((p)=>p.poc_id==selectedRecord.customer_poc_id)[0]);
        }
        
    },[selectedRecord])

   

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        resetFeedback();
        if(!initial){
            setInitial(true);
        }

        //console.log(`edit mode is set on location form with value of ${props.edit}`);
        setEditMode(props.edit);
        if(props.edit && props.selectedRecord){
            setSelectedRecord(props.selectedRecord);
        }else{
            clearForm();
        }
        
        LoadPOC();

      }, [props.showDialogue]);


    const LoadPOC = async()=>{
        let poc = await GetAllPOC();
        //console.log('poc data',poc);
        setPOCData(poc);
    }
    
    
    const UpdateLocationObj=async(e)=>{
        let contentObj = selectedRecord;
        let success = false;
        let locationObject = {
            location_id: selectedRecord.location_id
          , customer_id: customerId
          , location_name: locationName
          , address_line1: locationAddress1
          , address_line2: locationAddress2
          , city: locationCity
          , state: locationState
          , zip: locationZip
          , country: locationCountry
          , is_active: 'Y'
      }

      if(locationPOC && locationPOC.poc_id!=undefined){
        locationObject.customer_poc_id = locationPOC.poc_id;
      }




        setIsLoading(true);
        let pocObj;
        try{
            //console.log(`update location ${JSON.stringify(locationObject)}`)
            //await UpdateReport(selectedRecord.id, reportTitle, reportDescription, reportFile);
            /*await UpdateContent({contentType: contentType
                                , id: selectedRecord.id
                                , reportTitle: reportTitle
                                , reportDescription : reportDescription
                                , reportFile: reportFile});*/
            //pocObj = await UpdatePOC(selectedRecord.poc_id, pocFullName,pocCellPhone,pocEmail,pocImageB64,pocDeskPhone,pocTitle,isDefaultPOC);
            //console.log('updated POC object');
            
            let updLocation = await UpdateLocation(customerId,selectedRecord.location_id, locationName,locationAddress1,locationAddress2,locationCity,locationState,locationZip,locationCountry,(locationPOC&&locationPOC.poc_id)?locationPOC.poc_id:undefined);
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('Location Updated Successfully');


            success=true;
            if(props.onLocationUpdated){
                props.onLocationUpdated(locationObject)
            }

            
        }catch(err){
            setFeedbackTitle('Error Updating Location');
            setSeverity('error');
            setIcon('error');
            console.error(err);
            setFeedbackMessage(JSON.stringify(err.response.data.error));
        }
                    

           
        setIsLoading(false);
        
       
    }
    const AddLocationObj=async(e)=>{
        
        
        let pocObj = {};
        let success = false;
        /*let locationObject = {
              location_id: Math.random()
            , customer_id: customerId
            , location_name: locationName
            , address_line1: locationAddress1
            , address_line2: locationAddress2
            , city: locationCity
            , state: locationState
            , zip: locationZip
            , country: locationCountry
            , is_active: 'Y'
        }*/

        setIsLoading(true);
        try{
            
            /*//AddPOC(fullname,cell,email,imageb64,phone,title, isDefault)
            pocObj = await AddPOC(pocFullName,pocCellPhone,pocEmail,pocImageB64,pocDeskPhone,pocTitle,isDefaultPOC);
            
            console.log('added poc');
            console.log(pocObj);*/
            let locationObject = await AddLocation(customerId, locationName,locationAddress1,locationAddress2,locationCity,locationState,locationZip,locationCountry);
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('Location Added Successfully');
            
            //setTimeout(()=>resetFeedback(),3000);
            if(props.onLocationAdded){
                props.onLocationAdded(locationObject)
            }

            clearForm();
            //setAssignOptionDialogeVisible(true);
            //setResetForm(Math.random());

            success=true;
        }catch(err){
            setFeedbackTitle('Error Adding Customer');
            setSeverity('error');
            setIcon('error');
            if(err.response){
            setFeedbackMessage(JSON.stringify(err.response.data.error));
            }else{
                setFeedbackMessage(JSON.stringify(err));
            }
        }
            
        setIsLoading(false);


       

    }

    
    const clearForm=()=>{
        setLocations();
        setLocationName();
        setLocationAddress1();
        setLocationAddress2();
        setLocationCity();
        setLocationState();
        setLocationZip();
        setLocationCountry();
        setLocationPOC();
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackTitle();
        setFeedbackMessage();
    }
  

    const closeDialogue=(e)=>{

        clearForm();
        resetFeedback();
        setSelectedRecord();
        setShowDialogue(false)
        if(props.onCloseCallback){
            props.onCloseCallback(e);
        }
    }
    
    const removeLocation = (locationId)=>{
        let crrlocationss = locations;
        setLocations(locations.filter((a)=>a.location_id!=locationId));
    }

    return(
       <>
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '600px',
                maxHeight: '90%',
                minWidth: '800px',
                maxWidth: '90%'
              }}}
        >
            <DialogTitle>
                 {(editMode)?'Edit':'Add'} Location
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {(isLoading)?<LinearProgress />:<></>}
                <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                    <AlertTitle>{feedbackTitle}</AlertTitle>
                    {feedbackMessage}
                </Alert>
                <Button style={{visibility: ((severity=='success'&&props.onMakeAssignments)) ? 'visible' : 'hidden' }} onClick={(e)=>props.onMakeAssignments()}>Manage assignments</Button>
                <div style={{padding:'10px'}}>
                
                </div>
                <div style={{padding:'10px'}}>
                <Stack>
                    <FormControl fullWidth>
                        <FormLabel id="location-name-label" >Location Name</FormLabel>
                        <TextField id="location-name" 
                                    aria-labelledby="location-name-label" 
                                    variant="outlined" 
                                    required
                                    value={locationName}
                                    onChange={(e)=>setLocationName(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-address1-label" >Address 1</FormLabel>
                        <TextField id="customer-address1" 
                                    aria-labelledby="customer-address1-label" 
                                    variant="outlined" 
                                    required
                                    value={locationAddress1}
                                    onChange={(e)=>setLocationAddress1(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-address2-label" >Address 1</FormLabel>
                        <TextField id="customer-address2" 
                                    aria-labelledby="customer-address2-label" 
                                    variant="outlined" 
                                    required
                                    value={locationAddress2}
                                    onChange={(e)=>setLocationAddress2(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-city-label" >City</FormLabel>
                        <TextField id="customer-city" 
                                    aria-labelledby="customer-city-label" 
                                    variant="outlined" 
                                    required
                                    value={locationCity}
                                    onChange={(e)=>setLocationCity(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-state-label" >State</FormLabel>
                        <TextField id="customer-state" 
                                    aria-labelledby="customer-state-label" 
                                    variant="outlined" 
                                    required
                                    value={locationState}
                                    onChange={(e)=>setLocationState(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-zip-label" >Zip/Postal Code</FormLabel>
                        <TextField id="customer-zip" 
                                    aria-labelledby="customer-zip-label" 
                                    variant="outlined" 
                                    required
                                    value={locationZip}
                                    onChange={(e)=>setLocationZip(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-country-label" >Country</FormLabel>
                        <TextField id="customer-country" 
                                    aria-labelledby="customer-country-label" 
                                    variant="outlined" 
                                    required
                                    value={locationCountry}
                                    onChange={(e)=>setLocationCountry(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        {(pocData)?
                            <>
                                <FormLabel id="customer-poc-label" >POC Override</FormLabel>
                                <Autocomplete
                                    disablePortal
                                    id="ddlPOC"
                                    options={pocData.rows}
                                    getOptionLabel={(option) => option.poc_fullname}
                                    renderInput={(params) => <TextField {...params} aria-labelledby="customer-poc-label" />}
                                    onChange={(e,v)=>{setLocationPOC(v)}}
                                    key={locationPOC}
                                    value={locationPOC}
                                />
                            </>
                            
                        :<></>
                        }
                    </FormControl>

                </Stack>
                
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={()=>(editMode)?UpdateLocationObj():AddLocationObj()} loading={isLoading}>{(editMode)?'Update':'Add'}</LoadingButton>
                <Button onClick={()=>closeDialogue()}>Cancel</Button>                
            </DialogActions>
        </Dialog>
        
        </>
        

    )
}