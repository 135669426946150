import "../App.css";
import React, {
    useState,
    useEffect,
} from "react";
  
import axios from 'axios';

import { useSearchParams } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
  
import { useParams, Router } from 'react-router';
import {isMobile} from 'react-device-detect';

import { GetReportById } from "../libs/Reports";

export const ReportViewer = ({...props})=>{
    const [searchParams,setSearchParams ] = useSearchParams();
    const [reporturl, setReportUrl]=useState();
    const [reporttitle, setReportTitle]=useState();
    const [ loadurl, setLoadUrl ]=useState();
    //const [ reporttitle, setReportTitle ]=useState();
    let loadcount=0;

    useEffect(()=>{
        
        const loadreport = async(reportid)=>{
            if(reportid){
                try{
                    let selreport = await GetReportById(reportid);
                    //console.log('selreport',selreport);
                    if(selreport){
                        let rsp = await axios.get(selreport.report_presigned_url, {responseType: 'blob'});
                        const targeturl = URL.createObjectURL(rsp.data);
                        let ext = selreport.report_file_name.match(/\.(.[^\.]+)$/)
                        //console.log(ext);
                        if(ext[1]=='pdf'){
                            //display in viewer    
                            //console.log(selreport.report_presigned_url);
                            setReportUrl(targeturl);
                            setReportTitle(selreport.report_title);
                        }else{
                            //download report   
                            const a = document.createElement('a');
                            a.download = selreport.report_title + '.' + ext[1];
                            a.href = targeturl;
                            a.addEventListener('click', (e) => {
                                setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
                            });
                            a.click();
                        }
                    }
                }catch(err){
                    console.error(err);
                }
            }
        }
        
        if(loadcount==0){
            loadcount++;
            loadreport(searchParams.get('reportid'));
        }

       
    },[]);

   
    
    return (
        
        (reporturl)
            ?(isMobile)
                ?<a href={reporturl}>Download File:  {reporttitle}</a>
                :<iframe src={reporturl} width="100%" style={{border:"0px solid black",height:'96vh'}} />
            :<LinearProgress />
    );


  }
