////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const PasswordValidityChecks =  [
    {
          id:1
        , rx:/[a-z]/
        , message:'Password must contain a lower case letter'
    },
    {
        id:2
      , rx:/[A-Z]/
      , message:'Password must contain an upper case letter'
    },
    {
        id:3
        , rx:/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/
        , message:'Password must contain a special character'
    },
    {
        id:4
        , rx:/[0-9]/
        , message:'Password must contain a number'
    },
    {
        id:4
        , rx:/.{8}/
        , message:'Password must contain at least 8 characters'
    }
  
]
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const  CheckPassword = (password)=>{
    
    let messages = [];
    for(let ck of PasswordValidityChecks){
        if(!(ck.rx.test(password))){
            messages.push(ck.message);
        }
    }
    return messages;
  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const CheckEmail = (email)=>{

    if(email) {
        return email.toString().match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }else{
        return false;
    }
      
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ValidationCodeCheck = (code)=>{
    if(code){
        return code.match(/^[0-9]{6}/);
    }else{
        return false;
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export { PasswordValidityChecks, CheckPassword, CheckEmail,ValidationCodeCheck }