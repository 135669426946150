//styling
import '../../App.css';
import HeaderLogo from "../../images/abbott-header-logo.png"
import HeaderImage from "../../images/img_header.png";
import "../../css/customer_portal.css";
import "../../css/normalize.css";

//react components
import React, { Component, useState, useEffect  } from "react";

//third-party components
import { Alert, AlertTitle } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Auth } from "aws-amplify";
import AuthenticatedContent from "../UserPortal/AuthenticatedContent";


//customcomponents
import SignIn from "./SignIn";
import IconSelector from "../IconSelector";



let feedbackmessage;
export default class LoginForm extends Component {
    
  state = {
      handleSignInCallback:undefined
    ,  userfeedback:''
  };

  
  
  authcanceled=false;


  async componentDidMount() {
    
  }

  // Handle changes to form inputs on sign-in
  handleFormInput = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  resetForm = event =>{
    this.setState({
        username:'',password:''
    })
  }

  displayUserFeedback = (message,severity,title, icon) =>{
    this.setState({
        userfeedback: message,
        severity: severity ||'info',
        title: title || '',
        icon: icon || 'InfoIcon'
    })
  }
  
  switchComponent = status => {
    this.setState({ status });
  };

  forgotPassword = () =>{
    this.setState({status:"ForgotPassword"});
  }

  render() {
    return (
      <>
       
        <h1>Managed Services Portal</h1>
        <SignIn
                switchComponent={this.switchComponent}
                handleFormInput={this.handleFormInput}
                displayUserFeedback={this.displayUserFeedback}
                resetForm={this.resetForm}
                forgotPassword={this.forgotPassword}
                inputs={this.state}
                onAuthenticated={this.props.onAuthenticated}
            />
            
        
    </>
      
    );
  }
}