//styling
import '../../App.css';
import HeaderLogo from "../../images/abbott-header-logo.png"
import HeaderImage from "../../images/img_header.png";
import "../../css/customer_portal.css";
import "../../css/normalize.css";

//react components
import React, { Component, useState, useEffect  } from "react";
import { useNavigate } from 'react-router-dom';

//third-party components and libraries
import { Auth } from "aws-amplify";
import LoadingButton from '@mui/lab/LoadingButton';

//customcomponents

//libraries
import  { PasswordValidityChecks, CheckPassword, CheckEmail, ValidationCodeCheck } from '../../libs/ValidityChecks.js';
import { Check } from '@mui/icons-material';


//export default class ResetPassword extends Component {
export default function ResetPassword(props){
  const [passwordvalidationmessages,setPasswordValidationMessages]=useState([]);
  const [passwordchecks, setPasswordChecks]=useState([]);
  const [passwordsmatchmessage, setPasswordsMatchMessage]=useState('');
  const [invalidcodemessage, setInvalidCodeMessage]=useState('');
  const [invalidusernamemessage, setInvalidUsernameMessage]=useState('');
  const [displayerror, setDisplayError]=useState(false);
  const [forminput, setFormInput]=useState({username:'',code:'',password1:'',password2:''});
  const [isLoading,setIsLoading]=useState();
  const navigate = useNavigate();

  useEffect(()=>{
  
    const passwordchecks = PasswordValidityChecks

    let passwordmessages =[]
    for(let check of passwordchecks){
        passwordmessages.push(check.message);
    }
    setPasswordChecks(passwordchecks);
    setPasswordValidationMessages(passwordmessages);
      
  },[]);

  

  // Handle changes to form inputs and validate
  const handleFormInput = event => {
    
    let obj = forminput;
    obj[event.target.name]=event.target.value;
    
    setFormInput(obj);

    
    switch(event.target.name){
        case "password1":
            let invalidmessages = CheckPassword(event.target.value);
            setPasswordValidationMessages(invalidmessages);
        break;
        case "password2":
            let message = (event.target.value!=forminput.password1)?'Password doesn\'t match':''
            setPasswordsMatchMessage(message);
        break;
        /*case "code":
          if(!ValidationCodeCheck(event.target.value)){
            setInvalidCodeMessage('Code must be 6 digits.');
          }else{
            setInvalidCodeMessage('');
          }
        break;*/
        case "username":
          if(!CheckEmail(event.target.value.toLowerCase())){
            setInvalidUsernameMessage('Username must be formatted as an email.');
          }else{
            setInvalidUsernameMessage('');
          }
        break;
    }
  };

  
  const isValidForm = ()=>{
    let retval=false;
    try{
        retval = (forminput && CheckEmail(forminput.username) && forminput.code.length==6  && passwordvalidationmessages && passwordvalidationmessages.length==0 && passwordsmatchmessage=='' )
    }catch(err){
        retval=false;
    }
    return retval;
  }

  const resetPassword = async(event) => {
    event.preventDefault();
    if(isValidForm()){
      setIsLoading(true);
      const username = forminput.username;
      const code = forminput.code
      const newpassword = forminput.password1;
    
        
        Auth.forgotPasswordSubmit(username.toLowerCase(), code, newpassword)
        .then((data)=>{
            setIsLoading(false);
            navigate('/');
          } 
        )
        .catch((err)=>{
          //console.log(err);
          setIsLoading(false);
          setDisplayError(true);
        });
        
    }
    

  }

  

  
    return (
      <>
       
          <h1>Reset Password</h1>
          <p></p>
          <form id="resetPasswordForm">
            <div id="resetPasswordError" className="formresult padding_bottom_10" style={{visibility:(displayerror)?'visible':'hidden'}}>Could not reset password for the account. Please try again or go to <a href="/recoverpassword">Forget Password</a> to request a reset password link.</div>
            <div className="padding_bottom_20">
              <label>User Name:</label><input type="text" name="username" id="usernameInputResetPassword" required onChange={handleFormInput} />
            </div>
            <div className="password_reminder">
              <p>{invalidusernamemessage}</p>
            </div>
            <div className="padding_bottom_20">
              <label>Code:</label><input type="text" name="code" id="codeResetPassword" required onChange={handleFormInput}/>
            </div>
            <div className="password_reminder">
              <p>{invalidcodemessage}</p>
            </div>
            <div>
              <label>New Password:</label><input type="password" name="password1" id="newPasswordInput" placeholder="New Password" pattern=".*" required onChange={handleFormInput}/>
            </div>
            <div className="password_reminder">
              {passwordvalidationmessages.map((m)=> <p key={passwordvalidationmessages.indexOf(m)}>{m}</p>)}
            </div>
            <div className="padding_top_20">
              <label>Re-enter new password:</label><input type="password" name="password2" id="newPassword2Input" placeholder="New Password" pattern=".*" required onChange={handleFormInput}/>
            </div>
            <div className="password_reminder"><p>{passwordsmatchmessage}</p></div>
            <div className="center padding_top_40 padding_bottom_40">
              <LoadingButton onClick={resetPassword} loading={isLoading} disabled={!isValidForm()} variant="apoc">submit</LoadingButton>
            </div>
          </form>
    </>
        
    );
  
}