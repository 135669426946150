import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";

import React, { Component, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import PromptUser from '../PromptUser'

import { Auth } from "aws-amplify";
import { useNavigate } from 'react-router-dom';
import { useStepContext } from '@mui/material';



export default function SignOut(props){
  
  const [showLogoutPrompt,setShowLogoutPrompt]=useState(false);
  const [autoLogoutInterval, setAutoLogoutInterval]=useState();

  const signOut= async(event) => {
    event?.preventDefault();
    Auth.signOut();
    if(props.onSignOut){
        props.onSignOut();
    }
    
  }

  useEffect(()=>{
    if(props.triggerSignOut){
      
      setAutoLogoutInterval(setTimeout(()=>{
        signOut();
      },30000));

      setShowLogoutPrompt(true);
      
    }
   }, [props.triggerSignOut]);
 

  

 
    return (
        <>
          <div className="logout">
              <a onClick={signOut} className={props.className} >LOGOUT</a>
          </div>

          <PromptUser showDialogue={showLogoutPrompt}
          Title="User Inactivity Detected"
          Prompt="Your session has been inactive.  You will be automatically signed out in 30 seconds.  Would you like to continue your session?" 
          Params={{triggerSignOut:true}}
          onCallback={async (e)=>{
              
              setShowLogoutPrompt(false);
              clearInterval(autoLogoutInterval);
              if(e.response.toLowerCase()=='yes'){
                      //continue session
                      if(props.onContinueSession){
                        props.onContinueSession();
                      }
              }else{
                signOut();
              }
              
          }}
          />
        </>
    );
  
}