
import { Auth,API } from "aws-amplify";
import { insert,remove } from "./ArrayHelper";


const APINAME='user'



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetUserInfo = async(sub) =>{
    
    return new Promise(async (resolve,reject)=>{
        try{
            //let user = await Auth.currentAuthenticatedUser();
            let userinfo = await API.get(APINAME,'/'+sub);
            resolve(userinfo);
        }catch(err){
            reject(err);
        }
        
    })
    
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetAllUsers=async(rows, pageSize=5, currentPage=0, search, filter, sortBy, order)=>{
    
    //console.log('rows start',rows);

    return new Promise(async (resolve,reject)=>{
        try{
           //console.log(`received search result request ${search}`);

            const qParams = new URLSearchParams();
            qParams.append("perPage", pageSize);
            qParams.append("page", currentPage);
            if(search){
                qParams.append("q",search);
                //console.log('including search parameter ', search)
                rows=[];
            }else{
                //console.log('no search parameter');
            }
            if(sortBy){
                qParams.append("field",sortBy);
            }
            if(order){
                qParams.append("order",order);
            }
            if(filter){
                qParams.append("filter",filter);
            }
            
                
                //console.log(`rows length start ${rows.length}`);
                rows = rows.filter(f=>f.user_id.indexOf('dummy')!=0)
                //console.log(`rows after dummy filter`, rows);
                let retdata = await API.get(APINAME,'/' + (qParams)? `?${qParams.toString()}`:'');
                
                
                //console.log(`rows length after api call ${rows.length}`);
                let users = retdata.data;
                let totalitems = retdata.total;
                rows = (Array.isArray(rows))?rows.concat(users):users;
                //console.log('rows after result concat', rows);
                
                let dummyitems=0;
                if(rows.length!=totalitems){
                    //for(let i=startIndex+pageSize;i<totalitems;i++){
                    for(let i=rows.length;i<totalitems;i++){
                        rows.push({user_id:`dummy${i}`});
                    }
                }
                
                dummyitems =  rows.filter(f=>f.user_id.indexOf('dummy')==0).length;
                //console.log(`contains ${dummyitems} dummy rows`);
                
               
                if(!sortBy || sortBy == 'date_created'){
                    //default sort
                    rows = rows.sort((a, b) => (a.customer_name && b.customer_name)?a.customer_name.toLowerCase() > b.customer_name.toLowerCase() ? 1 : -1:1);
                }else{
                    //field sort
                    rows = rows.sort((a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1);
                }

                if(rows.length>totalitems){
                    rows = rows.splice(0,totalitems);
                }

                //console.log('rows end',rows);
                resolve({
                      rows : rows
                    , totalItems : totalitems
                    , resolvedItems : totalitems-dummyitems
                });
           
            
        }catch(err){
            reject(err);
        }
        
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableUser = async(userid)=>{
    return new Promise(async(resolve,reject)=>{
        let userData = {
            is_active: 'N'
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: userData
        }
        try{
            let userinfo = await API.put(APINAME,'/'+userid, req);
            resolve(userinfo);
        }catch(err){
            console.error('Error disabling user.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableUsers=async(users)=>{
    let promises = []
    for(let user of users){
        promises.push(DisableUser(user.id));
    }
    return await Promise.all(promises);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableUser = async(userid)=>{
    return new Promise(async(resolve,reject)=>{
        let userData = {
            is_active: 'Y'
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: userData
        }
        try{
            let userinfo = await API.put(APINAME,'/'+userid, req);
            resolve(userinfo);
        }catch(err){
            console.error('Error enabling user.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableUsers=async(users)=>{
    let promises = []
    for(let user of users){
        promises.push(EnableUser(user.id));
    }
    return await Promise.all(promises);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UpdateUser = async(userId, userdata)=>{
    return new Promise(async(resolve,reject)=>{
        
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: userdata
        }
        try{
            let userinfo = await API.put(APINAME,'/'+userId, req);
            resolve(userinfo);
        }catch(err){
            console.error('Error enabling user.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddUser = async(userdata)=>{
    return new Promise(async(resolve,reject)=>{
        
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: userdata
        }
        try{
            let userinfo = await API.post(APINAME,'/', req);
            resolve(userinfo);
        }catch(err){
            console.error('Error adding user.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ResetPassword = async(userdata)=>{
    return new Promise(async(resolve,reject)=>{

        userdata.action='password_reset';
        
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: userdata
        }
        try{
            let userinfo = await API.post(APINAME,'/', req);
            resolve(userinfo);
        }catch(err){
            console.error('Error adding user.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ResendInvitation = async(userdata)=>{
    return new Promise(async(resolve,reject)=>{

        userdata.action='invitation_resend';
        
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: userdata
        }
        try{
            let userinfo = await API.post(APINAME,'/', req);
            resolve(userinfo);
        }catch(err){
            console.error('Error adding user.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DeleteUser = async(userId)=>{
    return new Promise(async(resolve,reject)=>{
        
        let req = {
              headers: {'Content-Type':'application/json'}
        }
        try{
            let userinfo = await API.del(APINAME,'/'+userId, req);
            resolve(userinfo);
        }catch(err){
            console.error('Error deleting user.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { GetUserInfo,GetAllUsers,EnableUsers,DisableUsers, AddUser, UpdateUser, DeleteUser,ResetPassword,ResendInvitation}