import { createTheme } from "@mui/material";

const muitheme = createTheme({
    typography: {
        button: {
          fontSize: '1.25em',
        },
    },
    palette:{
        mode:"light",
        primary:{
            main:'#009CDE',
            light:'#009CDE',
            dark:'#009CDE',
            contrastText:'#ffffff'
        },
        secondary:{
            main:'#009CDE',
            light:'#009CDE',
            dark:'#009CDE',
            contrastText:'#ffffff'
        },
        action:{
            active:'#009CDE',
            hover:'#bce1f4',
            hoverOpacity:0.12,
            selected:'#009CDE',
            selectedOpacity:0.08,
            disabled: '#C0C0C0',
            disabledBackground:'#C0C0C0',
            disabledOpacity: 0.38,
            focus:'#009CDE',
            focusOpacity:0.12,
            activatedOpacity:0.12
        }
       
    },
    components: {
        MuiButton: {
          variants: [
            {
                props: { variant: 'apoc' },
                style: {
                    unset:'all',
                    textTransform: 'uppercase',
                    border: 'none',
                    backgroundColor:'#009CDE',
                    color: '#ffffff',
                    padding: '10px;',
                    width: '200px',
                    hoverOpacity:0.82,
                    '&:hover': {
                        backgroundColor: '#009CDE',
                        color: '#ffffff',
                        
                    },
                }

            }
          ],
        },
        "MuiDataGrid": {
            styleOverrides:{
                root:{
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none'
                    }
                },
                cell:{
                },
               
            }
        },
        "MuiDataGrid-cell": {
            styleOverrides:{
                    'focus-within':{
                        outline: "none !important",
                    }
                
            }
        },
    },
   
   
})

export {muitheme}
