//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";
import { useFormContext, FormProvider } from 'react-hook-form';



//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Alert, AlertTitle } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';



//customcomponents
import AssignOption  from './AssignOption';
import IconSelector from '../../IconSelector';

//libraries

import { Button, LinearProgress } from '@mui/material';
import { GetAllPOC } from '../../../libs/POC';
import { AddLocation, UpdateLocation } from '../../../libs/Locations';
import { GetAllRoles } from '../../../libs/Customers';
import { AddUser, UpdateUser } from '../../../libs/User';

export default function UserForm(props){

    
    const [showDialogue,setShowDialogue]=useState(false);
    const [isLoading, setIsLoading]=useState(false);
    const [initial,setInitial]=useState();
    const [assignOptionDialogueVisible, setAssignOptionDialogeVisible]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [resetForm,setResetForm]=useState();
    const [editMode,setEditMode]=useState();
    const [selectedRecord,setSelectedRecord]=useState();
    
    
    
    const [roleData,setRoleData]=useState();
    const [locations, setLocations]=useState();
    const [customerId, setCustomerId]=useState();
    const [userName,setUserName]=useState();
    const [email,setEmail]=useState();
    const [title, setTitle]=useState();
    const [phone, setPhone]=useState();
    const [userLocation,setUserLocation]=useState();
    const [userRole, setUserRole]=useState();
    

    
   
    //const { getValues } = useFormContext();

    useEffect(()=>{
        resetFeedback();
    },[showDialogue])

    useEffect(()=>{
        if(props.customerId){
            setCustomerId(props.customerId);
        }
    }, [props.customerId])
    
    useEffect(()=>{
        
        if(selectedRecord){
            //console.log('selected record',selectedRecord);
            setUserName(selectedRecord.user_name);
            setEmail(selectedRecord.email);
            setTitle(selectedRecord.job_title);
            setPhone(selectedRecord.user_telephone);
            setUserLocation(locations.filter((l)=>l.location_id==selectedRecord.locations[0].location_id)[0]);
            setUserRole(selectedRecord.locations[0].roles[0]);
        }
        
    },[selectedRecord])

   useEffect(()=>{
        if(locations){
            //console.log('choose from locations',locations);
        }
   },[locations]);

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        resetFeedback();
        if(!initial){
            setInitial(true);
        }

        //console.log(`edit mode is set on location form with value of ${props.edit}`);
        setEditMode(props.edit);
        if(props.selectedLocations){
            setLocations(props.selectedLocations);
        }
        if(props.edit && props.selectedRecord){
            setSelectedRecord(props.selectedRecord);
        }else{
            clearForm();
        }
        
        
        LoadRoles();

      }, [props.showDialogue]);



    const LoadRoles = async()=>{
        let roles = await GetAllRoles();
        //console.log(roles);
        setRoleData(roles);
    }

    const UpdateUserObj=async(e)=>{
        
        let success = false;
        let userObject = {
            customer_id: customerId
          , user_name: userName
          , email: email
          , job_title: title
          , user_telephone: phone
          , user_id:selectedRecord.user_id
          , locations: [
            {
                 location_id : userLocation.location_id
                , roles : [userRole]
            }
          ]
        }

        setIsLoading(true);
        
        try{
            //console.log(`update user ${JSON.stringify(userObject)}`)
            
            let updUser = await UpdateUser(selectedRecord.user_id,userObject);
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('User Updated Successfully');


            success=true;
            if(props.onLocationUpdated){
                props.onLocationUpdated(userObject)
            }

            
        }catch(err){
            setFeedbackTitle('Error Updating User');
            setSeverity('error');
            setIcon('error');
            //console.error(err);
            setFeedbackMessage(JSON.stringify(err.response.data.error));
        }
                    

           
        setIsLoading(false);
        
       
    }
    const AddUserObj=async(e)=>{
        
        
        let pocObj = {};
        let success = false;
        let userObject = {
            customer_id: customerId
          , user_name: userName
          , email: email
          , job_title: title
          , user_telephone: phone
          , locations: [
            {
                 location_id : userLocation.location_id
                , roles : [userRole]
            }
          ]
        }

        setIsLoading(true);
        try{
            
            /*//AddPOC(fullname,cell,email,imageb64,phone,title, isDefault)
            pocObj = await AddPOC(pocFullName,pocCellPhone,pocEmail,pocImageB64,pocDeskPhone,pocTitle,isDefaultPOC);
            
            console.log('added poc');
            console.log(pocObj);*/
            //let locationObject = await AddLocation(customerId, locationName,locationAddress1,locationAddress2,locationCity,locationState,locationZip,locationCountry);
            let userdata = await AddUser(userObject);
            //console.log(userdata);
            userObject.user_id=userdata.user_id;
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('User Added Successfully');
            
            //setTimeout(()=>resetFeedback(),3000);
            if(props.onLocationAdded){
                props.onLocationAdded(userObject)
            }

            clearForm();
            //setAssignOptionDialogeVisible(true);
            //setResetForm(Math.random());

            success=true;
        }catch(err){
            setFeedbackTitle('Error Adding User');
            setSeverity('error');
            setIcon('error');
            if(err.response){
            setFeedbackMessage(JSON.stringify(err.response.data.error));
            }else{
                setFeedbackMessage(JSON.stringify(err));
            }
        }
            
        setIsLoading(false);


       

    }

    
    const clearForm=()=>{
        setUserName();
        setEmail();
        setTitle();
        setPhone();
        setUserLocation();
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackTitle();
        setFeedbackMessage();
    }
  

    const closeDialogue=(e)=>{

        clearForm();
        resetFeedback();
        setSelectedRecord();
        setShowDialogue(false)
        if(props.onCloseCallback){
            props.onCloseCallback(e);
        }
    }
    
    

    return(
       <>
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '600px',
                maxHeight: '90%',
                minWidth: '800px',
                maxWidth: '90%'
              }}}
        >
            <DialogTitle>
                 {(editMode)?'Edit':'Add'} User
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {(isLoading)?<LinearProgress />:<></>}
                <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                    <AlertTitle>{feedbackTitle}</AlertTitle>
                    {feedbackMessage}
                </Alert>
                <Button style={{visibility: ((severity=='success'&&props.onMakeAssignments)) ? 'visible' : 'hidden' }} onClick={(e)=>props.onMakeAssignments()}>Manage assignments</Button>
                <div style={{padding:'10px'}}>
                
                </div>
                <div style={{padding:'10px'}}>
                <Stack>
                    <FormControl fullWidth>
                        <FormLabel id="user-name-label" >User Name</FormLabel>
                        <TextField id="user-name" 
                                    aria-labelledby="user-name-label" 
                                    variant="outlined" 
                                    required
                                    value={userName}
                                    onChange={(e)=>setUserName(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-email-label" >Email</FormLabel>
                        <TextField id="customer-email" 
                                    aria-labelledby="customer-email-label" 
                                    variant="outlined" 
                                    required
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    disabled={editMode}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-title-label" >Job Title</FormLabel>
                        <TextField id="customer-title" 
                                    aria-labelledby="customer-title-label" 
                                    variant="outlined" 
                                    required
                                    value={title}
                                    onChange={(e)=>setTitle(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="customer-phone-label" >Phone</FormLabel>
                        <TextField id="customer-phone" 
                                    aria-labelledby="customer-phone-label" 
                                    variant="outlined" 
                                    required
                                    value={phone}
                                    onChange={(e)=>setPhone(e.target.value)}
                                    />
                    </FormControl>
                    
                    <FormControl fullWidth>
                        {(locations)?
                            <>
                            <FormLabel id="user-location-label" >Location</FormLabel>
                            <Autocomplete
                                disablePortal
                                id="ddlLocations"
                                options={locations}
                                getOptionLabel={(option) => option.location_name}
                                renderInput={(params) => <TextField {...params}  aria-labelledby="user-location-label"  />}
                                onChange={(e,v)=>{setUserLocation(v);}}
                                value={userLocation}
                                key={userLocation}
                            />
                            </>
                        :<></>
                        }
                    </FormControl>
                    <FormControl fullWidth>
                        {(roleData)?
                            <>
                            <FormLabel id="user-role-label" >Role</FormLabel>
                            <Autocomplete
                                disablePortal
                                id="ddlLocations"
                                options={roleData}
                                renderInput={(params) => <TextField {...params}  aria-labelledby="user-role-label"  />}
                                onChange={(e,v)=>{setUserRole(v);}}
                                value={userRole}
                                key={userRole}
                            />
                            </>
                        :<></>
                        }
                    </FormControl>
                </Stack>
                
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={()=>(editMode)?UpdateUserObj():AddUserObj()} loading={isLoading}>{(editMode)?'Update':'Add'}</LoadingButton>
                <Button onClick={()=>closeDialogue()}>Cancel</Button>                
            </DialogActions>
        </Dialog>
        
        </>
        

    )
}