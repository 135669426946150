//styling
import '../../App.css';
import "../../css/admin_portal.css";
import "../../css/normalize.css";
import IconSearch from "../../images/icon_search.png";

//react components
import React, { useState, useEffect  } from "react";


//third-party components
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, AlertTitle } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';


//customcomponents
import IconSelector from '../IconSelector';
import PromptUser from '../PromptUser';


//libraries
import { GetAllUsers, EnableUsers, DisableUsers } from '../../libs/User';

export default function UserManager(props){

    const [isLoading, setIsLoading]=useState(true);
    const [isTableLoading, setIsTableLoading]=useState();
    const [columns, setColumns]=useState([]);
    const [users,setUsers]=useState(props.users || {rows:[],totalItems:0,resolvedItems:0});
    const [currentPage, setCurrentPage]=useState((props.users)?props.users.page || 0:0);
    const [pageSize, setPageSize]=useState((props.users)?props.users.pageSize || 100:100);
    const [sortBy, setSortBy]=useState();
    const [searchPhrase, setSearchPhrase]=useState();
    const [selectedrowids,setSelectedRowIds]=useState([]);
    const [selectedRows,setSelectedRows]=useState([]);
    const [isDeleting, setIsDeleting]=useState(false);
    const [deleteParams, setDeleteParams]=useState({});
    const [disableParams, setDisableParams]=useState({});
    const [enableParams, setEnableParams]=useState({});
    const [showDeletePrompt,setShowDeletePrompt]=useState(false);
    const [showDisablePrompt, setShowDisablePrompt]=useState(false);
    const [showEnablePrompt, setShowEnablePrompt]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [showAddUserDialogue,setShowAddUserDialogue]=useState();
    
    
    useEffect(()=>{
        if(feedbackMessage){
            //hide delete prompt after wait.
                let timeoutperiod = 3000;
                switch(severity){
                case 'error':
                    timeoutperiod = 6000;
                break;
                }
                setTimeout(()=>{//console.log('running timer');
                    resetFeedback();},3000);
        }

    },[feedbackMessage]);

    useEffect(() => {

        
            setColumns([
                {     field: 'customer_number'
                    , headerName: 'Number'
                    , width:100
                }
                ,{
                    field: 'customer_name'
                    , headerName:"Customer"
                    ,width:200
                }
                ,{
                    field: 'city'
                    , headerName:"Location"
                    ,width:400
                    ,renderCell: (params) => {
                        if(!params.row.address_line1&&!params.row.address_line2&&!params.row.city&&!params.row.state){
                            return('');
                        }else{
                            return (`${params.row.address_line1},${(params.row.address_line2)?params.row.address_line2+',':''} ${params.row.city} , ${params.row.state}`);
                        }
                    }
                }
                ,{
                    field: 'role'
                    , headerName:"Role"
                    ,width:200
                }
                ,{
                    field: 'user_name'
                    , headerName:"Username"
                    , width:200
                }
                ,{
                    field: 'email'
                    , headerName:"Email"
                    , width:200
                }
                ,{
                    field:'created_date'
                    , headerName:"Created"
                    ,width:200
                },{
                    field:'is_active'
                    , headerName:"Enabled"
                    ,width:200
                }
                , {
                    field: "actions",
                    headerName: "",
                    sortable: false,
                    description: 'View Report',
                    width: 50,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                        return (
                            <Tooltip title="View as User">
                                <IconButton href={`/links?userId=${encodeURIComponent(params.row.user_id)}`} target="_blank"><VisibilityIcon /></IconButton>
                            </Tooltip>
                        );
                    }
                },
            ]);
            
        if(props.isSelected){
            LoadData({pageSize:pageSize, page: currentPage},users,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        //console.log('setting data in callback')
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }

      }, [props.isSelected,users, pageSize]);
    
      
    const CustomPaginator=()=>{
        return (
                (isTableLoading)?
                <></>
                :<GridPagination disabled={!isTableLoading}/>
        );
    }

    const handlePagination = (params, evt, details)=>{
                
            LoadData(params, users,props.onData);

     }

     const LoadData = (params,origdata,callback)=>{


            setIsTableLoading(true);
            if(!origdata || origdata.totalItems==0 || (origdata.resolvedItems<(params.pageSize*(params.page+1)) && origdata.resolvedItems!=origdata.totalItems) || params.search){
                setPageSize(params.pageSize);
                
                GetAllUsers((params.search)?[]:users.rows, params.pageSize, params.page, params.search, null, sortBy,null).then(async (users)=>{
                    if(users){
                        setUsers(users);
                        if(callback){
                            if(origdata && origdata.resolvedItems!=users.resolvedItems){
                                users.page=params.page
                                users.pageSize=params.pageSize
                                //console.log('sending report data to callback');

                                callback(users).then(()=>{
                                    //console.log('back from callback');
                                    setPageSize(params.pageSize);
                                    setCurrentPage(params.page);
                                    setIsTableLoading(false);
                                });
                            }
                            
                        }else{
                            //console.log('no callback configured')
                            setPageSize(params.pageSize);
                            setCurrentPage(params.page);
                            setIsTableLoading(false);
                        }
                    }
                
                }).catch((err)=>{
                    console.error(`error fetching reports for page ${params.page}`,err);
                    setIsTableLoading(false);
                });
            }else{
                //console.log('pulling data form cache');
                origdata.page=params.page;
                origdata.pageSize=params.pageSize;
                callback(origdata);
                setIsTableLoading(false);
                setPageSize(params.pageSize);
                setCurrentPage(params.page);
            }

            
            
     }

    const resetData= async()=>{
        setIsLoading(true);
        setCurrentPage(0);
        setUsers({rows:[],totalItems:0,resolvedItems:0});setCurrentPage(0);
    }

    const searchInput = (e)=>{
        
        if(e.keyCode==13){
            //console.log('load search data');
            
            LoadData({pageSize:pageSize, page: 0, search:e.target.value},undefined,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackMessage();
        setFeedbackTitle();
    }

    return(
        (isLoading)?
        <div id="usermanager">
            <LinearProgress />
        </div>
        :
        <div id="usermanager">
            
                <>
                        <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                            <AlertTitle>{feedbackTitle}</AlertTitle>
                            {feedbackMessage}
                        </Alert>
                        {(isDeleting)?<LinearProgress />:<></>}
                        
                        <PromptUser showDialogue={showDeletePrompt}
                            Title="Delete User"
                            Prompt="Are you sure you would like to delete the selected users?" 
                            Params={deleteParams}
                            onCallback={async (e)=>{
                                
                                setShowDeletePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        let delpromises=[];
                                        /*for(let delItem of e.params){
                                            delpromises.push(DeleteContent(delItem.id,delItem.contentType));
                                        }
                                        let delres = await Promise.all(delpromises);
                                        */
                                        let data = {...users};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.user_id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setUsers(data);
                                        setSeverity('success');
                                        setIcon('verified');
                                        setFeedbackTitle('Content Deleted');
                                        setFeedbackMessage('Content Deleted Successfully.')
                                        //console.log('content deleted');
                                    }catch(err){
                                        console.error(err.response.data.error);
                                        setSeverity('error');
                                        setIcon('error');
                                        setFeedbackTitle('Error Deleting Content');
                                        setFeedbackMessage(err.response.data.error)
                                        //console.log('error deleting content');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        <PromptUser showDialogue={showDisablePrompt}
                            Title="Disable User"
                            Prompt="Are you sure you would like to disable the selected users?" 
                            Params={disableParams}
                            onCallback={async (e)=>{
                                setShowDisablePrompt(false);
                                //console.log(e);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        let delpromises=[];
                                        let userObjs = [];
                                        for(let obj of e.params){
                                            userObjs.push({id : obj.id});
                                        }
                                        let disableres = await DisableUsers(userObjs);
                                        
                                        let data = {...users};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setUsers(data);
                                        setFeedbackTitle('User Disabled');
                                        setFeedbackMessage('Users Disabled Successfully.')
                                        //console.log('user disabled');
                                    }catch(err){
                                        console.error(err);
                                        //console.log('error disabling user');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        <PromptUser showDialogue={showEnablePrompt}
                            Title="Enable User"
                            Prompt="Are you sure you would like to enable the selected user?" 
                            Params={enableParams}
                            onCallback={async (e)=>{
                                setShowEnablePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        
                                        let userObjs = [];
                                        for(let obj of e.params){
                                            userObjs.push({id : obj.id});
                                        }
                                        let enableres = await EnableUsers(userObjs);

                                        let data = {...users};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setUsers(data);
                                        setFeedbackTitle('User Enabled');
                                        setFeedbackMessage('User Enabled Successfully.')
                                        //console.log('user enabled');
                                    }catch(err){
                                        //console.error(err);
                                        //console.log('error enabling user');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        
                        <Stack direction="row" >
                            <OutlinedInput id="txtsearch" 
                                onKeyDown={searchInput} 
                                variant='outlined'
                                value={searchPhrase}
                                onChange={(e)=>setSearchPhrase(e.target.value)}
                                startAdornment={<InputAdornment position="end"><IconButton aria-label="search" edge="start" onClick={(e)=>searchInput({keyCode:13})}><SearchIcon /></IconButton></InputAdornment>}
                                sx={{verticalAlign:'top'}}
                            />
                            <Tooltip title="Delete Selected Users">
                                <IconButton onClick={(e)=>{
                                    let rowdata = users.rows.filter((d)=>selectedrowids.indexOf(d.user_id)>=0);
                                    let delparams = [];
                                    rowdata.forEach((d)=>delparams.push({id:d.user_id}));
                                    setDeleteParams(delparams);
                                    setShowDeletePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><DeleteIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Enable Selected Users">
                                <IconButton onClick={(e)=>{
                                    let rowdata = users.rows.filter((d)=>selectedrowids.indexOf(d.user_id)>=0);
                                    let enableparams = [];
                                    rowdata.forEach((d)=>enableparams.push({id:d.user_id}));
                                    //console.log('enable params',enableparams);
                                    setEnableParams(enableparams);
                                    setShowEnablePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><VisibilityIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Disable Selected Users">
                                <IconButton onClick={(e)=>{
                                    let rowdata = users.rows.filter((d)=>selectedrowids.indexOf(d.user_id)>=0);
                                    let disableparams = [];
                                    rowdata.forEach((d)=>disableparams.push({id:d.user_id}));
                                    setDisableParams(disableparams);
                                    setShowDisablePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><VisibilityOffIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Add User">
                                <IconButton onClick={(e)=>{setShowAddUserDialogue(true)}} disabled={(selectedrowids.length>0)}><AddIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Refresh Data">
                                <IconButton onClick={(e)=>{resetData();}}><RefreshIcon /></IconButton>
                            </Tooltip>
                            
                        </Stack>
                        <DataGrid
                            rows={users.rows}
                            columns={columns}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                pageSize: pageSize,
                                page:currentPage
                                },
                            },
                            }}
                            pageSizeOptions={[5,10,25,50,100]}
                            //onRowSelectionModelChange={(val)=>alert(val)}
                            getRowId={(row)=>{return row.user_id}}
                            fullWidth
                            id="report_table" 
                            className="hover row-border" 
                            onPaginationModelChange={handlePagination}
                            slots={{ 
                                    pagination : CustomPaginator,
                                    
                            }}
                            loading={isTableLoading}
                            checkboxSelection
                            onRowSelectionModelChange={(ids) => {
                                setSelectedRowIds(ids);
                                let rows = [];
                                ids.map((id)=>{ rows = rows.concat(users.rows.filter((r)=>r.id==id))});
                                setSelectedRows(rows);
                            }}
                            
                        >
                        </DataGrid>
                      
                    
                </>
            
        </div>

    )
}