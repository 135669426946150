//styling

//react components
import React, {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route, Router, Redirect, useSearchParams } from 'react-router-dom';

//third-party components


//customcomponents
import MyLinks from './MyLinks';
import MyMessages from './MyMessages';
import MyPOC from './MyPOC';
import MyReports from './MyReports';
import { ReportViewer } from '../ReportViewer';
import { LinearProgress } from '@mui/material';


import { GetMyReports } from '../../libs/Reports';
import { GetMyMessages } from '../../libs/Messages';
import { GetMyLinks } from '../../libs/Links';
import { GetMyPOC } from '../../libs/POC';
import ChangePassword from '../Public/ChangePassword';

const DisplayContent = (props)=>{

    const [reportData, setReportData]=useState();
    const [pocData,setPOCData]=useState();
    const [messageData, setMessageData]=useState();
    const [linksData, setLinksData]=useState();
    const [userInfo, setUserInfo]=useState();
    const [path,setPath]=useState();
    const [searchParams,setSearchParams ] = useSearchParams();
    const [loadOrder,setLoadOrder] = useState(['/links','/reports','/messages','/poc'])
    const [isLoading,setIsLoading] = useState(false);
    const [isLoaded,setIsLoaded] = useState(false);
    const [isTriggered,setIsTriggered] = useState(false);
    useEffect(() => {
          
        if(props.userInfo){
            setUserInfo(props.userInfo);
        }
        
        setPath(props.Path);
      }, [props.Path]);

    useEffect(()=>{

        setIsLoading(true);

    },[linksData,reportData,pocData,messageData])

    useEffect(()=>{
        if(isLoading && !isTriggered){
            setIsTriggered(true);
            let pageloads = [...loadOrder];
            pageloads.splice(pageloads.indexOf(path),1);
            setLoadOrder(pageloads);
            
            
         }
    },[isLoading]);

    useEffect(()=>{
        if(loadOrder.length==3){
            //console.log(`triggering load data with order ${JSON.stringify(loadOrder)}`);
            loaddata();
        }
    },[loadOrder])

    const loaddata = async()=>{

        for(let page of loadOrder){
            //console.log(`switching for load order with page ${page}`);
            switch(page){
                case '/links':
                    if(!linksData){
                        let links = await GetMyLinks(searchParams.get('userId'), [], 100, 0);
                        setLinksData(links);
                    }
                break;
                case '/reports':
                    if(!reportData){
                        let reports = await GetMyReports(searchParams.get('userId'), [], 100, 0)
                        setReportData(reports);
                    }
                break;
                case '/messages':
                    if(!messageData){
                        let messages = await GetMyMessages(searchParams.get('userId'), [], 100, 0);
                        setMessageData(messages)
                    }
                break;
                case '/poc':
                    if(!pocData){
                        let poc = await GetMyPOC(searchParams.get('userId'));
                        setPOCData(poc[0]);
                    }
                break;
                
            }
           
        }
        
    }

      return (
        <>
        <MyLinks userInfo={props.userInfo}
            onData={(data)=>{
                    return new Promise((resolve,reject)=>{
                        if(!linksData){setLinksData(data)}
                        resolve();    
                    })
                }
            }
            linksData={linksData}
            selected={(path=='/links')}
            />
        <MyReports 
                onData={(data)=>{
                    return new Promise((resolve,reject)=>{
                        setReportData(data)
                        resolve();
                    })  
                }}
                reportData={reportData} 
                selected={(path=='/reports')}
            />
        <MyMessages 
                        onData={(data)=>{
                            
                                return new Promise((resolve,reject)=>{
                                    if(!messageData){setMessageData(data)}
                                    resolve();
                                })    
                            }
                        }
                        messageData={messageData}
                        selected={(path=='/messages')}
                    />
        <MyPOC 
                        pocId={(props && props.userinfo)?props.userinfo.customer_poc_id:undefined }
                        onData={(data)=>{if(!pocData){setPOCData(data)}}}
                        pocData={pocData} 
                        selected={(path=='/poc')}
                    />
        </>
            
    );

}


function AppRouter(props){
    
        const [reportData, setReportData]=useState();
        const [path, setPath]=useState(props.path);
        
        
        useEffect(() => {
          
            setPath(props.path);
            
    
          }, [props.path]);

          
        return (
            
            (props.path || props.fullWindow)?
            <BrowserRouter>
                <Routes>
                    {(props.fullWindow==true)?
                        <Route path="/reports/viewer" element={<ReportViewer />}  />
                    :
                    <>
                    <Route path="/" element={<DisplayContent Path={path} selected={(path=='/')} />} />
                    <Route path="/links" element={<DisplayContent Path={path} selected={(path=='/links')}/> } />
                    <Route path="/messages" element={<DisplayContent Path={path} selected={(path=='/messages')}/>} />
                    <Route path="/poc" element={<DisplayContent Path={path} selected={(path=='/poc')}/>} />
                    <Route path="/reports" element={<DisplayContent Path={path} selected={(path=='/reports')}/>} />
                    </>
                    }
                </Routes>
            </BrowserRouter>
            :<LinearProgress />
            
        )
}

export default AppRouter;