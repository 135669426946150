//styling
import "../../App.css";
import HeaderLogo from "../../images/abbott-header-logo.png"
import HeaderImage from "../../images/img_header.png";
import "../../css/customer_portal.css";
import "../../css/normalize.css";


//react components
import React, { Component, useState, useEffect  } from "react";


//third-party components
import {createBrowserHistory} from "history";
import LinearProgress from '@mui/material/LinearProgress';
import { Auth,API } from "aws-amplify";

//customcomponents
import SignOut from '../Public/SignOut';
import AppRouter from './AppRouter';
import Footer from './Footer';
import MyLinks from './MyLinks';
import MyMessages from './MyMessages';
import MyPOC from './MyPOC';
import MyReports from './MyReports';
import { ReportViewer } from '../ReportViewer';


//libraries
import { GetUserInfo } from '../../libs/User';


export default function AuthenticatedContent(props){

    
    const [fullname,setFullName]=useState('');
    const [viewasname,setViewAsName]=useState('');
    const browserHistory = createBrowserHistory();
    const [path,setPath]=useState(undefined);
    const [initial,setInitial]=useState(true);
    const [isLoading,setIsLoading]=useState(true);
    const [isAdmin,setIsAdmin]=useState(false);
    const [pocData,setPOCData]=useState();
    const [messageData, setMessageData]=useState();
    const [reportData, setReportData]=useState();
    const [linksData, setLinksData]=useState();
    

    const paths=['/links','/reports','/messages','/poc','/reports/viewer']
    const uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);

    useEffect(()=>{
        if(props.onNavigation){
            props.onNavigation();
        }
    },[path]);

    useEffect(()=>{
        let userIdParam = window.location.toString().toLowerCase().match(/userid=(.[^\&]+)/);
        if(props.userInfo){
            
            setFullName(props.userInfo.user_name);
            if(props.userInfo.groups && props.userInfo.groups.indexOf('SysAdmin')>=0)
            {
                setIsAdmin(true);
            }
            
        }
        
        if(props.viewAs){
            //console.log('view as property set');
            //console.log(props.viewAs);
            
            setViewAsName(props.viewAs.user_name);
        }

        if(!path){
            //console.log('no path identified');
            setInitial(true);
            let uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);
            //console.log(uri_stem);
            
                if(uri_stem && paths.indexOf(uri_stem[1])>=0 && uri_stem[1]!='/reports/viewer'){
                    //setPath(uri_stem[1]);
                    switchContent(uri_stem[1]);
                }else if(!uri_stem){
                    
                    switchContent(paths[0]);
                }
            

        }

        browserHistory.listen((e)=>{
            
            switch(e.action){
                case 'POP':
                    setInitial(true);
                    let crrpath=(!e.location.pathname || e.location.pathname=='/')?setPath(paths[0]):e.location.pathname;
                    //setPath((e.location.pathname=='/')?setPath('/links'):e.location.pathname);
                    switchContent(crrpath,true);
                break;
            }

        })

        setIsLoading(false);
        if(props.onNavigation){
            props.onNavigation();
        }
          
    },[props.userInfo, props.viewAs]);

    const switchContent = (path,skipPushPath)=>{
        path = (!path)?paths[0]:path;
        let userIdParam = window.location.toString().toLowerCase().match(/userid=(.[^\&]+)/);
        let navpath = path;//(path=='/')?paths[0]:path;
        if(userIdParam && userIdParam[1].toLowerCase().match(/^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/)){
            navpath = navpath + '?userId='+userIdParam[1];
        }
        setInitial(false);
        if(!skipPushPath){
            browserHistory.push(navpath);
        }
        setPath(path);

        if(props.onNavigation){
            props.onNavigation();
        }
    }

    
    if(uri_stem && uri_stem.length>=2 && uri_stem[1]=='/reports/viewer'){
        return (<AppRouter fullWindow={true} />)
    }else{
        return (
                (isLoading)?
                <LinearProgress />
                :
                <>
                <div className="segment container_grey">
                    <div className="segment_inside">
                        <div className="apoc_logo"><img src={HeaderLogo} width="120px" /></div>
                        <div className="apoc_title">
                            {(isAdmin)?
                                <><span className="admin_link"><a href="/admin/customers">ADMINISTRATION CONTROL PANEL</a></span>
                                <span className="padding_right_20">|</span></>
                            :<></>
                            }
                            <span>POINT OF CARE</span></div>
                    </div>
                    </div>
                    <div className="outcontainer apoc_customer_portal">
                    
                    
                    <div className="container_confirm confirmation">
                        <div className="confirm">
                        <div className="message"></div>
                        <div className="line_top"></div>
                        <div className="buttons">
                            <span className="yes">Yes</span>
                            <span className="cancel">No</span>
                        </div>
                        </div>
                    </div>
                
                    
                    <div className="container_confirm alert">
                        <div className="confirm">
                        <div className="message"></div>
                        <div className="line_top"></div>
                        <div className="buttons">
                            <span className="ok">Ok</span>
                        </div>
                        </div>
                    </div>

                    <div className="segment background_white">
                        <div className="segment_padding">
                        <div className="row row_padding">
                            <div className="segment_inside">
                            <div style={{float: 'left'}}>
                                <p className="alignleft" style={{display: (fullname &&!viewasname)?'inline-block':'none'}}>Welcome, <span id="welcome">{fullname}!</span></p>
                                <p className="alignleft" style={{display: (viewasname)?'inline-block':'none'}}>Viewing as - <span id="welcome">{viewasname}</span></p>
                            </div>          
                            <SignOut 
                                onSignOut={props.onSignOut}
                                triggerSignOut={props.triggerSignOut}
                                onContinueSession={()=>{
                                    if(props.onContinueSession){
                                        props.onContinueSession();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="segment background_white">
                        <div>
                            <img className="image_full_width" src={HeaderImage} />
                        </div>

                        <div className="segment_padding">
                        <div className={'row row_padding padding_top_30'}>
                            <h2 className="padding_top_10 padding_bottom_20">Welcome to the Abbott Point of Care Managed Services Portal.</h2>
                            <p className="padding_top_5">This is a secure portal provided by your health system and Abbott to access i-STAT System management and configuration software. Use this page to log into your available software systems, analyze Reports and view Messages. Requests for additional access should be made through your Health System administrator.</p>
                        </div>


                        <div className="row padding_top_20 padding_bottom_5 tabs_content">
                            <div><a id="tab_my_links" className={(path=='/links')?'tab tab_selected':'tab'} onClick={(e)=>switchContent('/links')} ><h3>My Links</h3></a></div>
                            <div><a id="tab_my_reports" className={(path=='/reports')?'tab tab_selected':'tab'} onClick={(e)=>switchContent('/reports')}><h3>My Reports</h3></a></div>
                            <div><a id="tab_my_messages" className={(path=='/messages')?'tab tab_selected':'tab'} onClick={(e)=>switchContent('/messages')}><h3>My Messages</h3></a></div>
                            <div id="tab_my_poc_container"><a id="tab_my_poc" className={(path=='/poc')?'tab tab_selected':'tab'} onClick={(e)=>switchContent('/poc')}><h3>Abbott POC</h3></a></div>
                        </div>
                        </div>

                        <div className="content_details padding_bottom_20 segment_padding">
                            
                            <AppRouter fullWindow={false} path={path}  />
                            
                        </div>
                    </div>
                </div>

                <Footer />

                </>
                
        );
    }
    
}