//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";
import { useFormContext, FormProvider } from 'react-hook-form';



//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Alert, AlertTitle } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';



//customcomponents
import AssignOption  from './AssignOption';
import IconSelector from '../../IconSelector';
import UserForm from './UserForm';
import PromptUser from '../../PromptUser';

//libraries
import { GetAllCustomers,GetCustomerLocations, GetAllRoles} from '../../../libs/Customers';
import { DeleteLocation} from '../../../libs/Locations';
import { Button, LinearProgress } from '@mui/material';
import { AddLink, UpdateLink } from '../../../libs/Links';
import { AddMessage, UpdateMessage } from '../../../libs/Messages';
import { AddReport, UpdateReport } from '../../../libs/Reports';
import { AddContent, UpdateContent } from '../../../libs/Content';
import {  AddPOC, UpdatePOC, GetAllPOC } from '../../../libs/POC';
import { DeleteUser,ResetPassword, ResendInvitation} from '../../../libs/User';

export default function AddUpdateUsers(props){

    
    const [showDialogue,setShowDialogue]=useState(false);
    const [isLoading, setIsLoading]=useState(false);
    const [initial,setInitial]=useState();
    const [assignOptionDialogueVisible, setAssignOptionDialogeVisible]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [resetForm,setResetForm]=useState();
    const [editMode,setEditMode]=useState();
    const [selectedRecord,setSelectedRecord]=useState();
    const [showForm,setShowForm]=useState(false);
    const [selectedrowids,setSelectedRowIds]=useState([]);
    const [selectedRows,setSelectedRows]=useState([]);
    const [deleteParams, setDeleteParams]=useState({});
    const [disableParams, setDisableParams]=useState({});
    const [enableParams, setEnableParams]=useState({});
    const [showDeletePrompt,setShowDeletePrompt]=useState(false);
    const [ resendInvitationParams, setResendInvitationParams]=useState({});
    const [ showResendInvitationPrompt, setShowResendInvitationPrompt ]=useState(false);
    
    const [ resetPasswordParams, setResetPasswordParams]=useState({});
    const [ showResetPasswordPrompt, setShowResetPasswordPrompt ]=useState(false);
    
    const [pocData,setPOCData]=useState();
    const [users, setUsers]=useState();
    const [selectedUser, setSelectedUser]=useState();
    const [locations, setLocations]=useState();
    const [locationName,setLocationName]=useState();
    const [locationAddress1,setLocationAddress1]=useState();
    const [locationAddress2,setLocationAddress2]=useState();
    const [locationCity,setLocationCity]=useState();
    const [locationState,setLocationState]=useState();
    const [locationZip,setLocationZip]=useState();
    const [locationCountry,setLocationCountry]=useState();
    const [locationPOC, setLocationPOC]=useState();
    
   
    //const { getValues } = useFormContext();

    useEffect(()=>{
        resetFeedback();
    },[showDialogue])

    
    useEffect(()=>{
        
        if(selectedRecord){
            //console.log(selectedRecord);
            setUsers(selectedRecord.users);
            setLocations(selectedRecord.locations);
        }
        
    },[selectedRecord])

   

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        resetFeedback();
        if(!initial){
            setInitial(true);
        }

        setEditMode(props.edit);
        if(props.edit && props.selectedRecord){
            setSelectedRecord(props.selectedRecord);
        }else{
            clearForm();
        }

      }, [props.showDialogue]);

    
    
    const UserObjectUpdated=async(userObject)=>{
        
        let crrUsers = [...users];
        crrUsers = crrUsers.slice(0,crrUsers.indexOf(selectedUser)).concat([userObject]).concat(crrUsers.slice(crrUsers.indexOf(selectedUser)+1));
        setUsers(crrUsers);
        
    }
    const UserObjectAdded=async(userObject)=>{
        
        let crrUsers = [...users];
        crrUsers.unshift(userObject);
        setUsers(crrUsers);

    }

    
    const clearForm=()=>{
        setUsers();
        setLocationName();
        setLocationAddress1();
        setLocationAddress2();
        setLocationCity();
        setLocationState();
        setLocationZip();
        setLocationCountry();
        setLocationPOC();
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackTitle();
        setFeedbackMessage();
    }
  

    const closeDialogue=(e)=>{


        clearForm();
        resetFeedback();
        setSelectedRecord();
        setShowDialogue(false)
        if(props.onCloseCallback){
            props.onCloseCallback(users);
        }
    }
    
    const removeUser= (userId)=>{
        let crrusers = users;
        setUsers(users.filter((a)=>a.user_id!=userId));
    }

    const removeUsers = async (userids)=>{
        try{
            let crrusers = users;
            
            setIsLoading(true);
            setSeverity('info');
            setIcon('info');
            setFeedbackTitle('Deleting Users');
            
            
            for(let userid of userids){
                
                let user_name = crrusers.filter((a)=>a.user_id==userid)[0].user_name
                setFeedbackMessage(`Deleting ${user_name} from Customer.`);
                let del = await DeleteUser(userid);
                crrusers = crrusers.filter((a)=>a.user_id!=userid);
            }

            setUsers(crrusers);

            setSeverity('success');
            setIcon('verified');
            setFeedbackTitle('Users Deleted Successfully');
            setFeedbackMessage(`${userids.length} users deleted successfully.`);
            
        
        }catch(err){
            setSeverity('error');
            setIcon('error');
            setFeedbackTitle('Error Deleting Content');
            setFeedbackMessage((err.response && err.response.data)?err.response.data.error:JSON.stringify(err));
            //console.log('error deleting content',err);
        }

        setIsLoading(false);
    }

    const resendInvitation = async (userids)=>{
        try{
            let crrusers = users;
            
            setIsLoading(true);
            setSeverity('info');
            setIcon('info');
            setFeedbackTitle('Resending Invitations');
            
            for(let userid of userids){
                
                let userrec = crrusers.filter((a)=>a.user_id==userid)[0]
                //console.log(userrec);
                setFeedbackMessage(`Resending Invitation to ${userrec.email}.`);
                let reset = await ResendInvitation(userrec);
            }


            setSeverity('success');
            setIcon('verified');
            setFeedbackTitle('Invitation resent successfully.');
            setFeedbackMessage(`${userids.length} invitations resent successfully.`);
            
        
        }catch(err){
            setSeverity('error');
            setIcon('error');
            setFeedbackTitle('Error Resending Invitation');
            setFeedbackMessage((err.response && err.response.data)?err.response.data.error:JSON.stringify(err));
            //console.log('error resending invitation',err);
        }

        setIsLoading(false);
    }

    const resetPassword = async (userids)=>{
        try{
            let crrusers = users;
            
            setIsLoading(true);
            setSeverity('info');
            setIcon('info');
            setFeedbackTitle('Resetting Passwords');
            
            for(let userid of userids){
                
                let userrec = crrusers.filter((a)=>a.user_id==userid)[0]
                //console.log(userrec);
                setFeedbackMessage(`Resetting password for ${userrec.email}.`);
                let reset = await ResetPassword(userrec);
            }


            setSeverity('success');
            setIcon('verified');
            setFeedbackTitle('Password reset initiated successfully.');
            setFeedbackMessage(`Password reset initiated successfully for ${userids.length} user${(userids.length!=1)?'s':''}.`);
            
        
        }catch(err){
            setSeverity('error');
            setIcon('error');
            setFeedbackTitle('Error Resetting Password');
            setFeedbackMessage((err.response && err.response.data)?err.response.data.error:JSON.stringify(err));
            //console.log('error deleting password',err);
        }

        setIsLoading(false);
    }

    return(
       <>
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '600px',
                maxHeight: '90%',
                minWidth: '800px',
                maxWidth: '90%'
              }}}
        >
            <DialogTitle>
                Manage Users
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {(isLoading)?<LinearProgress />:<></>}
                <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                    <AlertTitle>{feedbackTitle}</AlertTitle>
                    {feedbackMessage}
                </Alert>
                <Button style={{visibility: ((severity=='success'&&props.onMakeAssignments)) ? 'visible' : 'hidden' }} onClick={(e)=>props.onMakeAssignments()}>Manage assignments</Button>
                <div style={{padding:'10px'}}>
                <Stack direction="row">
                    <Tooltip title="Add User">
                        <IconButton onClick={(e)=>{setShowForm(true)}} ><AddIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Resend Invitation">
                        <IconButton onClick={(e)=>{
                                    
                                    setResendInvitationParams(selectedrowids);
                                    setShowResendInvitationPrompt(true);

                                }} disabled={(selectedrowids.length==0)}><ContactMailIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Initiate Password Reset">
                        <IconButton onClick={(e)=>{
                                    
                                    setResetPasswordParams(selectedrowids);
                                    setShowResetPasswordPrompt(true);

                                }} disabled={(selectedrowids.length==0)}><LockResetIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Users">
                        <IconButton onClick={(e)=>{
                                    
                                    setDeleteParams(selectedrowids);
                                    setShowDeletePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><DeleteIcon /></IconButton>
                    </Tooltip>
                </Stack>
                </div>
                <div style={{padding:'10px'}}>
                <Stack spacing={5}>
                        <DataGrid
                                rows={users||[]}
                                columns={[ 
                                            {     
                                                field: 'user_name'
                                                , headerName: 'Name'
                                                , width: 175
                                            },
                                            {     
                                                field: 'email'
                                                , headerName: 'Email'
                                                , width: 300
                                            },
                                            {     
                                                field: 'job_title'
                                                , headerName: 'Title'
                                                , width: 175
                                            },
                                            {     
                                                field: 'user_telephone'
                                                , headerName: 'Phone'
                                                , width: 175
                                            },
                                            {     
                                                field: 'userlocation'
                                                , headerName: 'Location'
                                                , width: 175
                                                , renderCell: (params)=>{
                                                    return(
                                                        (params.row && params.row.locations.length==1 && locations &&locations.filter((l)=>(l.location_id==params.row.locations[0].location_id)).length==1)?
                                                        `${locations.filter((l)=>(l.location_id==params.row.locations[0].location_id))[0].location_name}`
                                                        :''
                                                    )
                                                }
                                            },
                                            {     
                                                field: 'userrole'
                                                , headerName: 'Role'
                                                , width: 175
                                                , renderCell: (params)=>{
                                                    return(
                                                        (params.row && params.row.locations && params.row.locations.length==1)?
                                                        `${params.row.locations[0].roles[0]}`
                                                        :''
                                                        
                                                    )
                                                }
                                            },
                                            {
                                                field: "action_edit",
                                                headerName: "",
                                                sortable: false,
                                                description: 'Edit',
                                                width: 50,
                                                disableClickEventBubbling: true,
                                                disableColumnMenu: true,
                                                renderCell: (params) => {
                                                    return (
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={(e)=>{
                                                                setSelectedUser(params.row);
                                                                setShowForm(true);
                                                            }}><EditIcon /></IconButton>
                                                        </Tooltip>
                                                    );
                                                 }
                                            }
                                        ]}
                                initialState={{}}
                                checkboxSelection
                                //onRowSelectionModelChange={(val)=>alert(val)}
                                getRowId={(row)=>{return row.user_id}}
                                fullWidth
                                id="users_table"
                                className="hover row-border" 
                                //onRowClick={(m)=>{setMessage(m.row);}}
                                disableRowSelectionOnClick={true}
                                autoHeight={true}
                                onRowSelectionModelChange={(ids) => {
                                    setSelectedRowIds(ids);
                                    /*let rows = [];
                                    ids.map((id)=>{ rows = rows.concat(locations.filter((r)=>r.id==id))});
                                    setSelectedRows(rows);*/
                                }}
                                
                        />
                </Stack>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>closeDialogue()}>Close</Button>                
            </DialogActions>
        </Dialog>
        <UserForm showDialogue={(showForm)} onCloseCallback={(e)=>{setShowForm(false);setSelectedUser();}} selectedLocations={(selectedRecord)?selectedRecord.locations:undefined} customerId={(selectedRecord)?selectedRecord.customer_id:undefined} selectedRecord={selectedUser} edit={(selectedUser)} onLocationAdded={UserObjectAdded} onLocationUpdated={UserObjectUpdated}/>

        <PromptUser showDialogue={showDeletePrompt}
                            Title="Delete Users"
                            Prompt="Are you sure you would like to delete the selected users?" 
                            Params={deleteParams}
                            onCallback={async (e)=>{
                                
                                setShowDeletePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    
                                        removeUsers(e.params);
                                    
                                }
                                
                            }}
                        />
        
        <PromptUser showDialogue={showResendInvitationPrompt}
                            Title="Resend Invitations"
                            Prompt='Invitations can only be resent for users in "FORCE_CHANGE_PASSWORD" status.  Are you sure you would like to resend invitations for the selected users?'
                            Params={resendInvitationParams}
                            onCallback={async (e)=>{
                                
                                setShowResendInvitationPrompt(false);
                                if(e && e.response && e.response.toLowerCase()=='yes'){
                                    
                                        resendInvitation(e.params);
                                    
                                }
                                
                            }}
                        />
        
        <PromptUser showDialogue={showResetPasswordPrompt}
                            Title="Initiate Password Reset"
                            Prompt='An email will be sent to selected users with instructions to reset their password.  Are you sure you would like to initiate a password reset for the selected users?'
                            Params={resetPasswordParams}
                            onCallback={async (e)=>{
                                
                                setShowResetPasswordPrompt(false);
                                if(e && e.response && e.response.toLowerCase()=='yes'){
                                    
                                        resetPassword(e.params);
                                    
                                }
                                
                            }}
                        />


        </>
        

    )
}