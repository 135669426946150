//styling
import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";

//react components
import React, { Component, useState, useEffect  } from "react";

//third-party components and libraries

//customcomponents

//libraries




export default function Footer(props){


    return (
        <footer className="apocfooter">
            <div className="apoclegal"><p>&copy; 2023 Abbott. All rights reserved. Please read the <a href="https://www.abbott.com/online-terms-and-conditions.html">LEGAL NOTICE</a> for further details.<br />Abbott Point of Care Inc. | 400 College Road East | Princeton, NJ USA 08540</p></div>
            <p>Version 3.0</p>
        </footer>
    );
}