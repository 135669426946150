//styling
import logo from './logo.svg';
import { muitheme } from "./config/muitheme";

//react components
import React, {useEffect,useState} from 'react';



//third-party components
import { Amplify } from 'aws-amplify';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {createBrowserHistory} from "history";
import { ThemeProvider } from '@mui/material/styles';

//customcomponents
import CustomAuthenticator from './components/Public/CustomAuthenticator';
import AuthenticatedContent from './components/UserPortal/AuthenticatedContent';
import AdminContent from './components/Admin/AdminContent';

//custom libraries
import awsconfig from './config/awsconfig';
import { GetUserInfo } from './libs/User';
import { LinearProgress } from '@mui/material';

function App() {
  const [user, setUser]=useState();
  const [userinfo, setUserInfo]=useState();
  const [viewasinfo,setViewAsInfo]=useState();
  const [isAdmin, setIsAdmin]=useState();
  const [view, setView]=useState('user');
  const [activityTimer, setActivityTimer]=useState([]);
  const [triggerSignout, setSignOutTrigger]=useState();

  const USER_SESSION_TIMEOUT = process.env.REACT_APP_USER_SESSION_TIMEOUT_MINUTES*60*1000 || 40000;
  const ADMIN_SESSION_TIMEOUT = process.env.REACT_APP_ADMIN_SESSION_TIMEOUT_MINUTES*60*1000 || 20000;

  const browserHistory = createBrowserHistory();
  

  const getUriStem=()=>{
    let rxUriStem = /https?:\/\/.[^\/]+(.[^\?]+)(.+)?/;
    return window.location.toString().match(rxUriStem)[1];
  }

  
  useEffect(() => {
    document.title = 'APOC Managed Services';
    Amplify.configure(awsconfig);

    let uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);
    
    if(uri_stem && uri_stem[1].match(/^\/admin\/.+/)){
      setView('admin');
    }

    let userIdParam = window.location.toString().toLowerCase().match(/userid=(.[^\&]+)/);
    if(userinfo && userinfo.groups.indexOf('SysAdmin')>=0 && userIdParam && userIdParam[1].toLowerCase().match(/^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/)){
        GetUserInfo(userIdParam[1]).then((data)=>{
           //setUserInfo(data);
           setViewAsInfo(data);
        }).catch((err)=>{
            console.error('error fetching user detail',err)
        });
    }

  }, [isAdmin]);

  

  const onAuthenticated = async(user)=>{
    //setUser(user);
    //console.log('is authenticated trigger');
    //console.log(user);
    setSignOutTrigger(false);

    let userinfo = await GetUserInfo(user.attributes.sub)
    userinfo.groups = user.signInUserSession.accessToken.payload['cognito:groups'];
    
    if(userinfo.groups && userinfo.groups.indexOf('SysAdmin')>=0){
      setIsAdmin(true);
      setUserInfo(userinfo);
      setView('admin');
      await user.storage.setItem('userinfo',JSON.stringify(userinfo))

      window.location.replace('/admin/customers');
    }else{
      setUserInfo(userinfo);
      await user.storage.setItem('userinfo',JSON.stringify(userinfo))

      //window.location.replace('/links');
    }
    onActivity();
    
  }

  const onSignOut = ()=>{
    
    setUserInfo(null);
    window.location.replace('/');
    
  }

  const onActivity = ()=>{

    let timers = [...activityTimer]
    if(activityTimer.length>0){
      for(let t of timers){
        clearTimeout(t);
      }
      timers=[];
    }
    let intervalid = setTimeout(checkActivity.bind(this, Date.now()),(isAdmin)?ADMIN_SESSION_TIMEOUT:USER_SESSION_TIMEOUT);
    
    timers.push(intervalid);
    setActivityTimer(timers);
    
  }

  const checkActivity = (navevent)=>{
        let timeout = ((isAdmin)?ADMIN_SESSION_TIMEOUT:USER_SESSION_TIMEOUT)/1000
        let lastNavigationAge = (Date.now()-navevent)/1000;
        if(lastNavigationAge>=timeout){
          setSignOutTrigger(true);
        }
  } 

  const continueSession = ()=>{
    setSignOutTrigger(false);
    onActivity();
  }

  document.onclick = onActivity;
  document.onload = onActivity;
  

  return (
        <ThemeProvider theme={muitheme}>
          <CustomAuthenticator 
              onAuthenticated={onAuthenticated} 
              setUserInfo={(user)=>{
                setUser(user);
                if(user){
                  if(user.storage){
                    let userinfo= user.storage.getItem('userinfo');
                    if(userinfo){
                      userinfo=JSON.parse(userinfo);
                      setUserInfo(userinfo);
                      if(userinfo && userinfo.groups && userinfo.groups.indexOf('SysAdmin')>=0){
                        setIsAdmin(true);
                      }
                    }
                    
                  }
                }
              }}
            >
            
            {(userinfo)?
            (isAdmin&&view=='admin')?
            <AdminContent userInfo={userinfo} onSignOut={onSignOut} onNavigation={onActivity} triggerSignOut={triggerSignout} onContinueSession={continueSession}/>
            :<AuthenticatedContent userInfo={userinfo}  viewAs={viewasinfo} onSignOut={onSignOut} onNavigation={onActivity} triggerSignOut={triggerSignout} onContinueSession={continueSession}/>
            :<LinearProgress />
            }
          </CustomAuthenticator>
      </ThemeProvider>
  );
}

export default App;
