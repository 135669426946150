//styling
import '../../App.css';
import HeaderLogo from "../../images/abbott-header-logo.png"
import HeaderImage from "../../images/img_header.png";
import "../../css/customer_portal.css";
import "../../css/normalize.css";

//react components
import React, { Component, useState, useEffect  } from "react";

//third-party components and libraries
import { Auth } from "aws-amplify";
import LoadingButton from '@mui/lab/LoadingButton';

//customcomponents



export default class RecoverPassword extends Component {
    
  state = {
      handleSignInCallback:undefined
    ,  userfeedback:''
    , isLoading:false
    , successFeedback:''
    , errorFeedback:''
  };

  
  async componentDidMount() {
    
   
  }

  // Handle changes to form inputs on sign-in
  handleFormInput = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  

  recoverPassword = async(event) => {
    event.preventDefault();
    this.setState({isLoading:true})
    const username = this.state.username;
    Auth.forgotPassword(username.toLowerCase())
    .then((data)=>{
        this.setState({successFeedback:'We\'ll send you instruction to the email provided if it\'s associated with an account.'})
        this.setState({isLoading:false})
    })
    .catch((err)=>{
        //error condition, no message
        this.setState({successFeedback:'We\'ll send you instruction to the email provided if it\'s associated with an account.'})
        this.setState({isLoading:false})
    });

  }
  render() {
    return (
      <>
       
      <h1>Forgot your password? </h1>
          <p className="apoc_text">Please enter the user name associated with your account and we’ll send you an email with instructions on how to reset your password.</p>
          <>
          <form id="forgetPasswordForm">
            <div id="forgetPasswordError" className="formresult padding_bottom_10">{this.state.errorFeedback}</div>
            <div id="forgetPasswordSuccess" className="formresult_success padding_bottom_10">{this.state.successFeedback}</div>
            <div className="padding_bottom_40">
              <label for="usernameInputForgetPassword">User Name:</label>
              <input type="text" 
                     id="usernameInputForgetPassword" 
                     name="username" 
                     placeholder="Username" 
                     required 
                     onChange={this.handleFormInput}
                     value={this.state.username}
                />
            </div>
            <div className="center padding_bottom_40">
              <LoadingButton onClick={this.recoverPassword} loading={this.state.isLoading} variant="apoc">submit</LoadingButton>
            </div>
          </form>
</>
        
    </>
      
    );
  }
}