//styling
import '../../App.css';
import "../../css/admin_portal.css";
import "../../css/normalize.css";
import IconSearch from "../../images/icon_search.png";

//react components
import React, { useState, useEffect  } from "react";


//third-party components
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, AlertTitle } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link'
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import MessageIcon from '@mui/icons-material/Message';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import HideSourceIcon from '@mui/icons-material/HideSource';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



//customcomponents
import AssignContent from './Dialogues/AssignContent';
import AddUpdateContent from './Dialogues/AddUpdateContent';
import IconSelector from '../IconSelector';
import PromptUser from '../PromptUser';

//libraries
import { GetContent, DeleteContent, DisableContent, EnableContent } from '../../libs/Content';
import { Button } from '@mui/material';
import { GetReportById } from '../../libs/Reports';
import axios from 'axios';

export default function POCManager(props){

    const [isLoading, setIsLoading]=useState(true);
    const [isTableLoading, setIsTableLoading]=useState();
    const [columns, setColumns]=useState([]);
    const [pocdata,setPOCData]=useState(props.content || {rows:[],totalItems:0,resolvedItems:0});
    const [currentPage, setCurrentPage]=useState((props.content)?props.content.page || 0:0);
    const [pageSize, setPageSize]=useState((props.content)?props.content.pageSize || 100:100);
    const [sortBy, setSortBy]=useState();
    const [selectedrowids,setSelectedRowIds]=useState([]);
    const [selectedRows,setSelectedRows]=useState([]);
    const [showAddAssignmentDialogue, setShowAddAssignmentDialogue]=useState(false);
    const [showAddContentDialogue, setShowAddContentDialogue]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [showDeletePrompt,setShowDeletePrompt]=useState(false);
    const [showDisablePrompt, setShowDisablePrompt]=useState(false);
    const [showEnablePrompt, setShowEnablePrompt]=useState(false);
    const [deleteParams, setDeleteParams]=useState({});
    const [disableParams, setDisableParams]=useState({});
    const [enableParams, setEnableParams]=useState({});
    const [isDeleting, setIsDeleting]=useState(false);
    const [message,setMessage]=useState();
    const [editContent, setEditContent]=useState(false);
    const [selectedRecord, setSelectedRecord]=useState();
    const [assignmentMode, setAssignmentMode]=useState();
    const [searchPhrase, setSearchPhrase]=useState();
    

    useEffect(()=>{
        if(feedbackMessage){
            //hide delete prompt after wait.
                let timeoutperiod = 3000;
                switch(severity){
                case 'error':
                    timeoutperiod = 6000;
                break;
                }
                setTimeout(()=>{//console.log('running timer');
                    resetFeedback();},3000);
        }

    },[feedbackMessage]);

    useEffect(() => {
        setColumns([
            {
                field: 'created_date'
                , headerName:"Created Date"
                ,width:250
            }
            ,{
                field: 'contentType'
                , headerName:"Type"
                ,width:250
            }
            ,{
                field: 'title'
                , headerName:"Title"
                ,width:250
            }
            ,{
                  field: 'description'
                , headerName:"Description"
                ,width:400
            },
            ,{
                field: 'is_active'
              , headerName:"Enabled"
              ,width:100
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                description: 'Actions',
                width: 250,
                disableClickEventBubbling: true,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <Stack direction="row" spacing={1}>
                            {(params.row.contentType=='report' && params.row.report_file_name.match(/\.pdf$/))?
                                <Tooltip title="View Report">
                                    <IconButton href={`/reports/viewer?reportid=${encodeURIComponent(params.row.id)}`} target="_blank"><SummarizeIcon /></IconButton>
                                </Tooltip>
                            :(params.row.contentType=='report')?
                                <Tooltip title="Download Report">
                                    <IconButton onClick={async (e)=>{ 
                                        let selreport = await GetReportById(params.row.id);
                                        let rsp = await axios.get(selreport.report_presigned_url, {responseType: 'blob'});
                                        const targeturl = URL.createObjectURL(rsp.data);
                                        let ext = selreport.report_file_name.match(/\.(.[^\.]+)$/)
                                            //download file
                                            const a = document.createElement('a');
                                            a.download = `${selreport.report_title}.${ext[1]}`;
                                            a.href = targeturl;
                                            a.addEventListener('click', (e) => {
                                                setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
                                            });
                                            a.click();
                                            URL.revokeObjectURL(targeturl);
                                    }}><SummarizeIcon /></IconButton>
                                </Tooltip>
                            :(params.row.contentType=='link')?
                                <Tooltip title={`Visit ${params.row.link}`}>
                                    <IconButton href={params.row.link} target="_blank"><LinkIcon /></IconButton>
                                </Tooltip>
                            :(params.row.contentType=='message')?
                                <Tooltip title="View Message">
                                    <IconButton onClick={()=>{setMessage(params.row)}} description="View Message"><MessageIcon /></IconButton>
                                </Tooltip>
                            :<></>
                            }
                            <Tooltip title="Manage Assignments">
                                <IconButton onClick={(e)=>{ 
                                    setAssignmentMode('single');
                                    setSelectedRecord(params.row);
                                    setShowAddAssignmentDialogue(true);
                                }}><AssignmentIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton onClick={(e)=>{
                                    setEditContent(true);
                                    setSelectedRecord(params.row);
                                    setShowAddContentDialogue(true);
                                }}><EditIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton onClick={(e)=>{ setDeleteParams([{id:params.row.id,contentType:params.row.contentType}]);setShowDeletePrompt(true);}}><DeleteIcon /></IconButton>
                            </Tooltip>
                        </Stack>
                    );
                 }
            }
         ]);
        
        if(props.isSelected){
            LoadData({pageSize:pageSize, page: currentPage},pocdata,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }

        
      }, [props.isSelected,pocdata]);
    
      
    const CustomPaginator=()=>{
        return (
                (isTableLoading)?
                <></>
                :<GridPagination disabled={!isTableLoading}/>
        );
    }

    const handlePagination = (params, evt, details)=>{
                
            LoadData(params, pocdata,props.onData);

     }

     const resetData= async()=>{
        setIsLoading(true);
        setPOCData({rows:[],totalItems:0,resolvedItems:0});
    }
    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackMessage();
        setFeedbackTitle();
    }

    const newContentAdded = (contentObj)=>{
        setEditContent(false);
        //console.log('new content added');
        //console.log(contentObj);
        let data= {...pocdata};
        data.rows = [contentObj].concat(data.rows);
        data.totalItems++;
        data.resolvedItems++;
        setSelectedRecord(contentObj);
        setPOCData(data);

    }

    const contentAssignmentsUpdated = (contentObjs,mode)=>{
       
        let data = {...pocdata};
        if(mode=='single'){
            //replace all customers with contentObj customers
            let dindex = data.rows.indexOf(selectedRecord);
            data.rows[dindex].customers = contentObjs[0].customers;
            setPOCData(data);

        }else{
            //replace customer assignments included in ContentObjs
            
            for(let id of selectedrowids){
                //console.log('id',id);
                //data.rows[data.rows.indexOf(data.rows.filter((r)=>r.id==id)[0])].customers=contentObjs[0].customers

                let dindex = data.rows.indexOf(data.rows.filter((r)=>r.id==id)[0]);
                //console.log('updating row index ', dindex);
                for(let contentObj of contentObjs){
                    for(let customer of contentObj.customers){
                        if(data.rows[dindex] && data.rows[dindex].customers){
                            data.rows[dindex].customers = data.rows[dindex].customers.filter((c)=>c.customer_id!=customer.customer_id);
                        }
                    }
                    if(!data.rows[dindex].customers){
                        data.rows[dindex].customers=[];
                    }
                    data.rows[dindex].customers=data.rows[dindex].customers.concat(contentObj.customers)
                }

            }
            setPOCData(data);
            //setPOCData(data);
            /*for(let contentObj of contentObjs)
            {
                console.log('contentObj',contentObj);
                let dindex = data.rows.indexOf(data.rows.filter((r)=>r.id==contentObj.customer_id)[0]);
                console.log('updating index ', dindex);
                for(let customer of contentObj.customers){
                    if(data.rows[dindex] && data.rows[dindex].customers){
                        data.rows[dindex].customers = data.rows[dindex].customers.filter((c)=>c.customer_id!=customer.customer_id);
                    }
                }

                //console.log(contentObj.customers)
                //data.rows[dindex].customers = data.rows[dindex].customers.concat(contentObj.customers);
            }*/
            setPOCData(data);
            
        }
    }

    const contentUpdated = (contentObj)=>{
        
        //console.log('content updated');
        //console.log(contentObj);

        let data= {...pocdata};
        data.rows = data.rows.slice(0,data.rows.indexOf(selectedRecord)).concat([contentObj]).concat(data.rows.slice(data.rows.indexOf(selectedRecord)+1));
        //data.rows = [contentObj].concat(data.rows.filter((r)=>r.id!=contentObj.id));
        setPOCData(data);
    }

     const LoadData = (params,origdata,callback)=>{


            setIsTableLoading(true);
            if(!origdata || origdata.totalItems==0 || (origdata.resolvedItems<(params.pageSize*(params.page+1)) && origdata.resolvedItems!=origdata.totalItems) || params.search){
                setPageSize(params.pageSize);
                //console.log('calling getmyreports with search params:', params.search)
                GetContent(pocdata.rows, params.pageSize, params.page, params.search, sortBy||'title').then(async (data)=>{
                    if(data){
                        setPOCData(data);
                        if(callback){
                            if(origdata && origdata.resolvedItems!=data.resolvedItems){
                                data.page=params.page
                                data.pageSize=params.pageSize
                                //console.log('sending report data to callback');

                                callback(data).then(()=>{
                                    //console.log('back from callback');
                                    setPageSize(params.pageSize);
                                    setCurrentPage(params.page);
                                    setIsTableLoading(false);
                                });
                            }
                            
                        }else{
                            //console.log('no callback configured')
                            setPageSize(params.pageSize);
                            setCurrentPage(params.page);
                            setIsTableLoading(false);
                        }
                    }
                
                }).catch((err)=>{
                    console.error(`error fetching reports for page ${params.page}`,err);
                    setIsTableLoading(false);
                });
            }else{
                //console.log('pulling data form cache');
                origdata.page=params.page;
                origdata.pageSize=params.pageSize;
                callback(origdata);
                setIsTableLoading(false);
                setPageSize(params.pageSize);
                setCurrentPage(params.page);
            }

            
            
     }

    const onMakeAssignments = ()=>{
        setSelectedRecord(selectedRecord);
        setAssignmentMode('single');
        setShowAddAssignmentDialogue(true);
        setShowAddContentDialogue(false)
    }
    const searchInput = (e)=>{
        
        if(e.keyCode==13){
            LoadData({pageSize:pageSize, page: 0, search:searchPhrase},undefined,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }
    }

    const addAssignments=(e)=>{
        //console.log(`add assignments for contentids ${selectedrowids}`);
    }
    return(
        (isLoading)?
        <div id="contentmanager">
            <LinearProgress />
        </div>
        :
        <div id="contentmanager">
            
                <>
                        <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                            <AlertTitle>{feedbackTitle}</AlertTitle>
                            {feedbackMessage}
                        </Alert>
                        {(isDeleting)?<LinearProgress />:<></>}
                        
                        <PromptUser showDialogue={showDeletePrompt}
                            Title="Delete Content"
                            Prompt="Are you sure you would like to delete the selected content?" 
                            Params={deleteParams}
                            warnLimit={1}
                            warnMessage='Type "{1}" below to continue deleting {0} selected content items.'
                            warnConfirmation="delete"
                            onCallback={async (e)=>{
                                
                                setShowDeletePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        let delpromises=[];
                                        for(let delItem of e.params){
                                            delpromises.push(DeleteContent(delItem.id,delItem.contentType));
                                        }
                                        let delres = await Promise.all(delpromises);

                                        let data = {...pocdata};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setPOCData(data);
                                        setSeverity('success');
                                        setIcon('verified');
                                        setFeedbackTitle('Content Deleted');
                                        setFeedbackMessage('Content Deleted Successfully.')
                                        //console.log('content deleted');
                                    }catch(err){
                                        console.error(err.response.data.error);
                                        setSeverity('error');
                                        setIcon('error');
                                        setFeedbackTitle('Error Deleting Content');
                                        setFeedbackMessage(err.response.data.error)
                                        //console.log('error deleting content');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        <PromptUser showDialogue={showDisablePrompt}
                            Title="Disable Content"
                            Prompt="Are you sure you would like to disable the selected content?" 
                            Params={disableParams}
                            onCallback={async (e)=>{
                                setShowDisablePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        let delpromises=[];
                                        let contentObjs = [];
                                        for(let obj of e.params){
                                            contentObjs.push({id : obj.id,contentType: obj.contentType});
                                        }
                                        let disableres = await DisableContent(contentObjs);

                                        let data = {...pocdata};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setPOCData(data);
                                        setFeedbackTitle('Content Disabled');
                                        setFeedbackMessage('Content Disabled Successfully.')
                                        //console.log('content disabled');
                                    }catch(err){
                                        console.error(err);
                                        //console.log('error disabling content');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        <PromptUser showDialogue={showEnablePrompt}
                            Title="Enable Content"
                            Prompt="Are you sure you would like to enable the selected content?" 
                            Params={enableParams}
                            onCallback={async (e)=>{
                                setShowEnablePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        
                                        let contentObjs = [];
                                        for(let obj of e.params){
                                            contentObjs.push({id : obj.id,contentType: obj.contentType});
                                        }
                                        let enableres = await EnableContent(contentObjs);

                                        let data = {...pocdata};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setPOCData(data);
                                        setFeedbackTitle('Content Enabled');
                                        setFeedbackMessage('Content Enabled Successfully.')
                                        //console.log('content enabled');
                                    }catch(err){
                                        //console.error(err);
                                        //console.log('error enabling content');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        
                        <Stack direction="row" >
                            <OutlinedInput id="txtsearch" 
                                onKeyDown={searchInput} 
                                variant='outlined'
                                value={searchPhrase}
                                onChange={(e)=>setSearchPhrase(e.target.value)}
                                startAdornment={<InputAdornment position="end"><IconButton aria-label="search" edge="start" onClick={(e)=>searchInput({keyCode:13})}><SearchIcon /></IconButton></InputAdornment>}
                                sx={{verticalAlign:'top'}}
                            />
                            <Tooltip title="Assign Selected Content">
                                <IconButton onClick={(e)=>{setShowAddAssignmentDialogue(true);}} disabled={(selectedrowids.length==0)}><AssignmentIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Selected Content">
                                <IconButton onClick={(e)=>{
                                    let rowdata = pocdata.rows.filter((d)=>selectedrowids.indexOf(d.id)>=0);
                                    let delparams = [];
                                    rowdata.forEach((d)=>delparams.push({id:d.id,contentType:d.contentType}));
                                    setDeleteParams(delparams);
                                    setShowDeletePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><DeleteIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Enable Selected Content">
                                <IconButton onClick={(e)=>{
                                    let rowdata = pocdata.rows.filter((d)=>selectedrowids.indexOf(d.id)>=0);
                                    let enableparams = [];
                                    rowdata.forEach((d)=>enableparams.push({id:d.id,contentType:d.contentType}));
                                    setEnableParams(enableparams);
                                    setShowEnablePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><VisibilityIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Disable Selected Content">
                                <IconButton onClick={(e)=>{
                                    let rowdata = pocdata.rows.filter((d)=>selectedrowids.indexOf(d.id)>=0);
                                    let disableparams = [];
                                    rowdata.forEach((d)=>disableparams.push({id:d.id,contentType:d.contentType}));
                                    setDisableParams(disableparams);
                                    setShowDisablePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><VisibilityOffIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Add Content">
                                <IconButton onClick={(e)=>{setShowAddContentDialogue(true)}} disabled={(selectedrowids.length>0)}><AddIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Refresh Data">
                                <IconButton onClick={(e)=>{resetData();}}><RefreshIcon /></IconButton>
                            </Tooltip>
                            
                        </Stack>
                        
                
                        <DataGrid
                            rows={pocdata.rows}
                            columns={columns}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                pageSize: pageSize,
                                page:currentPage
                                },
                            },
                            }}
                            disableRowSelectionOnClick
                            pageSizeOptions={[5,10,25,50,100]}
                            //onRowSelectionModelChange={(val)=>alert(val)}
                            fullWidth
                            id="content_table" 
                            className="hover row-border" 
                            onPaginationModelChange={handlePagination}
                            slots={{ 
                                    pagination : CustomPaginator,
                                    
                            }}
                            checkboxSelection
                            onRowSelectionModelChange={(ids) => {
                                setSelectedRowIds(ids);
                                let rows = [];
                                ids.map((id)=>{ rows = rows.concat(pocdata.rows.filter((r)=>r.id==id))});
                                setSelectedRows(rows);

                            }}
                            loading={isTableLoading}
                            
                        ></DataGrid>
                        <AssignContent showDialogue={(showAddAssignmentDialogue)} selectedRowIds={selectedrowids} onCloseCallback={(e)=>{setSelectedRecord();setAssignmentMode();setShowAddAssignmentDialogue(false)}} onAssignmentsUpdated={contentAssignmentsUpdated} mode={assignmentMode}  selectedRecord={selectedRecord} selectedRows={selectedRows} />
                        <AddUpdateContent showDialogue={(showAddContentDialogue)} onCloseCallback={(e)=>{setShowAddContentDialogue(false); setSelectedRecord();setTimeout(()=>setEditContent(false),250);}} onContentAdded={newContentAdded} onContentUpdated={contentUpdated} onMakeAssignments={onMakeAssignments} edit={editContent} selectedRecord={selectedRecord} />
                        {message && 
                                <>
                                    
                                    <Dialog
                                        open={(message)}
                                        PaperProps={{ style: {
                                            minHeight: '400px',
                                            maxHeight: '90%',
                                            minWidth: '600px',
                                            maxWidth: '90%'
                                          }}}
                                    >
                                        <DialogTitle>
                                        {message.description}
                                        </DialogTitle>
                                        <IconButton
                                        aria-label="close"
                                        onClick={()=>setMessage(undefined)}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                        <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {message.message}
                                        </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                        </DialogActions>
                                    </Dialog>

                                </>
                        }
                    
                </>
            
        </div>

    )
}