//styling
import "../../css/customer_portal.css";
import "../../css/normalize.css";


//react components
import React, { Component, useState, useEffect } from "react";


//third-party components
import { Auth, API } from "aws-amplify";
import { TextField, Link } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';


//customcomponents
import SignInGroup from "./SignInGroup";





let feedbackmessage;
export default class SignIn extends Component {
    
  state = {
      userfeedback:''
    , isSigningIn:false
    , username:''
    , password:''
    , password1:''
    , password2:''
    , challenge:false
    , user:{}
  };

  
  
  authcanceled=false;


  async componentDidMount() {
    this.setState({onAuthenticated:this.props.onAuthenticated})
  }

 

  handleSignIn = async(event) => {
    
    event.preventDefault();
    this.setState({isSigningIn:true});
    
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const { username, password } = this.state;
    this.authcanceled=false;
   
    try {
        //(message,severity,title, icon)
        let user = (!this.state.challenge)?(await Auth.signIn(username.toLowerCase(), password)):(this.state.password1==this.state.password2)?(await Auth.completeNewPassword(this.state.user,this.state.password1)):undefined;
          if(!user){
            this.setState({userfeedback:'Passwords do not match.  Please  try again.'});
          }else if(user && user.challengeName){
            switch(user.challengeName){
              case "NEW_PASSWORD_REQUIRED":
                this.setState({userfeedback:'Authentication successful.  New Password Required.',challenge:true,user:user});    
              break;
            }
          }else if(this.state.onAuthenticated){

            if(!user.attributes||!user.attributes.sub){
              let attrinfo = await Auth.currentUserInfo();
              user.attributes = attrinfo.attributes
            }

            this.state.onAuthenticated(user);
          }
      }catch (err) {
        if(err.name==='NotAuthorizedException'){
            this.setState({userfeedback:'Authentication failed.  Please try again.'});
            
        }else{
          //console.log('Unhandled error authenticating usererror authenticating user',err);
        }
        
        
      }finally{
        this.setState({password:''});
        this.setState({isSigningIn:false});
        if(this.state.handleSignInCallback){
            this.state.handleSignInCallback();
        }
      }
      
      return true;

  };

  handleCancel = async(event) => {
    //get cancel for requestid
    this.authcanceled=true;
    this.props.displayUserFeedback('Authentication canceled.','warning','Authentication Canceled',"warn");
  }

  handleFormInput = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  
  isValidEmail = (email)=>{
      if(email) {
        return email.toString().match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }else{
        return false;
      }
    
  }

  isValidPassword = (password)=>{
    if(password){
      return (password.length>=6);
    }else{
      return false;
    }
  }

  render() {
    return (
      <form id="signinForm">
          <div id="signinresult" className="formresult padding_bottom_10">{this.state.userfeedback}</div>
            {(this.state.challenge)?
              <>
              <div className="padding_bottom_20">
                <label>User Name:</label><input type="text" id="usernameInputSignin" name="username" value={this.state.username} required onChange={this.handleFormInput} />
              </div>
              <div className="padding_bottom_5">
                <label>New Password:</label><input type="password" id="passwordInputSignin" name="password1" placeholder="Password" pattern=".*" value={this.state.password1} required onChange={this.handleFormInput} autoComplete="off"/>
              </div>
              <div className="padding_bottom_5">
                <label>Confirm Password:</label><input type="password" id="passwordInputSignin" name="password2" placeholder="Password" pattern=".*" value={this.state.password2} required onChange={this.handleFormInput} autoComplete="off"/>
              </div>
              <div className="center padding_bottom_40">
                  <LoadingButton onClick={this.handleSignIn} loading={this.state.isSigningIn} variant="apoc">Set Password</LoadingButton>
              </div>
            </>
            :
            <>
              <div className="padding_bottom_20">
                <label>User Name:</label><input type="text" id="usernameInputSignin" name="username" value={this.state.username} required onChange={this.handleFormInput} />
              </div>
              <div className="padding_bottom_5">
                <label>Password:</label><input type="password" id="passwordInputSignin" name="password" placeholder="Password" pattern=".*" value={this.state.password} required onChange={this.handleFormInput} autoComplete="off"/>
              </div>
              <div className="forget_password padding_bottom_40"><a href="/forget_password.html">Forgot Password?</a></div>
              <div className="center padding_bottom_40">
              
                  <LoadingButton onClick={this.handleSignIn} loading={this.state.isSigningIn} variant="apoc">login</LoadingButton>
              </div>
            </>
            }
    </form>
      
    );
  }
}