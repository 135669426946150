//styling
import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";

//react components
import React, { useState, useEffect  } from "react";
import { useSearchParams } from "react-router-dom";

//third-party components
import LinearProgress from '@mui/material/LinearProgress';


//customcomponents

//libraries
import { GetMyPOC } from '../../libs/POC';


export default function MyPOC(props){

    const [isLoading, setIsLoading]=useState(true);
    const [poc,setPOC]=useState(props.pocData);
    const [selected, setSelected] = useState();
    const [searchParams,setSearchParams ] = useSearchParams();


    useEffect(()=>{
        if(props.pocData){
            setPOC(props.pocData);
        }
    },[props.pocData])



    useEffect(() => {
        if(!poc && props.selected){
            GetMyPOC(searchParams.get('userId')||props.userId).then((pocdata)=>{
                setPOC(pocdata[0]);
                setIsLoading(false);
                
                if(props.onData){
                    props.onData(pocdata[0]);
                }
            })
            .catch((err)=>{
                console.error('error fetching poc information',err);
                setIsLoading(false);
                if(props.onData){
                    props.onData({});
                }
            });
        }else{
            setIsLoading(false);
        }
        setSelected(props.selected);
    
      }, [props.selected]);


    return(
        (selected)?
        (isLoading || !(poc))?
        <div id="my_poc" className="my_poc">
            <div className="row row_padding">
            <LinearProgress />
            </div>
        </div>
        :
        <div id="my_poc" className="my_poc">
                <div className="row row_padding">
                    <p>Your Abbott Point of Care Contact is here to support you!</p>
                    
                    
                    <div id="poc_detail">
                        
                        <div id="poc_detail_item_container">
                            <div className="poc_detail_item">
                            <label className="poc_detail_label"></label><label id="poc_fullname">{poc.poc_fullname}</label>
                            </div>
                            <div className="poc_detail_item">
                            <label className="poc_detail_label"></label><label id="poc_title">{poc.poc_title}</label>
                            </div>
                            <div className="poc_detail_item">
                            <label className="poc_detail_label"></label><label id="poc_email">{poc.poc_email}</label>
                            </div>
                            <div className="poc_detail_item">
                            <label className="poc_detail_label">Desk:</label><label id="poc_phone">{poc.poc_phone}</label>
                            </div>
                            <div className="poc_detail_item" id="poc_cell_container">
                            <label className="poc_detail_label">Cell:</label><label id="poc_cell">{poc.poc_cell}</label>
                            </div>
                        </div>
                        <div id="poc_image_container">
                        <img id="poc_image" src={poc.poc_image} />
                        </div>
                    </div>
                </div>
            </div>
        :<></>
        

    )
}



