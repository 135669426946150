//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";
import { Message } from '@aws-amplify/ui-react';


//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Button, LinearProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Box, Container } from '@mui/system';
import MessageIcon from '@mui/icons-material/Message';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LinkIcon from '@mui/icons-material/Link';
import { Alert, AlertTitle } from "@mui/material";



//customcomponents
import IconSelector from '../../IconSelector';

//libraries
import { GetAllCustomers,GetCustomerLocations, GetAllRoles} from '../../../libs/Customers';
import { AddContentAssignments } from '../../../libs/Content';



export default function AssignContent(props){

    
    const [showDialogue,setShowDialogue]=useState(false);
    const [selectedrowids,setSelectedRowIds]=useState();
    const [selectedContent,setSelectedContent]=useState([]);
    const [customerData, setCustomerData]=useState();
    const [initial,setInitial]=useState();
    const [selectedCustomer, setSelectedCustomer]=useState();
    const [locationData, setLocationData]=useState({});
    const [isLoadingLocation, setIsLoadingLocation]=useState(false);
    const [roleData, setRoleData]=useState();
    const [selectedLocations, setSelectedLocations]=useState();
    const [selectedRoles, setSelectedRoles]=useState();
    const [resetForm,setResetForm]=useState();
    const [assignments,setAssignments]=useState([]);
    const [assignmentsChanged,setAssignmentsChanged]=useState(false);
    const [mode, setMode]=useState();
    const [selectedRecord, setSelectedRecord]=useState();
    const [isLoading, setIsLoading]=useState();
    const [selectedRows, setSelectedRows]=useState([]);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        setSelectedRowIds(props.selectedRowIds);
        

        if(!roleData && !customerData && !initial){
            GetAllCustomers().then((data)=>{
                setCustomerData(data);
                //console.log(data);
            }).catch((err)=>{
                console.error('error retrieving customer data');
            });

            GetAllRoles().then((data)=>{
                data.unshift('All Roles');
                setRoleData(data);
                //console.log(data);
            }).catch((err)=>{
                console.error('error retrieving customer data');
            });
        }

        setMode(props.mode);
        if(props.mode=='single'){
            setSelectedRecord(props.selectedRecord);
            setSelectedContent([props.selectedRecord]);
        }else{
            setSelectedRecord();
            setSelectedContent(props.selectedRows);
        }

        if(!initial){
            setInitial(true);
        }

        setAssignments([]);

      }, [props.showDialogue,props.selectedRowIds]);

      useEffect(()=>{
        
        if(selectedRecord && selectedRecord.customers){
            //populate assignment table from selected record
            /*let assignment = {
                  customerid:selectedCustomer
                , customer_name:customerData.filter((cd)=>cd.customer_id==selectedCustomer)[0].customer_name
                , locations:locations
                , locationids: selectedLocations
                , roles: selectedRoles
            }*/
            setIsLoading(true);
            populateAssignments(selectedRecord);

            setSelectedContent([selectedRecord]);
            //setAssignments(assignments.concat([assignment]));
        }
        
    },[selectedRecord])
    
    const populateAssignments=async(record)=>{
        let locationpromises = []
        let assignments = [];
        let customerpromise = GetAllCustomers();
        for(let customer of record.customers){
            
            locationpromises.push(GetCustomerLocations(customer.customer_id));
            let assignment = {
                  customerid: customer.customer_id
                , customer_name:''
                , locations:[]
                , locationids:[]
                , roles:[]
            }
            for(let role of Object.keys(customer.roles)){
                assignment.roles.push(role)
                for(let locationid of customer.roles[role].assigned_locations){
                   assignment.locationids.push(locationid)
                }
            }
            assignments.push(assignment);
            
        }
        let customers = await Promise.all([customerpromise]);
        //console.log('assign content returned with customers');
        //console.log(customers);
        let locationresults = await Promise.all(locationpromises);
        let locations=[];
        locationresults.map((l)=>locations = locations.concat(l));
        for(let assignment of assignments){

            if(customers[0].rows.filter((c)=>c.customer_id==assignment.customerid).length>0){
                assignments[assignments.indexOf(assignment)].customer_name = customers[0].rows.filter((c)=>c.customer_id==assignment.customerid)[0].customer_name;
            }
            for(let lid of assignment.locationids){
                let selLocation = locations.filter((l)=>l.location_id==lid)[0];
                if(selLocation && assignments[assignments.indexOf(assignment)].locations.indexOf(selLocation.location_name)<0){
                    assignments[assignments.indexOf(assignment)].locations.push(selLocation.location_name);
                }
            }
        }
        setAssignments(assignments);
        setIsLoading(false);
    }

    const addAssignments=(e)=>{
        //console.log(`add assignments for contentids ${selectedrowids} to customer ${selectedCustomer} at locations ${selectedLocations} and roles ${selectedRoles}`);

        let locations = [];
        let locationids = [];
        //console.log(selectedLocations);
        if(selectedLocations.indexOf(-1)>=0){
            locationData[selectedCustomer].filter((l)=>l.location_id!=-1).map((d)=>{locations.push(d.location_name);locationids.push(d.location_id)});
        }else{
            for(let selLocation of selectedLocations){
                locations.push(locationData[selectedCustomer].filter((l)=>l.location_id==selLocation)[0].location_name)
            }
            locationids = selectedLocations;
        }
        
        let roles = [];
        if(selectedRoles.indexOf('All Roles')>=0){
            //add all roles
            roles=[...roleData];
            roles.shift();
        }else{
            roles=selectedRoles
        }

        let assignment = {
              customerid:selectedCustomer
            , customer_name:customerData.rows.filter((cd)=>cd.customer_id==selectedCustomer)[0].customer_name
            , locations:locations
            , locationids: locationids
            , roles: roles
        }

        setAssignments(assignments.filter((a)=>a.customerid!=assignment.customerid).concat([assignment]));
        setAssignmentsChanged(true);
        setSelectedCustomer();
        setSelectedLocations();
        setSelectedRoles();
        setResetForm(Math.random());
        

    }
    const removeAssignment=(customerid)=>{
        //console.log(`remove assignment from ${customerid}`);
        let crrassignments = assignments;
        setAssignments(crrassignments.filter((a)=>a.customerid!=customerid));
        setAssignmentsChanged(true);
    }

    const closeDialogue=(e)=>{

        setShowDialogue(false)
        setAssignmentsChanged(false);
        resetFeedback();
        if(props.onCloseCallback){
            props.onCloseCallback(e);
        }
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackTitle();
        setFeedbackMessage();
        
    }
    const selectedCustomerChanged = async (event, value)=>{
        
        if(value){
            setIsLoadingLocation(true);
            let selcustomer = value
            setSelectedCustomer(selcustomer.customer_id);
            
            let locations = locationData;
            if(!locations[selcustomer.customer_id]){
                locations[selcustomer.customer_id]=await  GetCustomerLocations(selcustomer.customer_id)
                locations[selcustomer.customer_id].unshift({location_id:-1,location_name:'All Locations'});
                setLocationData(locations);
            }
            setIsLoadingLocation(false);
        }else{
            setSelectedCustomer();
        }

    }
    const selectedLocationChanged = async (event, value)=>{
        if(value){
        let sellocation = value
        //console.log(sellocation);
        let sellocations=[];
        sellocation.map((l)=>sellocations.push(l.location_id));
        setSelectedLocations(sellocations);
        }else{
            setSelectedLocations();
        }

    }
    const selectedRoleChanged = async (event, value)=>{
        
        if(value){
        let selrole = value
        //console.log(selrole);
        setSelectedRoles(selrole);
        }else{
            setSelectedRoles();
        }

    }

    const saveAssignments=async()=>{
        setFeedbackTitle('Saving Assignments.');
        setSeverity('info');
        setIcon('info');
        setFeedbackMessage(`Assigning ${selectedContent.length} content objects to ${assignments.length} customers.`);
        
        let customers = [];
        for(let assignment of assignments){
            let crrassign = {
                customer_id:assignment.customerid
                , roles:{}
            }
            for(let role of assignment.roles){
                if(!crrassign.roles[role]){
                    crrassign.roles[role]={
                        assigned_locations:[]
                    }
                }
            }
            for(let lid of assignment.locationids){
                for(let role of assignment.roles){
                    if(crrassign.roles[role].assigned_locations.indexOf(lid)<0){
                        crrassign.roles[role].assigned_locations.push(lid)
                    }
                }
            }
            customers.push(crrassign)
        }
        let data = []
        for(let ct of selectedContent){
            data.push(
                {
                    id: ct.id
                    , customers : customers
                    , contentType : ct.contentType
                }
            )
        }

        //try{
            let res = await AddContentAssignments(data,(mode=='single'))
            
            //console.log('selectedContent data',data);

            setFeedbackTitle('Assignments Saved.');
            setSeverity('success');
            setIcon('success');
            setFeedbackMessage(`${selectedContent.length} content objects assigned to ${customers.length} customers.`);

            if(props.onAssignmentsUpdated){
                props.onAssignmentsUpdated(data,mode);
            }
        /*}catch(err){
            console.error(err);
            setFeedbackTitle('Error Saving Assignments.');
            setSeverity('error');
            setIcon('error');
            setFeedbackMessage(JSON.stringify(err));
        }*/
    }


    return(
       
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '768px',
                maxHeight: '90%',
                minWidth: '1024px',
                maxWidth: '90%'
              }}}
        >
            <DialogTitle>
                {(mode=='single')?'Manage Content Assignments':'Manage Content Assignments'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {(isLoading)?<LinearProgress />:<></>}
                <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                    <AlertTitle>{feedbackTitle}</AlertTitle>
                    {feedbackMessage}
                </Alert>
                <Container maxWidth={800} style={{maxHeight: 200, overflow: 'auto', margin:'20px'}}>
                <Stack spacing={1}>
                { selectedContent.map((c,index)=>
                    (index%4==0)?
                        <Stack direction="row" spacing={1}>
                            { selectedContent.map((c1,i1)=>(i1>=index && i1<index+4)?
                                <Tooltip title={`${c1.title}`}>
                                    <Chip label={`${c1.title}`} 
                                        size='large'
                                        sx={{width:225}}
                                        avatar={(c1.contentType=='link')?<LinkIcon />:(c1.contentType=='message')?<MessageIcon />:<SummarizeIcon />}
                                        variant='outlined'
                                    />
                                </Tooltip>:<></>) }
                        </Stack>
                    :<></>
                )}
                </Stack>
                </Container>
                
                <DialogContentText id="alert-dialog-description">
                    Assign the selected content to Customers, Locations, and Roles.
                </DialogContentText>
                <div style={{padding:'10px'}}>
                    <FormControl fullWidth>
                        {(customerData)?
                            <Autocomplete
                                disablePortal
                                id="ddlCustomer"
                                options={customerData.rows}
                                getOptionLabel={(option) => option.customer_name}
                                renderInput={(params) => <TextField {...params} label="Customer" />}
                                onChange={selectedCustomerChanged}
                                key={resetForm}
                            />
                        :<></>
                        }
                    </FormControl>
                </div>
                <div style={{padding:'10px'}}>
                    <FormControl fullWidth>
                        
                            {(isLoadingLocation)?
                            <LinearProgress />
                            :<></>}
                            <Autocomplete
                                disablePortal
                                id="ddlLocation"
                                multiple
                                disabled={!(locationData[selectedCustomer])}
                                options={(locationData[selectedCustomer])?locationData[selectedCustomer]:[]}
                                getOptionLabel={(option) => option.location_name}
                                renderInput={(params) => <TextField {...params} label="Location" />}
                                onChange={selectedLocationChanged}
                                key={selectedCustomer}
                            />

                        
                            
                        
                    </FormControl>
                </div>
                <div style={{padding:'10px'}}>
                        <FormControl fullWidth>
                        
                            <Autocomplete
                                disablePortal
                                id="ddlRoles"
                                multiple
                                disabled={!(locationData[selectedCustomer])}
                                options={roleData}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => <TextField {...params} label="Role" />}
                                onChange={selectedRoleChanged}
                                key={selectedCustomer}
                            />
                        
                        </FormControl>
                </div>
                <h4>Assignments</h4>
                <DataGrid
                        rows={assignments}
                        columns={[ 
                                    {
                                        field: "action_delete",
                                        headerName: "",
                                        sortable: false,
                                        description: 'Delete',
                                        width: 50,
                                        disableClickEventBubbling: true,
                                        disableColumnMenu: true,
                                        renderCell: (params) => {
                                            return (
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={()=>{removeAssignment(params.row.customerid);}}><DeleteIcon /></IconButton>
                                                </Tooltip>
                                            );
                                        }
                                    },
                                    {     
                                        field: 'customer_name'
                                    , headerName: 'Customer'
                                    , width: 175
                                    }
                                    ,{ 
                                            field: 'locations'
                                        , headerName: 'Locations'
                                        , width: 300
                                    }
                                    ,{ 
                                      field: 'roles'
                                    , headerName: 'Roles'
                                    , width: 350
                                    }
                                ]}
                        initialState={{
                        }}
                        //onRowSelectionModelChange={(val)=>alert(val)}
                        getRowId={(row)=>{return row.customerid}}
                        fullWidth
                        id="assignments_table"
                        className="hover row-border" 
                        //onRowClick={(m)=>{setMessage(m.row);}}
                        disableRowSelectionOnClick={true}
                        autoHeight={true}
                        
                    />
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>addAssignments()} disabled={!(selectedCustomer&&selectedLocations&&selectedRoles)}>Add Assignment</Button>
                <Button onClick={()=>closeDialogue()}>Cancel</Button>
                <Button onClick={()=>saveAssignments()} disabled={!(assignmentsChanged)}>Save Assignments</Button>
            </DialogActions>
        </Dialog>

    )
}