//styling
import HeaderLogo from "../../images/abbott-header-logo.png"
import HeaderImage from "../../images/img_header.png";
import "../../css/normalize.css";
import "../../css/customer_portal.css";

//react components
import React, { PureComponent } from "react";

//third-party components
import { Auth, Amplify } from "aws-amplify";

//customcomponents
import PublicRouter from './PublicRouter';
import Footer from '../UserPortal/Footer';


//libraries
import awsconfig from '../../config/awsconfig';

export default class CustomAuthenticator extends PureComponent {
  state = {
    username: "",
    email: "",
    password: "",
    phone_number: "",
    code: "",
    user: null, // will contain our user data object when signed in
    status: this.props.status || "SignUp",
    userfeedback:"",
    severity:"success", //alert
    title:"",
    icon: "InfoIcon",
    mode: "face",
    showFaceAuthenticatorAppDialogue: false,
    isAuthenticated: false,
    loading:true
    
  };

  
  
  async componentDidMount() {
    try{
        Amplify.configure(awsconfig);
        let authUserData = await  Auth.currentAuthenticatedUser();
        if(authUserData){
            
            let user = {username:authUserData.username,groups:authUserData.signInUserSession.accessToken.payload['cognito:groups']||[],...authUserData.attributes}
            
            
            if(this.props.setUserInfo){
                this.setState({user:user,isAuthenticated:true,loading:false})
                authUserData.groups=user.groups;
                user.isAuthenticated=true;
                this.props.setUserInfo(authUserData);
            }else{
                this.setState({loading:false})    
            }
            
        }else{
            this.setState({loading:false})    
        }
           
    }catch(err){
        //console.log('error mounting component');
        //console.log(err);
        this.setState({loading:false})    
    }
  }

  
  render() {
    //return <div>{this.CustomAuthenticator()}</div>;
    if(this.state.loading==true){
        return <></>
    }else{
    return (
            (this.state.isAuthenticated==false)?
            <>
                <div className="segment container_grey">
                    <div className="segment_inside">
                        <div className="apoc_logo"><img src={HeaderLogo} width="120px" /></div>
                        <div className="apoc_title">POINT OF CARE</div>
                    </div>
                    </div>
                    <div className="outcontainer apoc_login_portal">
                    <div className="segment background_white padding_bottom_20">
                        <div>
                            <img className="image_full_width" src={HeaderImage} />
                        </div>
                        <div className="form_container padding_top_20">
                        <PublicRouter onAuthenticated={(userinfo)=>{ 
                                if(this.props.onAuthenticated){
                                    this.props.onAuthenticated(userinfo)
                                }
                                this.setState({isAuthenticated:true})
                            }}
                            isAuthenticated={(this.state.isAuthenticated)} />
                        </div>
                    </div>
                </div>
                        
                <Footer />
            </>
            :this.props.children
            )
    
        }
    
  }
}