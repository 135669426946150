
import { Auth,API } from "aws-amplify";
import {remove,insert} from "./ArrayHelper"

const APINAME='messages'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetMyMessages= async(sub, rows, pageSize=5, currentPage=0, search, sortBy) =>{
    
    return new Promise(async (resolve,reject)=>{
        try{
            sub = sub || (await Auth.currentAuthenticatedUser()).attributes.sub;

            const qParams = new URLSearchParams();
            qParams.append("pagesize", pageSize);
            qParams.append("currentpage", currentPage);
            if(search){
                qParams.append("search",search);
            }
            if(sortBy){
                qParams.append("sort",sortBy);
            }
            
            if(sub){

                let info = await API.get(APINAME,'/message/user/'+sub+'?'+qParams.toString());
                if(search){
                    
                    info = info.filter((l)=>(l.subject_line.toLowerCase().indexOf(search.toLowerCase())>=0
                                                        || l.message.toLowerCase().indexOf(search.toLowerCase())>=0
                                                        || l.message_title.toLowerCase().indexOf(search.toLowerCase())>=0))
                    
                }
                
                let totalitems = info.length;
                let startIndex = currentPage*pageSize;
                let length = (startIndex+pageSize>info.length)?info.length-startIndex:pageSize;
                let dummyitems=0;
                if(totalitems!=(rows.length-rows.filter(f=>f.message_id.indexOf('dummy')==0).length)){
                    if(info.length>pageSize){
                        info = info.splice(startIndex,length);
                    }
                    if(rows.length!=totalitems){
                        for(let i=startIndex+pageSize;i<totalitems;i++){
                            info.push({message_id:`dummy${i}`});
                        }
                        rows=info;
                        
                    }else{
                        let count=0
                        //remove dummy rows
                        rows = remove(rows, startIndex,length);
                        //insert new rows
                        for(let l of info){
                            rows = insert(rows,startIndex+count, l)
                            count++;
                        } 
                        
                    }
                    
                    dummyitems =  rows.filter(f=>f.message_id.indexOf('dummy')==0).length;
                    
                }

                if(!sortBy || sortBy == 'created_date'){
                    //default sort
                    rows = rows.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
                }else{
                    //field sort
                    rows = rows.sort((a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1);
                }

                resolve({
                      rows : rows || []
                    , totalItems : totalitems||0
                    , resolvedItems : totalitems-dummyitems||0
                });
            }else{
                resolve({
                    rows : []
                  , totalItems : 0
                  , resolvedItems : 0
              });
            }
            
        }catch(err){
            reject(err);
        }
        
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetAllMessages=async()=>{
    
    return new Promise(async (resolve,reject)=>{
        try{
                let messages = await API.get(APINAME,'/message/?'+ Date.now());
                resolve(messages);
        }catch(err){
            console.error('Error getting messages.')
            reject(err);
        }
        
    })

}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddMessage= async(title,subject,body)=>{
    return new Promise(async(resolve,reject)=>{
        let messageData = {
              message_title: title
            , subject_line: subject
            , message: body
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: messageData
        }
        try{
            let messageinfo = await API.post(APINAME,'/message/', req);
            resolve(messageinfo);
        }catch(err){
            console.error('Error adding message.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UpdateMessage= async(id, title,subject,body)=>{
    return new Promise(async(resolve,reject)=>{
        let messageData = {
              message_title: title
            , subject_line: subject
            , message: body
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: messageData
        }
        try{
            let messageinfo = await API.put(APINAME,'/message/'+id, req);
            resolve(messageinfo);
        }catch(err){
            console.error('Error adding message.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableMessage=async(id)=>{
    return new Promise(async(resolve,reject)=>{
        let messageData = {
              is_active: 'N'
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: messageData
        }
        try{
            let messageinfo = await API.put(APINAME,'/message/'+id, req);
            resolve(messageinfo);
        }catch(err){
            console.error('Error disabling message.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableMessages=async(ids)=>{
    let promises = []
    for(let id of ids){
        promises.push(DisableMessage(id));
    }
    return await Promise.all(promises);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableMessage=async(id)=>{
    return new Promise(async(resolve,reject)=>{
        let messageData = {
              is_active: 'Y'
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: messageData
        }
        try{
            let messageinfo = await API.put(APINAME,'/message/'+id, req);
            resolve(messageinfo);
        }catch(err){
            console.error('Error enabling message.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableMessages=async(ids)=>{
    let promises = []
    for(let id of ids){
        promises.push(EnableMessage(id));
    }
    return await Promise.all(promises);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UpdateMessageAssignments= async(id, customers,replace)=>{
    return new Promise(async(resolve,reject)=>{
        let messageData = {
             customers: customers
        }
        if(replace){
            messageData.action='REPLACE'
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: messageData
        }
        try{
            let messageinfo = await API.put(APINAME,'/message/'+id, req);
            resolve(messageinfo);
        }catch(err){
            console.error('Error adding message.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DeleteMessage = async(messageid)=>{
    
    return new Promise(async(resolve,reject)=>{
        try{
            let messageinfo = await API.del(APINAME,'/message/'+messageid);
            resolve(messageinfo);
        }catch(err){
            console.error('Error deleting message.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



export { GetMyMessages, GetAllMessages, AddMessage, DeleteMessage, DisableMessage, DisableMessages,EnableMessage,EnableMessages, UpdateMessage, UpdateMessageAssignments}