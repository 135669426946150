//styling
import '../../App.css';
import "../../css/admin_portal.css";
import "../../css/normalize.css";

//react components
import * as React from 'react';
import {useState, useEffect} from 'react';

import { BrowserRouter, Routes, Route, Router, Redirect } from 'react-router-dom';

//third-party components
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

//customcomponents
import CustomerManager from './CustomerManager';
import UserManager from './UserManager';
import POCManager from './POCManager';
import ContentManager from './ContentManager';
import { ReportViewer } from '../ReportViewer';



const DisplayContent = (props)=>{

    
    const [customerData, setCustomerData]=useState();
    const [userData, setUserData]=useState();
    const [content, setContent]=useState();
    const [value, setValue] = useState('1');
    const [path, setPath]=useState();
    const [selectedPath, setSelectedPath] = useState();
    const [switchContent, setSwitchContent] = useState();
    const [pocData, setPOCData]=useState();

    useEffect(() => {

        //console.log(`in admin router with selected path ${props.selectedPath}`);
        //if((props.Path==(props.selectedPath||'/admin/users'))){
            let uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/) || '/admin/customers';
            
            //console.log('path changed in display content',uri_stem[1]);
            //alert(`path change in router ${path}`);
            //console.log('Path Property', props.Path);
            //alert(props.path||uri_stem);
            
            //console.log('switch on uri_stem',uri_stem[1])
            
            switch(uri_stem[1]){
            
                case '/admin/customers':
                    //console.log('set value to 1')
                    setValue('1');
                    setPath('/admin/customers')
                break;
                case '/admin/users':
                    //console.log('set value to 2')
                    setValue('2');
                    setPath('/admin/users')
                break;
                case '/admin/content':
                    //console.log('set value to 3')
                    setValue('3');
                    setPath('/admin/content')
                break;
                case '/admin/poc':
                    //console.log('set value to 4')
                    setValue('4');
                    setPath('/admin/poc')
                break;
                default:
                    //console.log('set value to 1')
                    setValue('1');
                    setPath('/admin/customers')
                    handleChange();
                break;
                
            }
        //}else{
        //    console.log(`Path ${props.Path}!= selectedPath ${props.selectedPath}`);
        //}
      
        

      }, [props.selectedPath]);

      const handleChange = (event, newValue) => {
        //console.log('handle change called');
        let switchto;
        switch(newValue){
            case '1':
                switchto='/admin/customers';
            break;
            case '2':
                switchto='/admin/users';
            break;
            case '3':
                switchto='/admin/content';
            break;
            case '4':
                switchto='/admin/poc';
            break;
            default:
                switchto='/admin/customers';
            break;
        }
        if(switchto){
            setPath(switchto);
            props.switchContent(switchto);
            setValue(newValue);
        }
    };
    

    return(
        <Box sx={{ width: '100%', typography: 'body1' }} className="admin_container">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="Administration functions">
                    <Tab label="Customer Manager" value="1" />
                    <Tab label="Content Manager" value="3" />
                    <Tab label="POC Manager" value="4" />
                </TabList>
                </Box>
                <TabPanel value="1">
                    <CustomerManager
                        isSelected={(props.selectedPath=='/admin/customers')}
                        onData={(data)=>{
                            return new Promise((resolve,reject)=>{
                                setCustomerData(data)
                                resolve();
                            })  
                        
                        }}
                        customers={customerData} 
                    />
                </TabPanel>
                <TabPanel value="2">
                    <UserManager
                        isSelected={(props.selectedPath=='/admin/users')}
                        onData={(data)=>{
                            return new Promise((resolve,reject)=>{
                                setUserData(data)
                                resolve();
                            })  
                        
                        }}
                        users={userData} 
                    />
                </TabPanel>
                <TabPanel value="3">
                    <ContentManager
                        isSelected={(props.selectedPath=='/admin/content')}
                        onData={(data)=>{
                            return new Promise((resolve,reject)=>{
                                setContent(data)
                                resolve();
                            })  
                        
                        }}
                        content={content} 
                    />
                </TabPanel>
                <TabPanel value="4">
                    <POCManager
                        isSelected={(props.selectedPath=='/admin/poc')}
                        onData={(data)=>{
                            return new Promise((resolve,reject)=>{
                                setPOCData(data)
                                resolve();
                            })  
                        
                        }}
                        pocData={pocData} 
                    />
                </TabPanel>
            </TabContext>
        </Box> 
    )

}


function AdminRouter(props){
    
        const [reportData, setReportData]=useState();
        const [path, setPath]=useState(props.path);
        
        useEffect(() => {
            const uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);

            let crrpath = (uri_stem&&uri_stem[1])?uri_stem[1]:'/admin/customers';
            //console.log('path changed in router', crrpath);
            setPath(crrpath);
            //props.switchContent(crrpath)
    
          }, [props.path]);

          
        return (
            
            <BrowserRouter>
                <Routes>
                    {(props.fullWindow==true)?
                        <Route path="/reports/viewer" element={<ReportViewer />}  />
                    :
                    <>
                    <Route path="/" element={<DisplayContent Path="/admin/customers" switchContent={props.switchContent} selectedPath={path}/>} />
                    <Route path="/admin/customers" element={<DisplayContent Path="/admin/customers" switchContent={props.switchContent} selectedPath={path} />} />
                    <Route path="/admin/users" element={<DisplayContent Path="/admin/users" switchContent={props.switchContent} selectedPath={path} />} />
                    <Route path="/admin/content" element={<DisplayContent Path="/admin/content" switchContent={props.switchContent} selectedPath={path}/>} />
                    <Route path="/admin/poc" element={<DisplayContent Path="/admin/poc" switchContent={props.switchContent} selectedPath={path}/>} />
                    </>
                    }
                </Routes>
            </BrowserRouter>
            
        )
}

export default AdminRouter