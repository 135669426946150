import { Auth } from 'aws-amplify';

export default (
{
    Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID
    },
    API: {
        endpoints: [
            {
                name: "user",
                endpoint: process.env.REACT_APP_APIGWENDPOINT_USER,
				custom_header: async () => { 
				  return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
            {
                name: "customer",
                endpoint: process.env.REACT_APP_APIGWENDPOINT_CUSTOMER,
				custom_header: async () => { 
				  return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
            {
                name: "customerlocation",
                endpoint: process.env.REACT_APP_APIGWENDPOINT_CUSTOMER,
				custom_header: async () => { 
				  return { 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
            {
                name: "location",
                endpoint: process.env.REACT_APP_APIGWENDPOINT_LOCATION,
				custom_header: async () => { 
				  return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
            {
                name: "poc",
                endpoint: process.env.REACT_APP_APIGWENDPOINT_POC,
				custom_header: async () => { 
				  return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
            {
                name: "hostedlink",
                endpoint: process.env.REACT_APP_APIGWENDPOINT_HOSTEDLINK,
				custom_header: async () => { 
				  return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
            {
                name: "reports",
                endpoint: process.env.REACT_APP_APIGWENDPOINT_REPORTS,
				custom_header: async () => { 
				  return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
            {
                name: "messages",
                endpoint: process.env.REACT_APP_APIGWENDPOINT_MESSAGES,
				custom_header: async () => { 
				  return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
        ]
    }
}
)


