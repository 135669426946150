//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";


//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';




//customcomponents

//libraries
import { Button } from '@mui/material';

export default function AssignOption(props){
    
    const [showDialogue,setShowDialogue]=useState(false);
    const [selectedrowids,setSelectedRowIds]=useState();
    const [customerData, setCustomerData]=useState();
    const [initial,setInitial]=useState();
    const [selectedCustomer, setSelectedCustomer]=useState();
    const [locationData, setLocationData]=useState({});
    const [isLoadingLocation, setIsLoadingLocation]=useState(false);
    const [roleData, setRoleData]=useState();
    const [selectedLocations, setSelectedLocations]=useState();
    const [selectedRoles, setSelectedRoles]=useState();
    const [resetForm,setResetForm]=useState();
    const [assignments,setAssignments]=useState([]);
    const [contentType, setContentType]=useState('link');
    const [linkData, setLinkData]=useState({'link-title':'','link-url':'','link-description':''});
    const [linkTitle, setLinkTitle]=useState('');
    const [linkDescription, setLinkDescription]=useState('');
    const [linkUrl, setLinkUrl]=useState('');

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        setSelectedRowIds(props.selectedRowIds);
        

        if(!initial){
            setInitial(true);
        }

      }, [props.showDialogue]);
    
    const closeDialogue=(e)=>{

        
        if(props.onCloseCallback){
            props.onCloseCallback(e);
        }else{
            setShowDialogue(false)
        }
    }
    

    return(
       
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '300px',
                maxHeight: '60%',
                minWidth: '800px',
                maxWidth: '60%'
              }}}
        >
            <DialogTitle>
            Assign Content Now
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <DialogTitle>
                    Would you like to assign the newly created content?
                </DialogTitle>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>closeDialogue('Yes')}>Yes</Button>
                <Button onClick={()=>closeDialogue('No')}>No</Button>                
            </DialogActions>
        </Dialog>

    )
}