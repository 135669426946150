
import { Auth,API } from "aws-amplify";
import { insert,remove } from "./ArrayHelper";


const APINAME='poc'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetMyPOC= async(sub) =>{
    
    return new Promise(async (resolve,reject)=>{
        try{
            
                sub = sub || (await Auth.currentAuthenticatedUser()).attributes.sub;
                if(sub){
                    let pocinfo = await API.get(APINAME,'/user/'+sub);
                    resolve(pocinfo);
                }else{
                    resolve([]);
                }
            
        }catch(err){
            reject(err);
        }
        
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetAllPOC=async(rows=[], pageSize=5, currentPage=0, search, sortBy)=>{
    

    return new Promise(async (resolve,reject)=>{
        try{
           

            const qParams = new URLSearchParams();
            qParams.append("pagesize", pageSize);
            qParams.append("currentpage", currentPage);
            if(search){
                qParams.append("search",search);
                //console.log('including search parameter ', search)
            }else{
                //console.log('no search parameter');
            }
            if(sortBy){
                qParams.append("sort",sortBy);
            }
            

            

                
                //console.log(`rows length start ${rows.length}`);
                
                let users = await API.get(APINAME,'/' + (qParams)? `?${qParams.toString()}`:'');
                
                if(search){
                    rows=[];
                    users = users.filter((u)=>(u.poc_fullname.toLowerCase().indexOf(search.toLowerCase())>=0
                                                || u.poc_title.toLowerCase().indexOf(search.toLowerCase())>=0
                                                || u.poc_email.toLowerCase().indexOf(search.toLowerCase())>=0
                                                ))
                }
                //console.log(`rows length after api call and filter ${rows.length}`);
                
                let totalitems = users.length;
                let startIndex = currentPage*pageSize;
                let length = (startIndex+pageSize>users.length)?users.length-startIndex:pageSize;
                let dummyitems=0;
                if(totalitems!=(rows.length-rows.filter(f=>f.poc_id.indexOf('dummy')==0).length)){
                    if(users.length>pageSize){
                        //console.log(`current page ${currentPage} with size ${pageSize}`);
                        //console.log(`slicing results from ${startIndex} for length ${length}`);
                        users = users.splice(startIndex,length);
                    }
                    //console.log(users);
                    //console.log(`start pushing dummy rows at index ${startIndex}`);
                    //console.log(`rows length ${rows.length} with total items ${totalitems}`);
                    if(rows.length!=totalitems){
                        for(let i=startIndex+pageSize;i<totalitems;i++){
                            users.push({poc_id:`dummy${i}`});
                        }
                        rows=users;
                    }else{
                        let count=0
                        //remove dummy rows
                        //console.log(`remove dummy rows from ${startIndex} to ${startIndex+length}`);
                        rows = remove(rows, startIndex,length);
                        //insert new rows
                        //console.log(`insert records starting at ${startIndex+count}`);
                        for(let l of users){
                            rows = insert(rows,startIndex+count, l)
                            count++;
                        } 
                        
                    }
                    
                    dummyitems =  rows.filter(f=>f.poc_id.indexOf('dummy')==0).length;
                    //console.log(`contains ${dummyitems} dummy rows`);
                    
                }else{
                    //console.log('we have no dummy items')
                    //console.log(`total times ${totalitems} less ${dummyitems}`);
                }
               
                if(!sortBy || sortBy == 'date_created'){
                    //default sort
                    rows = rows.sort((a, b) => a.poc_fullname.toLowerCase() > b.poc_fullname.toLowerCase() ? 1 : -1);
                }else{
                    //field sort
                    rows = rows.sort((a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1);
                }

                resolve({
                      rows : rows
                    , totalItems : totalitems
                    , resolvedItems : totalitems-dummyitems
                });
           
            
        }catch(err){
            reject(err);
        }
        
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddPOC=async(fullname,cell,email,imageb64,phone,title, isDefault)=>{

    return new Promise(async(resolve,reject)=>{

        try{
            
            //upload the file to the presigned URL
            /*let fupresult = await fetch(uploadDetail.uploadURL, {
                method: 'PUT',
                headers: {'Content-Type':uploadFile.type},
                body: uploadFile
            })*/
            
            //after successful result, call API to create the database entry for upload complete operations
            let pocData = {
                poc_cell: cell
                , poc_default: isDefault
                , poc_email: email
                , poc_fullname: fullname
                , poc_image: imageb64
                , poc_phone: phone
                , poc_title: title
            }
            let req = {
                    headers: {'Content-Type':'application/json'}
                , body: pocData
            }
          
              let pocinfo = await API.post(APINAME,'/', req);
              resolve(pocinfo)
        }catch(err){
            console.error('Error uploading report.')
            reject(err);
        }
        
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UpdatePOC=async(pocid,fullname,cell,email,imageb64,phone,title, isDefault)=>{

    return new Promise(async(resolve,reject)=>{

        try{
           
            let pocData = {
                  poc_cell: cell
                , poc_default: isDefault
                , poc_email: email
                , poc_fullname: fullname
                , poc_image: imageb64
                , poc_phone: phone
                , poc_title: title
            }

            let req = {
                    headers: {'Content-Type':'application/json'}
                , body: pocData
            }
          
            let pocinfo = await API.put(APINAME,'/'+pocid, req);
            
            pocData.poc_id=pocid

            resolve(pocData);
          
        }catch(err){
          
            console.error('Error updating poc.')
            reject(err);
        
        }

        
    })
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DeletePOC = async(pocid)=>{
    
    return new Promise(async(resolve,reject)=>{
        try{
            let pocinfo = await API.del(APINAME,'/'+pocid);
            resolve(pocinfo);
        }catch(err){
            console.error('Error deleting POC.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export { GetMyPOC,GetAllPOC,AddPOC,UpdatePOC, DeletePOC }