//styling
import "../../App.css";
import HeaderLogo from "../../images/abbott-header-logo.png"
import HeaderImage from "../../images/img_header.png";
import "../../css/admin_portal.css";
import "../../css/normalize.css";


//react components
import React, { Component, useState, useEffect  } from "react";


//third-party components
import {createBrowserHistory} from "history";
import LinearProgress from '@mui/material/LinearProgress';

//customcomponents
import SignOut from '../Public/SignOut';
import AdminRouter from "./AdminRouter";
import { ReportViewer } from '../ReportViewer';


//libraries
import { GetUserInfo } from '../../libs/User';


export default function AdminContent(props){

    
    const [fullname,setFullName]=useState('');
    const browserHistory = createBrowserHistory();
    const [path,setPath]=useState(undefined);
    const [initial,setInitial]=useState(true);
    const [isLoading,setIsLoading]=useState(true);
    const [pocData,setPOCData]=useState();
    const [messageData, setMessageData]=useState();
    const [reportData, setReportData]=useState();
    const [linksData, setLinksData]=useState();
    

    const paths=['/admin/customers','/admin/users','/admin/content','/admin/poc']
    const uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);
    
    useEffect(()=>{
        if(props.onNavigation){
            props.onNavigation();
        }
    },[]);



    useEffect(()=>{
        if(props.userInfo){
            setFullName(props.userInfo.user_name);
            setIsLoading(false);
        }

        if(path){
            //console.log('set initial path');
            setInitial(true);
            let uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);
            if(uri_stem){
                if(paths.indexOf(uri_stem[1])>=0){
                    setPath(uri_stem[1]);
                }else{
                    setPath(paths[0]);
                }

            }
        }

        browserHistory.listen((e)=>{
            
            let crrpath=(!e.location.pathname || e.location.pathname=='/')?setPath(paths[0]):e.location.pathname;

            switch(e.action){
                case 'POP':
                    setInitial(true);
                    //console.log('received pop event',crrpath)
                    setPath(crrpath);
                    //setPath((e.location.pathname=='/')?setPath('/links'):e.location.pathname);
                    switchContent(crrpath,true);
                break;
                case 'PUSH':
                    setInitial(true);
                    //console.log('received push event',crrpath)
                    setPath(crrpath);
                    //setPath((e.location.pathname=='/')?setPath('/links'):e.location.pathname);
                    switchContent(crrpath,true);

                break;
                default:
                    //console.log(e.action)
                break;
            }

        })


        
        if(props.onNavigation){
            props.onNavigation();
        }
    
        
          
    },[path]);

    const switchContent = (path,skipPush)=>{
        setInitial(false);
        //console.log('switch content',path)
        if(!skipPush){
            browserHistory.push(path);
        }
        setPath(path);
    }

    
    if(uri_stem && uri_stem.length>=2 && uri_stem[1]=='/reports/viewer'){
        return (<></>)
    }else{
        return (
                (isLoading)?
                <LinearProgress />
                :
                <>
                <div className="admin_segment">
                    <div className="outcontainer apoc_customer_portal">
                        <div className="apoc_logo"><img src={HeaderLogo} width="120px" /></div>
                        <div className="apoc_title">
                            <span className="padding_right_5">POINT OF CARE ADMINISTRATION CONTROL PANEL</span>
                            <span className="padding_right_5 padding_left_5">|</span>
                            <span className="admin_link"><a href="/links">user portal</a></span>
                            <span className="padding_right_10">|</span>
                            <span className="admin_link">
                            <SignOut  className="admin_link"
                                    onSignOut={props.onSignOut}
                                    triggerSignOut={props.triggerSignOut}
                                    onContinueSession={()=>{
                                        if(props.onContinueSession){
                                            props.onContinueSession();
                                        }
                                    }}
                                />
                            </span>
                        </div>

                    </div>
                    </div>
                
                    <div className="background_white">
                        <div className="padding_bottom_20 segment_padding admin_container">
                            <AdminRouter fullWindow={false} path={path} switchContent={switchContent}/>
                        </div>
                    </div>
                

                </>
                
        );
    }
    
}