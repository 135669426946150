//styling

//react components
import React, { Component, useState, useEffect  } from "react";
import { BrowserRouter, Routes, Route, Router, useNavigate } from 'react-router-dom';


//third-party components


//customcomponents
import LoginForm from './LoginForm';
import RecoverPassword from './RecoverPassword';
import ChangePassword from './ChangePassword';
import ResetPassword from './ResetPassword';
import Redirect from '../Redirect';


//export default class PublicRouter extends Component {
export default function PublicRouter(props){
    
    
    const onAuth = (userinfo)=>{


        if(props.onAuthenticated){
            props.onAuthenticated(userinfo)
        }
    }

    //console.log(`props.isAuthenticated ${props.isAuthenticated}`);

    return (
        
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginForm onAuthenticated={onAuth}/>} />
                <Route path="/forget_password.html" element={<RecoverPassword />} />
                <Route path="/reset_password.html" element={<ResetPassword />} />
                <Route path="/change_password.html" element={<ChangePassword />} />
                {(props.isAuthenticated==false)?
                <Route path="*" element={<Redirect to="/" />} />
                :<></>
                }
            </Routes> 
        </BrowserRouter>
        
    )

}

