//styling
import '../../App.css';
import "../../css/admin_portal.css";
import "../../css/normalize.css";
import IconSearch from "../../images/icon_search.png";

//react components
import React, { useState, useEffect  } from "react";


//third-party components
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, AlertTitle } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PeopleIcon from '@mui/icons-material/People';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



//customcomponents
import IconSelector from '../IconSelector';
import PromptUser from '../PromptUser';
import AddUpdateCustomer from './Dialogues/AddUpdateCustomer'
import AddUpdateLocations from './Dialogues/AddUpdateLocations'
import AddUpdateUsers from './Dialogues/AddUpdateUsers'

//libraries
import { GetAllUsers, EnableUsers, DisableUsers } from '../../libs/User';
import { GetAllCustomers, DeleteCustomer, DisableCustomers, EnableCustomers } from '../../libs/Customers';

import { ChecklistSharp } from '@mui/icons-material';

export default function CustomerManager(props){

    const [isLoading, setIsLoading]=useState(true);
    const [isTableLoading, setIsTableLoading]=useState();
    const [columns, setColumns]=useState([]);
    const [customers,setCustomers]=useState(props.customers || {rows:[],totalItems:0,resolvedItems:0});
    const [currentPage, setCurrentPage]=useState((props.customers)?props.customers.page || 0:0);
    const [pageSize, setPageSize]=useState((props.customers)?props.customers.pageSize || 100:100);
    const [sortBy, setSortBy]=useState();
    const [searchPhrase, setSearchPhrase]=useState();
    const [selectedrowids,setSelectedRowIds]=useState([]);
    const [selectedRows,setSelectedRows]=useState([]);
    const [isDeleting, setIsDeleting]=useState(false);
    const [deleteParams, setDeleteParams]=useState({});
    const [disableParams, setDisableParams]=useState({});
    const [enableParams, setEnableParams]=useState({});
    const [showDeletePrompt,setShowDeletePrompt]=useState(false);
    const [showDisablePrompt, setShowDisablePrompt]=useState(false);
    const [showEnablePrompt, setShowEnablePrompt]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [showAddCustomerDialogue, setShowAddCustomerDialogue]=useState(false)
    const [showAddLocationsDialogue, setShowAddLocationsDialogue]=useState(false)
    const [showAddUsersDialogue, setShowAddUsersDialogue]=useState(false)
    const [selectedRecord, setSelectedRecord]=useState();
    const [editContent, setEditContent]=useState(false);
    
    useEffect(()=>{
        if(feedbackMessage){
            //hide delete prompt after wait.
                let timeoutperiod = 3000;
                switch(severity){
                case 'error':
                    timeoutperiod = 6000;
                break;
                }
                setTimeout(()=>{//console.log('running timer');
                    resetFeedback();},3000);
        }

    },[feedbackMessage]);

    useEffect(() => {

        
            setColumns([
                {     field: 'customer_number'
                    , headerName: 'Number'
                    , width:100
                }
                ,{
                    field: 'customer_name'
                    , headerName:"Customer"
                    ,width:300
                }
                ,{
                      field:'locations'
                    , headerName:'Location Detail'
                    , width:650
                    , sortable: false
                    , renderCell: (params) => {
                        return (
                            
                            <Box fullWidth>
                                {params.row.locations && params.row.locations.map((l) => (
                                    <Location key={l.location_id} row={params.row} location={l} />
                                ))}
                            </Box>
                        )
                    }
                }
                ,{
                    field: 'poc'
                  , headerName:"APOC Contact"
                  , width:200
                  , valueGetter: (params) => `${params.row.poc.poc_fullname}`
                 }
                ,{
                    field:'userinfo'
                  , headerName:'Num Users'
                  , width:100
                  , sortable: true
                  //, sortComparator: (v1, v2) => (v1.users.length>v2.users.length)||false
                  , valueGetter: (params) => params.row.users.length
                  /*, renderCell: (params) => {
                      return parseInt(params.row.users.length);
                  }*/
                }
                ,{
                    field: "action_edit",
                    headerName: "",
                    sortable: false,
                    description: 'Edit',
                    width: 50,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                        return (
                            <Tooltip title="Edit">
                                <IconButton onClick={(e)=>{
                                    setEditContent(true);
                                    setSelectedRecord(params.row);
                                    setShowAddCustomerDialogue(true);
                                }}><EditIcon /></IconButton>
                            </Tooltip>
                        );
                     }
                }
                ,{
                    field: "action_managelocations",
                    headerName: "",
                    sortable: false,
                    description: 'Manage Locations',
                    width: 50,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                        return (
                            <Tooltip title="Manage Locations">
                                <IconButton onClick={(e)=>{
                                    setEditContent(true);
                                    setSelectedRecord(params.row);
                                    setShowAddLocationsDialogue(true);
                                }}><LocationCityIcon /></IconButton>
                            </Tooltip>
                        );
                     }
                },{
                    field: "action_manageusers",
                    headerName: "",
                    sortable: false,
                    description: 'Manage Users',
                    width: 50,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                        return (
                            <Tooltip title="Manage Users">
                                <IconButton onClick={(e)=>{
                                    setEditContent(true);
                                    setSelectedRecord(params.row);
                                    setShowAddUsersDialogue(true);
                                }}><PeopleIcon /></IconButton>
                            </Tooltip>
                        );
                     }
                }
                
            ]);
            
        if(props.isSelected){
            LoadData({pageSize:pageSize, page: currentPage},customers,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        //console.log('setting data in callback')
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }

      }, [props.isSelected,customers, pageSize]);
    
      
    const Location = (props)=>{
         const { row,location } = props;
         const [open, setOpen] = React.useState(false);


         const locationUsers = row.users.filter((f)=>(f.locations.filter((ul)=>ul.location_id==location.location_id).length>0));

         return (
            
                <Box fullWidth>
                        <Stack direction="row" fullWidth>
                            <div  style={{'width':'50px','textAlign':'left'}}>
                                {(locationUsers.length)?
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                                :<span>&nbsp;</span>
                                }
                            </div>
                            <div style={{'width':'400px'}}><b>{location.location_name}</b> - {location.address_line1},{location.address_line2}, {location.city}, {location.state} {location.zip}</div>
                        </Stack>
                
                        <Collapse in={open} timeout="auto" unmountOnExit>
                                <Table sx={{'borderBottom': 'unset' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Username</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {locationUsers.map((u)=>{
                                                            return (
                                                                <TableRow key={u.user_id}>
                                                                    <TableCell>{u.user_name}</TableCell>
                                                                    <TableCell>{u.email}</TableCell>
                                                                    <TableCell>{u.locations[0].roles.join(',')}</TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title="View as User">
                                                                            <IconButton href={`/links?userId=${encodeURIComponent(u.user_id)}`} target="_blank"><VisibilityIcon /></IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                    </TableBody>
                                </Table>
                            
                        </Collapse>
                </Box>
            
        )
    }
    const CustomPaginator=()=>{
        return (
                (isTableLoading)?
                <></>
                :<GridPagination disabled={!isTableLoading}/>
        );
    }

    const handlePagination = (params, evt, details)=>{
                
            LoadData(params, customers,props.onData);

     }

     const LoadData = (params,origdata,callback)=>{


            setIsTableLoading(true);
            if(!origdata || origdata.totalItems==0 || (origdata.resolvedItems<(params.pageSize*(params.page+1)) && origdata.resolvedItems!=origdata.totalItems) || params.search){
                setPageSize(params.pageSize);
                
                GetAllCustomers((params.search)?[]:customers.rows, params.pageSize, params.page, params.search, null, sortBy,null).then(async (data)=>{
                    if(data){
                        setCustomers(data);
                        if(callback){
                            if(origdata && origdata.resolvedItems!=data.resolvedItems){
                                data.page=params.page
                                data.pageSize=params.pageSize
                                //console.log('sending report data to callback');

                                callback(data).then(()=>{
                                    //console.log('back from callback');
                                    setPageSize(params.pageSize);
                                    setCurrentPage(params.page);
                                    setIsTableLoading(false);
                                });
                            }
                            
                        }else{
                            //console.log('no callback configured')
                            setPageSize(params.pageSize);
                            setCurrentPage(params.page);
                            setIsTableLoading(false);
                        }
                    }
                
                }).catch((err)=>{
                    console.error(`error fetching reports for page ${params.page}`,err);
                    setIsTableLoading(false);
                });
            }else{
                console.log('pulling data form cache');
                origdata.page=params.page;
                origdata.pageSize=params.pageSize;
                callback(origdata);
                setIsTableLoading(false);
                setPageSize(params.pageSize);
                setCurrentPage(params.page);
            }

            
            
     }

    const resetData= async()=>{
        setIsLoading(true);
        setCurrentPage(0);
        setCustomers({rows:[],totalItems:0,resolvedItems:0});setCurrentPage(0);
    }

    const searchInput = (e)=>{
        
        if(e.keyCode==13){
            //console.log('load search data');
            
            LoadData({pageSize:pageSize, page: 0, search:e.target.value},undefined,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }
    }

    const customerAdded = (customerObj)=>{
        setEditContent(false);
        //console.log('customer added');
        //console.log(customerObj);
        let data= {...customers};
        data.rows = [customerObj].concat(data.rows);
        data.totalItems++;
        data.resolvedItems++;
        setSelectedRecord(customerObj);
        setCustomers(data);

    }

    const customerUpdated = (customerObj)=>{
        
        //console.log('customer updated');
        //console.log(customerObj);

        let data= {...customers};
        data.rows = data.rows.slice(0,data.rows.indexOf(selectedRecord)).concat([customerObj]).concat(data.rows.slice(data.rows.indexOf(selectedRecord)+1));
        //data.rows = [contentObj].concat(data.rows.filter((r)=>r.id!=contentObj.id));
        setCustomers(data);
    }

    const usersClosed = (users)=>{
        setShowAddUsersDialogue(false); 
        setSelectedRecord();
        setTimeout(()=>setEditContent(false),250);

        let data= {...customers};
        let selectedCustomer = selectedRecord;
        selectedCustomer.users = users;

        data.rows = data.rows.slice(0,data.rows.indexOf(selectedRecord)).concat([selectedCustomer]).concat(data.rows.slice(data.rows.indexOf(selectedRecord)+1));
        //data.rows = [contentObj].concat(data.rows.filter((r)=>r.id!=contentObj.id));
        setCustomers(data);
    }

    const locationsClosed = (locations)=>{

        setShowAddLocationsDialogue(false);
        setSelectedRecord();
        setTimeout(()=>setEditContent(false),250);
        
        let data= {...customers};
        let selectedCustomer = selectedRecord;
        selectedCustomer.locations = locations;

        data.rows = data.rows.slice(0,data.rows.indexOf(selectedRecord)).concat([selectedCustomer]).concat(data.rows.slice(data.rows.indexOf(selectedRecord)+1));
        //data.rows = [contentObj].concat(data.rows.filter((r)=>r.id!=contentObj.id));
        setCustomers(data);


    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackMessage();
        setFeedbackTitle();
    }

    return(
        (isLoading)?
        <div id="usermanager">
            <LinearProgress />
        </div>
        :
        <div id="usermanager">
            
                <>
                        <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                            <AlertTitle>{feedbackTitle}</AlertTitle>
                            {feedbackMessage}
                        </Alert>
                        {(isDeleting)?<LinearProgress />:<></>}
                        
                        <PromptUser showDialogue={showDeletePrompt}
                            Title="Delete Customer"
                            Prompt="Are you sure you would like to delete the selected customers?" 
                            Params={deleteParams}
                            onCallback={async (e)=>{
                                
                                setShowDeletePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        let delpromises=[];
                                        for(let delItem of e.params){
                                            delpromises.push(DeleteCustomer(delItem.id,delItem.contentType));
                                        }
                                        let delres = await Promise.all(delpromises);
                                        
                                        let data = {...customers};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.customer_id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setCustomers(data);
                                        setSeverity('success');
                                        setIcon('verified');
                                        setFeedbackTitle('Customer Deleted');
                                        setFeedbackMessage('Customer Deleted Successfully.')
                                        //console.log('customer deleted');
                                    }catch(err){
                                        console.error(err.response.data.error);
                                        setSeverity('error');
                                        setIcon('error');
                                        setFeedbackTitle('Error Deleting Customer');
                                        setFeedbackMessage(err.response.data.error)
                                        //console.log('error deleting customer');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        <PromptUser showDialogue={showDisablePrompt}
                            Title="Disable User"
                            Prompt="Are you sure you would like to disable the selected customers?" 
                            Params={disableParams}
                            onCallback={async (e)=>{
                                setShowDisablePrompt(false);
                                //console.log(e);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        let delpromises=[];
                                        let userObjs = [];
                                        for(let obj of e.params){
                                            userObjs.push({id : obj.id});
                                        }
                                        let disableres = await DisableCustomers(userObjs);
                                        
                                        let data = {...customers};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setCustomers(data);
                                        setFeedbackTitle('Customer Disabled');
                                        setFeedbackMessage('Customer Disabled Successfully.')
                                        //console.log('customer disabled');
                                    }catch(err){
                                        //console.error(err);
                                        //console.log('error disabling customer');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        <PromptUser showDialogue={showEnablePrompt}
                            Title="Enable Customer"
                            Prompt="Are you sure you would like to enable the selected customer?" 
                            Params={enableParams}
                            onCallback={async (e)=>{
                                setShowEnablePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    try{
                                        setIsDeleting(true);
                                        
                                        let userObjs = [];
                                        for(let obj of e.params){
                                            userObjs.push({id : obj.id});
                                        }
                                        let enableres = await EnableCustomers(userObjs);

                                        let data = {...customers};
                                        data.rows = data.rows.filter((r)=>r.id!=e.params.id);
                                        data.totalItems = data.rows.length;
                                        data.resolvedItems--;
                                        setCustomers(data);
                                        setFeedbackTitle('Customer Enabled');
                                        setFeedbackMessage('Customer Enabled Successfully.')
                                        //console.log('customer enabled');
                                    }catch(err){
                                        //console.error(err);
                                        //console.log('error enabling customer');
                                    }
                                    setIsDeleting(false);
                                }
                                
                            }}
                        />
                        
                        <Stack direction="row" >
                            <OutlinedInput id="txtsearch" 
                                onKeyDown={searchInput} 
                                variant='outlined'
                                value={searchPhrase}
                                onChange={(e)=>setSearchPhrase(e.target.value)}
                                startAdornment={<InputAdornment position="end"><IconButton aria-label="search" edge="start" onClick={(e)=>searchInput({keyCode:13})}><SearchIcon /></IconButton></InputAdornment>}
                                sx={{verticalAlign:'top'}}
                            />
                            <Tooltip title="Delete Selected customers">
                                <IconButton onClick={(e)=>{
                                    let rowdata = customers.rows.filter((d)=>selectedrowids.indexOf(d.customer_id)>=0);
                                    let delparams = [];
                                    rowdata.forEach((d)=>delparams.push({id:d.customer_id}));
                                    setDeleteParams(delparams);
                                    setShowDeletePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><DeleteIcon /></IconButton>
                            </Tooltip>
                            {/*<Tooltip title="Enable Selected Customers">
                                <IconButton onClick={(e)=>{
                                    let rowdata = customers.rows.filter((d)=>selectedrowids.indexOf(d.customer_id)>=0);
                                    let enableparams = [];
                                    rowdata.forEach((d)=>enableparams.push({id:d.customer_id}));
                                    console.log('enable params',enableparams);
                                    setEnableParams(enableparams);
                                    setShowEnablePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><VisibilityIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Disable Selected Customers">
                                <IconButton onClick={(e)=>{
                                    let rowdata = customers.rows.filter((d)=>selectedrowids.indexOf(d.customer_id)>=0);
                                    let disableparams = [];
                                    rowdata.forEach((d)=>disableparams.push({id:d.customer_id}));
                                    setDisableParams(disableparams);
                                    setShowDisablePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><VisibilityOffIcon /></IconButton>
                            </Tooltip>*/}
                            <Tooltip title="Add Customer">
                                <IconButton onClick={(e)=>{setShowAddCustomerDialogue(true)}} disabled={(selectedrowids.length>0)}><AddIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Refresh Data">
                                <IconButton onClick={(e)=>{resetData();}}><RefreshIcon /></IconButton>
                            </Tooltip>
                            
                        </Stack>
                        <DataGrid
                            rows={customers.rows}
                            columns={columns}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                pageSize: pageSize,
                                page:currentPage
                                },
                            },
                            }}
                            pageSizeOptions={[5,10,25,50,100]}
                            //onRowSelectionModelChange={(val)=>alert(val)}
                            getRowId={(row)=>{return row.customer_id}}
                            fullWidth
                            id="report_table" 
                            className="hover row-border" 
                            onPaginationModelChange={handlePagination}
                            slots={{ 
                                    pagination : CustomPaginator,
                                    
                            }}
                            loading={isTableLoading}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(ids) => {
                                setSelectedRowIds(ids);
                                let rows = [];
                                ids.map((id)=>{ rows = rows.concat(customers.rows.filter((r)=>r.id==id))});
                                setSelectedRows(rows);
                            }}
                            getRowHeight={() => 'auto'}
                            
                        >
                        </DataGrid>
                        
                        <AddUpdateCustomer showDialogue={(showAddCustomerDialogue)} onCloseCallback={(e)=>{setShowAddCustomerDialogue(false); setSelectedRecord();setTimeout(()=>setEditContent(false),250);}} onCustomerAdded={customerAdded} onCustomerUpdated={customerUpdated} edit={editContent} selectedRecord={selectedRecord} />
                        <AddUpdateLocations showDialogue={(showAddLocationsDialogue)} onCloseCallback={locationsClosed} onLocationAdded={customerAdded} onLocationUpdated={customerUpdated} edit={editContent} selectedRecord={selectedRecord} />
                        <AddUpdateUsers showDialogue={(showAddUsersDialogue)} onCloseCallback={usersClosed} onUserAdded={customerAdded} onUserUpdated={customerUpdated} edit={editContent} selectedRecord={selectedRecord} />
                    
                </>
            
        </div>

    )
}